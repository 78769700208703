const middleware = {}

middleware['analytics'] = require('../middleware/analytics.ts')
middleware['analytics'] = middleware['analytics'].default || middleware['analytics']

middleware['auth-redirection'] = require('../middleware/auth-redirection.ts')
middleware['auth-redirection'] = middleware['auth-redirection'].default || middleware['auth-redirection']

middleware['featureFlag'] = require('../middleware/featureFlag.ts')
middleware['featureFlag'] = middleware['featureFlag'].default || middleware['featureFlag']

middleware['layout'] = require('../middleware/layout.ts')
middleware['layout'] = middleware['layout'].default || middleware['layout']

middleware['onboarding'] = require('../middleware/onboarding.ts')
middleware['onboarding'] = middleware['onboarding'].default || middleware['onboarding']

middleware['signature'] = require('../middleware/signature.ts')
middleware['signature'] = middleware['signature'].default || middleware['signature']

export default middleware
