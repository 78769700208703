import examples from 'libphonenumber-js/examples.mobile.json'
import { parsePhoneNumberFromString, getExampleNumber } from 'libphonenumber-js'
import type { CountryCode } from 'libphonenumber-js'

export const phone = {
  validate: (value: string, args: Record<string, any>) => {
    const countryCode = Array.isArray(args) ? args[0] : undefined

    if (!countryCode) {
      throw new Error('A country code must be specified (e.g phone:FR).')
    }

    if (!value) {
      return false
    }

    const parsing = parsePhoneNumberFromString(value, countryCode as CountryCode)

    return parsing?.isValid() ?? false
  },

  message: (_: any, args: Record<string, any>) => {
    const countryCode = args[0]

    if (!countryCode) {
      throw new Error('A country code must be specified (e.g phone:FR).')
    }

    /**
     * Get the phone number example to pass to the
     * translation key.
     */
    const phoneNumber = getExampleNumber(countryCode, examples)

    const example = phoneNumber?.formatNational() ?? undefined

    const message = 'Le champ téléphone est invalide.' + (example ? ` Exemple: ${example}` : '')

    return message
  },
}
