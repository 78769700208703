import { NavigationMainItem } from '@/types/navigation-items'

export const mainEntries: NavigationMainItem[] = [
  {
    available: ['current_student', 'past_student', 'future_student'],
    labelKey: 'chat',
    icon: 'mail',
    url: '/chat',
    badged: true,
  },
  {
    available: ['current_student', 'past_student', 'future_student'],
    labelKey: 'follow',
    icon: 'flag',
    url: '/follow-up',
  },
  {
    available: [
      'current_student',
      'past_student',
      'reader',
      'future_student',
      'current_edition',
      'future_edition',
      'past_edition',
    ],
    labelKey: 'courses',
    icon: 'book-open',
    url: '/courses',
  },
  {
    available: ['current_student', 'webinars', 'webinar_replays'],
    labelKey: 'webinar',
    icon: 'tribe',
    url: '/webinar',
  },
  {
    available: ['current_edition', 'future_edition', 'past_edition'],
    labelKey: 'hot-seat',
    icon: 'video-camera-circle',
    url: '/edition',
  },
  {
    available: ['community'],
    labelKey: 'community',
    icon: 'user-group',
    url: '/community',
    featureFlag: 'community',
    new: true,
  },
]
