<template>
  <div
    v-if="$config.IS_TESTING"
    class="text-12 fixed right-0 bottom-0 z-100 mr-2px mt-2px rounded bg-green-livementor px-0.5 py-2px text-xxs text-white"
  >
    <span class="font-bold"> Front </span>
    <span>: {{ $config.HEROKU_BRANCH || $config.DEPLOY_TARGET }}</span>
    <a v-if="$config.API_URL" class="font-bold" :href="$config.API_URL" target="blank"> API </a>
    <a
      v-if="$store.state.runHostUrl"
      class="font-bold"
      :href="$store.state.runHostUrl"
      target="blank"
    >
      Monolith
    </a>
  </div>
</template>

<script>
export default {}
</script>
