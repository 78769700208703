import { Context } from '@nuxt/types'
import VueRouter from 'vue-router'
import { FirebaseConfig } from '@/services/lm-firebase/types'
import { MessageType } from '@/services/lm-firebase/collections/MessagesCollection'

import { LmFirebase } from '@/services/lm-firebase'

import {
  CREATE_PROPOSAL,
  DELETE_DRAFT,
  DELETE_PROPOSAL,
  MERGE_CONVERSATIONS,
  SET_DRAFTS,
} from '@/constants/mutations-type'

import { SET_USERS, SET_MESSAGE } from '@/constants/actions-type'
import { Message } from '@/models/Message'
import { User, UserFromFirebase } from '@/models/User'
import { SlotStatus, TimekitAvailabilityResource } from '@/components/global/MeetingProposal/types'
import { CoachingContext } from '@/models/Coaching'
import { Conversation } from '@/models/Conversation'
import { NotificationType } from '@/constants/notifications-type'

export type CreateCoachingInput = {
  proposerName: string
  username: string
  memberId: string | number
  conversationId: string
  certification?: number | null
  proposal: {
    trainingCourseId: number
    slots: {
      start: string
      end: string
      id?: string
      status?: SlotStatus
      resources?: TimekitAvailabilityResource[]
    }[]
    context: CoachingContext
    coaching?: number | null
  }
}

export class FirebasePlugin {
  private lmFirebase: LmFirebase
  public isLoggedIn = false

  constructor(
    private readonly config: FirebaseConfig,
    private readonly store: Context['store'],
    private readonly router: VueRouter,
  ) {
    this.lmFirebase = new LmFirebase(this.config)
  }

  private userHasFeatureFlag(flag: string): boolean {
    return this.store.getters.userHasFeature(flag)
  }

  async login(user?: User) {
    try {
      user = user ?? this.store.getters['auth/user']

      if (!user?.id) {
        throw String('no user provided')
      }

      const userId = user.id.toString()

      await this.lmFirebase.login(user)
      this.isLoggedIn = true

      const userStored = await this.lmFirebase.usersCollection.fetchUser(userId)
      this.store.dispatch('users/SET_USER', userStored)

      const conversations = await this.fetchConversationsforCurrentUser(userStored)

      await this.fetchUsersOfConversations({
        userId,
        conversations,
      })

      this.listenUserData({
        userStored,
        conversations,
      })

      if (this.userHasFeatureFlag('session_log')) {
        await this.launchSessionRecording(userStored)
      }
    } catch (error) {
      throw new Error(`[FirebasePlugin](login) ${error}`)
    } finally {
      this.store.commit('setAllConversationsAreLoaded', true)
    }
  }

  private launchSessionRecording(user: UserFromFirebase) {
    try {
      return this.lmFirebase.sessionCollection.startUserSession(user.id)
    } catch (error) {
      throw new Error(`[FirebasePlugin](launchSessionRecording) ${error}`)
    }
  }

  private listenUserData({
    userStored,
    conversations,
  }: {
    userStored: UserFromFirebase
    conversations: Conversation[]
  }) {
    try {
      this.listenForConversationsUpdate({
        userId: userStored.id,
        conversations,
      })
      this.listenForUserUpdate(userStored)
      this.listenUserDrafts(userStored)
    } catch (error) {
      throw new Error(`[FirebasePlugin](listenUserData) ${error}`)
    }
  }

  private listenForConversationsUpdate({
    conversations,
    userId,
  }: {
    conversations: Conversation[]
    userId: string
  }) {
    try {
      for (const conversation of conversations) {
        if (!conversation.displayTo.includes(userId)) {
          continue
        }

        this.listenForConversationUpdate(conversation, userId)
      }
    } catch (error) {
      throw new Error(`[FirebasePlugin](listenForConversationsUpdate) ${error}`)
    }
  }

  private listenForUserUpdate(user: UserFromFirebase) {
    try {
      return this.lmFirebase.usersCollection.listenForUserUpdate(user.id).onSnapshot((snapshot) => {
        if (!snapshot || !snapshot.data()) {
          return
        }
        const snapshotData = snapshot.data() as UserFromFirebase

        this.store.dispatch(SET_USERS, snapshotData)

        const newConversationIds = snapshotData.conversations?.filter(
          (conversation: Conversation['id']) => {
            return !user.conversations?.includes(conversation)
          },
        )

        if (newConversationIds) {
          for (const conversationId of newConversationIds) {
            this.updateConversationAndHisUsers(conversationId, user.id)
          }
        }
      })
    } catch (error) {
      throw new Error(`[FirebasePlugin](listenForUserUpdate) ${error}`)
    }
  }

  private async updateConversationAndHisUsers(conversationId: string, userId: string) {
    try {
      const conversation = await this.fetchConversationById(conversationId)

      if (conversation) {
        this.store.commit('conversations/MERGE_CONVERSATIONS', {
          conversations: [conversation],
        })

        const interlocutorId = conversation?.participants.filter(
          (participantId: string) => participantId !== userId,
        )[0]

        const receiver = await this.lmFirebase.usersCollection.fetchUser(interlocutorId.toString())

        this.store.dispatch(SET_USERS, receiver)
      }
    } catch (error) {
      throw new Error(`[FirebasePlugin](updateConversationAndHisUsers) ${error}`)
    }
  }

  private listenUserDrafts(user: UserFromFirebase) {
    try {
      return this.lmFirebase.draftsCollection.listenUserDraftsChanges({
        userId: user.id,
        callback: (drafts) => this.store.commit(`drafts/${SET_DRAFTS}`, drafts, { root: true }),
      })
    } catch (error) {
      throw new Error(`[FirebasePlugin](listenUserDrafts) ${error}`)
    }
  }

  async logout() {
    try {
      if (this.userHasFeatureFlag('session_log')) {
        await this.lmFirebase.sessionCollection.closeUserSession()
      }

      return this.lmFirebase.logout()
    } catch (error) {
      throw new Error(`[FirebasePlugin](logout) ${error}`)
    }
  }

  getConversation(userId: string, otherParticipant: string) {
    try {
      return this.lmFirebase.conversationsCollection.getConversation(userId, otherParticipant)
    } catch (error) {
      throw new Error(`[FirebasePlugin](getConversation) ${error}`)
    }
  }

  getConversations(userId: string, otherParticipant: string) {
    try {
      return this.lmFirebase.conversationsCollection.getConversations(userId, otherParticipant)
    } catch (error) {
      throw new Error(`[FirebasePlugin](getConversations) ${error}`)
    }
  }

  async saveUserDraft({
    userId,
    conversationId,
    message,
  }: {
    userId: string
    conversationId: string
    message: string
  }) {
    try {
      await this.lmFirebase.draftsCollection.saveUserDraft({
        userId,
        conversationId,
        message,
      })
      this.store.commit(`drafts/${DELETE_DRAFT}`, conversationId, { root: true })
    } catch (error) {
      throw new Error(`[FirebasePlugin](deleteUserDraft) ${error}`)
    }
  }

  async deleteUserDraft({ userId, conversationId }: { userId: string; conversationId: string }) {
    try {
      await this.lmFirebase.draftsCollection.deleteUserDraft({ userId, conversationId })
      this.store.commit(`drafts/${DELETE_DRAFT}`, conversationId, { root: true })
    } catch (error) {
      throw new Error(`[FirebasePlugin](deleteUserDraft) ${error}`)
    }
  }

  terminateDbAndRecreateInstance() {
    try {
      this.lmFirebase.terminateDb()
      this.lmFirebase = new LmFirebase(this.config)
    } catch (error) {
      throw new Error(`[FirebasePlugin](terminateDbAndRecreateInstance) ${error}`)
    }
  }

  fetchBookmarkedMessages(userId: string) {
    try {
      return this.lmFirebase.bookmarksCollection.fetchBookmarkedMessages(userId)
    } catch (error) {
      throw new Error(`[FirebasePlugin](fetchBookmarkedMessages) ${error}`)
    }
  }

  bookmarkMessage({
    conversationId,
    message,
    userId,
  }: {
    conversationId: string
    message: Message
    userId: string
  }) {
    try {
      return this.lmFirebase.bookmarksCollection.bookmarkMessage({
        conversationId,
        message,
        userId,
      })
    } catch (error) {
      throw new Error(`[FirebasePlugin](unbookmarkMessage) ${error}`)
    }
  }

  unbookmarkMessage({ messageId, userId }: { messageId: string; userId: string }) {
    try {
      return this.lmFirebase.bookmarksCollection.unbookmarkMessage({ messageId, userId })
    } catch (error) {
      throw new Error(`[FirebasePlugin](unbookmarkMessage) ${error}`)
    }
  }

  private async fetchUsersOfConversations({
    userId,
    conversations,
  }: {
    userId: string
    conversations: Conversation[]
  }) {
    try {
      if (!userId) {
        throw String('No user id provided')
      }

      const participantIds = this.getParticipantIdsFromConversations(conversations)

      const usersData = await this.lmFirebase.usersCollection.fetchUsers(participantIds)

      this.store.dispatch(SET_USERS, usersData)
    } catch (error) {
      throw new Error(`[FirebasePlugin](fetchUsersOfConversations) ${error}`)
    }
  }

  private getParticipantIdsFromConversations(conversations: Conversation[]) {
    const userIds: string[] = []

    for (const conversation of conversations) {
      const participantsIds = conversation.participants

      for (const participantsId of participantsIds) {
        if (!userIds.includes(participantsId)) {
          userIds.push(participantsId)
        }
      }
    }

    return userIds
  }

  async updateTextMessage(conversationId: string, messageId: string, text: string) {
    try {
      await this.lmFirebase.messagesCollection.updateMessage(conversationId, messageId, text)

      const conversation = this.store.getters['conversations/getConversationById'](conversationId)
      const message = this.store.getters['messages/getMessageById'](messageId)

      this.store.dispatch(SET_MESSAGE, {
        conversation,
        messages: [
          {
            ...message,
            content: {
              text,
            },
          },
        ],
      })
    } catch (error) {
      throw new Error(`[FirebasePlugin](updateTextMessage) ${error}`)
    }
  }

  private listenForConversationUpdate(conversation: Conversation, userId: string) {
    try {
      return this.lmFirebase.conversationsCollection
        .listenForConversationUpdate(conversation.id)
        .onSnapshot((snapshot) => {
          const newConversationVersion = snapshot.data()
          const storeConversation = this.store.state.conversations[snapshot.id]

          if (
            newConversationVersion &&
            (!storeConversation ||
              storeConversation.lastMessageDate !== newConversationVersion.lastMessageDate)
          ) {
            this.store.commit('conversations/MERGE_CONVERSATIONS', {
              conversations: [newConversationVersion],
            })

            if (this.router.currentRoute.params.id === conversation.id) {
              if (userId) {
                this.markConversation(conversation.id, 'read')
              }
            }
          }
        })
    } catch (error) {
      throw new Error(`[FirebasePlugin](listenForConversationUpdate) ${error}`)
    }
  }

  private async fetchConversationsforCurrentUser(user: User) {
    try {
      if (!user) {
        throw String(`no user provided`)
      }

      const conversationsDoc = await this.lmFirebase.conversationsCollection.fetchConversations(
        user,
      )

      const conversations = conversationsDoc.docs
        .map((conversationDoc) => conversationDoc.data() as Conversation)
        .filter((conversation) => conversation)

      this.store.commit(`conversations/${MERGE_CONVERSATIONS}`, {
        conversations,
      })

      // this.store.commit('setAllConversationsAreLoaded', true)

      return conversations
    } catch (error) {
      throw new Error(`[FirebasePlugin](fetchConversationsforCurrentUser) ${error}`)
    }
  }

  fetchMessage(messageId: string) {
    try {
      return this.lmFirebase.fetchMessage(messageId)
    } catch (error) {
      throw new Error(`[FirebasePlugin](fetchMessage) ${error}`)
    }
  }

  listenNewMessagesFor(cid: string) {
    try {
      return this.lmFirebase.messagesCollection
        .listenForUpdate(cid)
        .orderBy('created_at')
        .limitToLast(1)
    } catch (error) {
      throw new Error(`[FirebasePlugin](listenNewMessagesFor) ${error}`)
    }
  }

  fetchLastMessageForConversation(cid: string) {
    try {
      return this.lmFirebase.messagesCollection.fetchMessages(cid, undefined, undefined, 1)
    } catch (error) {
      throw new Error(`[FirebasePlugin](fetchLastMessageForConversation) ${error}`)
    }
  }

  fetchConversationById(conversationId: string) {
    return this.lmFirebase.conversationsCollection.fetchConversationById(conversationId)
  }

  async fetchMessagesForConversation(cid: string, count?: number, fromMessageId?: string) {
    try {
      const conversation = this.store.getters['conversations/getConversationById'](
        cid,
      ) as Conversation

      const existingMessages: Message[] | undefined = conversation.messages
        ? conversation.messages
            .map((message) => this.store.state.messages[message])
            .sort((a: Message, b: Message) => a.created_at - b.created_at)
        : undefined

      const messages = await this.lmFirebase.messagesCollection.fetchMessages(
        cid,
        existingMessages && existingMessages.length ? existingMessages[0].id : undefined,
        fromMessageId,
        count,
      )

      const newMessages = messages.docs.map((message) => message.data())

      this.store.dispatch(SET_MESSAGE, {
        conversation,
        messages: newMessages,
      })

      return newMessages.length
    } catch (error) {
      throw new Error(`[FirebasePlugin](fetchMessagesForConversation) ${error}`)
    }
  }

  markConversation(conversationId: string, type: 'read' | 'unread') {
    try {
      const userId = String(this.store.getters['auth/user']?.id)

      if (!userId) {
        throw String('no user id provided')
      }

      const payload = {
        conversationId,
        userId,
      }

      const mutation =
        type === 'read' ? 'users/MARK_CONVERSATION_AS_READ' : 'users/MARK_CONVERSATION_AS_UNREAD'
      this.store.commit(mutation, payload)

      return this.lmFirebase.usersCollection.markConversationAsUnreadOrRead({
        type,
        ...payload,
      })
    } catch (error) {
      throw new Error(`[FirebasePlugin](markConversation) ${type} - ${error}`)
    }
  }

  async createCoachingProposal(createCoachingInput: CreateCoachingInput) {
    try {
      const isProposalUpdate =
        this.store.state.conversations[createCoachingInput.conversationId].coachingProposal

      this.store.commit(`conversations/${CREATE_PROPOSAL}`, {
        conversationId: createCoachingInput.conversationId,
        proposal: createCoachingInput.proposal,
      })

      let messageTitle = 'Votre rendez-vous de suivi'
      switch (createCoachingInput.proposal.context) {
        case 'kickoff':
          messageTitle = 'Votre diagnostic de rentrée'
          break
        case 'certification_exam':
          messageTitle = 'Votre oral de certification'
          break
        default:
          break
      }

      await this.lmFirebase.conversationsCollection.createProposal(
        createCoachingInput.conversationId,
        createCoachingInput.proposal,
      )
      this.sendCustomMessage({
        cid: createCoachingInput.conversationId,
        receiver_id: createCoachingInput.memberId,
        data: {
          context: createCoachingInput.proposal.context,
          title: messageTitle,
          kind: 'booking',
          description: isProposalUpdate
            ? 'Votre mentor doit déplacer votre rendez-vous de suivi. Vous venez de recevoir plusieurs propositions afin de choisir un nouveau créneau.'
            : 'Vous venez de recevoir plusieurs propositions pour prendre rendez-vous avec votre mentor.',
        },
      })
    } catch (error) {
      throw new Error(`[FirebasePlugin](createCoachingProposal) ${error}`)
    }
  }

  async deleteCoachingProposal(conversationId: string) {
    try {
      await this.lmFirebase.conversationsCollection.deleteProposal(conversationId)
      this.store.commit(`conversations/${DELETE_PROPOSAL}`, conversationId)
    } catch (error) {
      throw new Error(`[FirebasePlugin](deleteCoachingProposal) ${error}`)
    }
  }

  private async sendCustomMessage(payload: any) {
    const conversation = this.store.state.conversations[payload.cid]
    const userId = this.store.getters['auth/user']?.id?.toString()

    try {
      if (!userId) {
        throw String('No user id provided')
      }

      const msgInfos = {
        data: payload.data,
      }

      const { message } = await this.lmFirebase.messagesCollection.sendMessage(
        userId,
        conversation.id,
        MessageType.CUSTOM,
        '',
        msgInfos,
      )

      const messages = [message]

      this.store.dispatch(SET_MESSAGE, {
        conversation,
        messages,
      })
    } catch (error) {
      const message = {
        id: NotificationType.ERROR,
        status: 'failed',
        author: userId,
        content: payload.data,
        type: MessageType.CUSTOM,
      }
      const messages = [message]
      this.store.dispatch(SET_MESSAGE, {
        conversation,
        messages,
      })

      throw new Error(`[FirebasePlugin](sendCustomMessage) ${error}`)
    }
  }

  async sendMediaMessage(payload: { cid: string; file: File; type: string; content?: any }) {
    try {
      const conversation = this.store.state.conversations[payload.cid]

      const msgInfos = {
        file: payload.file,
        token: this.store.getters['auth/token'],
        type: payload.type,
      }

      const userId = this.store.getters['auth/user']?.id.toString()

      if (!userId) {
        throw String('No user id provided')
      }

      const sMessage = await this.lmFirebase.messagesCollection.sendMessage(
        userId,
        conversation.id,
        MessageType.MEDIA,
        '',
        msgInfos,
      )

      const message = {
        ...sMessage.message,
        created_at: Date.now(),
      }

      const messages = [message]

      this.store.dispatch(SET_MESSAGE, {
        conversation,
        messages,
      })

      sMessage.fireCall.catch(() => {
        const message = {
          id: NotificationType.ERROR,
          status: 'failed',
          author: userId,
          content: payload.content,
          type: MessageType.MEDIA,
        }
        const messages = [message]
        this.store.dispatch(SET_MESSAGE, {
          conversation,
          messages,
        })
      })
    } catch (error) {
      throw new Error(`[FirebasePlugin](sendMediaMessage) ${error}`)
    }
  }

  async sendTextMessage(payload: { cid: string; message: Record<string, any> }) {
    const conversation = this.store.state.conversations[payload.cid]

    try {
      const sMessage = await this.lmFirebase.messagesCollection.sendMessage(
        payload.message.sender
          ? payload.message.sender
          : this.store.getters['auth/user']?.id?.toString(),
        conversation.id,
        MessageType.TEXT,
        payload.message.text,
      )

      const message = sMessage.message
      message.created_at = Date.now()

      const messages = [message]

      this.store.dispatch(SET_MESSAGE, {
        conversation,
        messages,
      })

      sMessage.fireCall.catch(() => {
        const message = {
          id: sMessage.message.id,
          status: 'failed',
          author: this.store.getters['auth/user']?.id,
          content: {
            text: payload.message.text,
          },
          type: MessageType.TEXT,
        }
        const messages = [message]
        this.store.dispatch(SET_MESSAGE, {
          conversation,
          messages,
        })
      })
    } catch (err) {
      throw new Error(`[FirebasePlugin](sendTextMessage) ${err}`)
    }
  }
}
