import { currency } from './currency'
import { number } from './number'
import { date } from './date'
import { defineNuxtPlugin } from '@/types/nuxt-helpers'

const filters = ({
  defaultCurrency,
  defaultLocale,
}: {
  defaultCurrency: string
  defaultLocale: string
}) => ({
  currency: currency({ defaultCurrency, defaultLocale }),
  date: date(defaultLocale),
  number: number(defaultLocale),
})

const FilterPlugin = defineNuxtPlugin(({ $config }, inject) => {
  inject(
    'filters',
    filters({
      defaultCurrency: $config.FILTERS_DEFAULT_CURRENCY,
      defaultLocale: $config.FILTERS_DEFAULT_LOCALE,
    }),
  )
})

export type Filters = ReturnType<typeof filters>
export default FilterPlugin
