import { GET_TRAINING_COURSES } from '@/constants/actions-type'
import { MERGE_TRAINING_COURSES } from '@/constants/mutations-type'

const actions = {
  async [GET_TRAINING_COURSES]({ commit, dispatch }, studentId) {
    try {
      const trainingCourses = await this.$api.training_courses.get(studentId)
      const user = await this.$api.users.get(studentId)

      commit(MERGE_TRAINING_COURSES, {
        trainingCourses,
        studentId,
      })
      commit(
        'users/MERGE_TRAINING_COURSES',
        {
          trainingCourses,
          studentId,
        },
        { root: true },
      )

      if (user.student?.current_training_course_id) {
        dispatch(
          'signableDocuments/GET_SIGNABLE_DOCUMENTS',
          user.student.current_training_course_id,
          { root: true },
        )
      }

      return trainingCourses
    } catch (error) {
      throw new Error(`[trainingCourses/actions](GET_TRAINING_COURSES) ${error}`)
    }
  },
  async fetchTrainingCourse({ commit }, trainingCourseId) {
    if (!trainingCourseId) {
      return undefined
    }

    const trainingCourse = await this.$api.training_courses.getById(trainingCourseId)

    if (trainingCourse) {
      commit(MERGE_TRAINING_COURSES, {
        trainingCourses: [trainingCourse],
        studentId: trainingCourse.user_id,
      })
    }

    return trainingCourse
  },
  async recommendForTestimonial({ commit }, trainingCourseId) {
    if (!trainingCourseId) {
      return
    }

    const trainingCourse = await this.$api.training_courses.recommendForTestimonial(
      trainingCourseId,
    )

    if (trainingCourse) {
      commit(MERGE_TRAINING_COURSES, {
        trainingCourses: [trainingCourse],
        studentId: trainingCourse.user_id,
      })
    }
  },
}

export default actions
