export default {
  getTaskById: (state) => (taskId) => state[taskId],
  getTasksForStep: (_state, getters, rootState) => (stepId) => {
    const { tasks } = rootState.steps[stepId]
    if (!tasks) {
      return []
    }
    return tasks.map((taskId) => getters.getTaskById(taskId))
  },
}
