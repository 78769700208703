





























































import { Component, Vue, namespace, Prop, Getter } from 'nuxt-property-decorator'
import {
  MkrIcon,
  MkrInteractiveIcon,
  MkrNavItem,
  MkrNavItemGroup,
  MkrNotificationBadge,
} from '@livementor/mikado_reborn/src/index-core'

import NavigationItemsList from '@/components/navigation/common/NavigationItemsList.vue'
import HelpButton from '@/components/navigation/common/HelpButton.vue'
import { User } from '@/models/User'
import { Conversation } from '@/models/Conversation'

import { NavigationMainItem, NavigationCommunityItem } from '@/types/navigation-items'

const usersModule = namespace('users')
const conversationsModule = namespace('conversations')

@Component({
  components: {
    MkrIcon,
    MkrInteractiveIcon,
    MkrNavItem,
    MkrNavItemGroup,
    MkrNotificationBadge,
    NavigationItemsList,
    HelpButton,
  },
})
export default class NavigationManager extends Vue {
  @Prop({ default: [] }) mainEntries!: NavigationMainItem[]

  @Prop({ default: [] }) communityEntries!: NavigationCommunityItem[][]

  showMenu = false

  @Getter('isDetailView') isDetailView!: string

  @Getter('currentScreenName') currentScreenName!: {
    main?: string
    childName?: string
  }

  @Getter('backActionRoute') backActionRoute!: string

  @conversationsModule.Getter('getUnreadConversations')
  unreadConversations!: Conversation[]

  @usersModule.Getter('getCurrentUser')
  currentUser?: User

  get hasUnreadConversations() {
    return this.unreadConversations.length > 0
  }

  backNavigation() {
    if (this.isDetailView) {
      this.$store.commit('SET_CURRENT_SCREEN_VIEW', 'masterView')
      return
    }

    let path = this.$route.fullPath
    path = path.substring(0, path.lastIndexOf('/'))
    this.$router.push({
      path: this.backActionRoute || path,
    })
  }
}
