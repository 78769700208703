import { TimekitAvailability } from '@/components/global/MeetingProposal/types'
import { CoachingContext } from '@/models/Coaching'
import { TrainingCourse } from '@/models/TrainingCourse'

export enum ModalTypes {
  ABSENCE_PROOF_MODAL = 'AbsenceProofModal',
  LEGAL_CERTIFICATION_MODAL = 'LegalCertificationModal',
  FIRST_COURSE_VIEW_MODAL = 'FirstCourseViewModal',
  PERSONAL_AGREEMENT_MODAL = 'PersonalAgreementModal',
  MEETING_PROPOSAL_MODAL = 'MeetingProposalModal',
  MEETING_SALES_MODAL = 'MeetingSalesModal',
  TRAINING_DIPLOMA_MAIN_MODAL = 'TrainingDiplomaMainModal',
  ATTENDANCE_CERTIFICATE_MODAL = 'AttendanceCertificateModal',
}

export interface GlobalModalData {
  type: ModalTypes
  payload: Record<string, any>
}
export type SetGlobalModal = (payload: GlobalModalData | null) => void

export interface GlobalMeetingProposalModal extends GlobalModalData {
  type: ModalTypes.MEETING_PROPOSAL_MODAL
  payload: {
    context: CoachingContext
    conversationId: string
    trainingCourseId: number
    studentId?: string | number
    coaching?: Record<string, any | undefined>
    minSlotsAllowed?: number
    proposal?: {
      context: CoachingContext
      coaching?: number
      slots: {
        id: string
        start: string
        end: string
        resources: TimekitAvailability['resources']
      }[]
    }
  }
}

export interface GlobalMeetingSalesModal {
  type: ModalTypes.MEETING_SALES_MODAL
  payload: {
    context: string
    title?: string
    masterclassTitle?: string
  }
}

export interface GlobalFirstCourseViewModal {
  type: ModalTypes.FIRST_COURSE_VIEW_MODAL
  payload: {
    trainingCourse: TrainingCourse
  }
}

export type SetGlobalMeetingProposalModal = (payload: GlobalMeetingProposalModal | null) => void
export type SetGlobalMeetingSalesModal = (payload: GlobalMeetingSalesModal | null) => void
export type SetGlobalFirstCourseViewModal = (payload: GlobalFirstCourseViewModal | null) => void
