import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '..'
import {
  CreateConversationPayload,
  LMConversation,
  LMConversationKind,
  MembershipStatus,
  PostAddMembers,
} from '@/models/LMConversation'
import { LimitKind } from '@/composables/useConversation'
import { UnreadConversation } from '@/components/chat/SidebarElements/MentorSidebar.vue'

const isBlocked = (conversation: LMConversation) =>
  conversation.kind === LMConversationKind.PEER && !!conversation.other_memberships?.[0].blocking_id

const isArchived = (conversation: LMConversation) => {
  const hasLeftOrArchived = [
    MembershipStatus.ARCHIVED,
    MembershipStatus.LEFT,
    MembershipStatus.KICK,
  ].includes(conversation.user_membership?.status)

  return hasLeftOrArchived || isBlocked(conversation)
}

// function qui ajoute des propriétés à un objet conversation

export type ConversationState = {
  conversations: LMConversation[]
  membersModalParams: {
    conversation: LMConversation | null
    isOpen: boolean
  }
  limitConversationKind: LimitKind | null
  conversationToEdit: LMConversation | null
  remainingConversations: {
    party: number
    peer: number
  }
  fullyLoadedConversations: Array<number>
  isSuspended: boolean
  profileIsDisplayed: boolean
}

export const state = (): ConversationState => ({
  conversations: [],
  membersModalParams: {
    conversation: null,
    isOpen: false,
  },
  limitConversationKind: null,
  conversationToEdit: null,
  remainingConversations: {
    party: 0,
    peer: 0,
  },
  fullyLoadedConversations: [],
  isSuspended: false,
  profileIsDisplayed: false,
})

export const getters: GetterTree<ConversationState, RootState> = {
  getCurrentMentorConversation: (_, __, ___, rootGetters): UnreadConversation | null => {
    const activedConversations = rootGetters['conversations/getActivedConversations']
    const futureConversations = rootGetters['conversations/getFuturesConversations']

    if (!activedConversations.length && !futureConversations.length) {
      return null
    }

    const mentorConversation = activedConversations[0] || futureConversations[0]
    const receiverId = rootGetters['conversations/getReceiversIdsForConversation'](
      mentorConversation.id,
    )
    const mentor = rootGetters['users/getUserById'](receiverId)

    const currentUser = rootGetters['users/getCurrentUser']
    const isUnread = currentUser?.unreadConversations?.includes(mentorConversation.id) ?? false

    return {
      ...mentorConversation,
      user: mentor,
      isUnread,
    }
  },
  getConversation: (state) => (id: number) => {
    const c = state.conversations.find((x: LMConversation) => x.id === id)
    if (!c) {
      return null
    }
    return {
      ...c,
      isArchived: isArchived(c),
      isBlocked: isBlocked(c),
      hasLeft: c.user_membership?.status === MembershipStatus.LEFT,
      allMessagesAreLoaded: c.id && state.fullyLoadedConversations.includes(c.id),
    }
  },
  getConversationsToDisplay: (state) => {
    const conversations = state.conversations.filter((c: LMConversation) => !isArchived(c))

    // sort by last activity
    return conversations.sort((a, b) => {
      const aDate = a.user_membership.conversation_last_activity_at
      const bDate = b.user_membership.conversation_last_activity_at

      return new Date(bDate).getTime() - new Date(aDate).getTime()
    })
  },
  getArchivedConversations: (state) => {
    return state.conversations.filter((c: LMConversation) => isArchived(c))
  },
  getPeerConversations: (_, getters) => {
    return (getters.getConversationsToDisplay || []).filter(
      (x: LMConversation) => x.kind === LMConversationKind.PEER,
    )
  },
  noConversations: (_, getters) => {
    return (
      getters.getCurrentMentorConversation === null &&
      getters.getConversationsToDisplay.length === 0
    )
  },
  getGroupConversations: (_, getters) => {
    return (getters.getConversationsToDisplay || []).filter(
      (x: LMConversation) => x.kind === LMConversationKind.PARTY,
    )
  },
  getIsMembersModalOpen: (state) => {
    return !!state.membersModalParams.conversation || state.membersModalParams.isOpen
  },
  getIsLimitConversationModalOpen: (state) => {
    return !!state.limitConversationKind
  },
  getConversationByUserId: (state) => (userId: number) => {
    const peerConversations = state.conversations.filter((c) => c.kind === LMConversationKind.PEER)

    return peerConversations.find((conversation) =>
      conversation.user_ids?.find((id) => id === userId),
    )
  },
  getIsEditConversationModalOpen: (state) => {
    return !!state.conversationToEdit
  },
}

export const mutations: MutationTree<ConversationState> = {
  setConversations(state, conversations) {
    state.conversations = conversations
  },
  setAsRead(state, { conversationId, read }: { conversationId: number; read: boolean }) {
    const index = state.conversations.findIndex((x) => x.id === conversationId)
    if (index < 0) {
      return
    }

    state.conversations[index].user_membership.unread = !read
  },
  setIsSuspended(state, value) {
    state.isSuspended = value
  },
  setProfileIsDisplayed(state, value) {
    state.profileIsDisplayed = value
  },
  reorderConversation(state, conversationId: number) {
    const index = state.conversations.findIndex((x) => x.id === conversationId)
    if (index < 0) {
      return
    }

    state.conversations[index].user_membership.conversation_last_activity_at = new Date()
  },
  createOrUpdateConversation(state, conversation) {
    const index = state.conversations.findIndex((x) => x.id === conversation.id)

    const updatedConversation = {
      ...state.conversations[index],
      ...conversation,
    }
    index >= 0
      ? state.conversations.splice(index, 1, updatedConversation)
      : state.conversations.push(updatedConversation)
  },
  deleteConversation(state, conversationId) {
    const index = state.conversations.findIndex((x) => x.id === conversationId)
    if (index >= 0) {
      state.conversations.splice(index, 1)
    }
  },
  setMembersModalParams(state, params: ConversationState['membersModalParams']) {
    state.membersModalParams = params
  },
  setLimitConversationKind(state, kind: LimitKind | null) {
    state.limitConversationKind = kind
  },
  setConversationToEdit(state, conversation: LMConversation | null) {
    state.conversationToEdit = conversation
  },
  setRemainingConversations(state, remainingConversations) {
    state.remainingConversations = remainingConversations
  },
  setEndOfMessages(state, conversationId: number) {
    state.fullyLoadedConversations.push(conversationId)
  },
  decrementRemainingConversations(state, kind: LMConversationKind) {
    state.remainingConversations[kind] = state.remainingConversations[kind] - 1
  },
}

export const actions: ActionTree<ConversationState, RootState> = {
  async fetchConversations({ commit }) {
    const index = await this.$api.chat.fetchConversations()

    commit('setRemainingConversations', {
      peer: index.user.nb_conversations_left_to_start_peer,
      party: index.user.nb_conversations_left_to_start_party,
    })

    commit('setConversations', index.conversations)
    commit('setIsSuspended', index.user.suspended)
    commit('setProfileIsDisplayed', index.user.profile_displayed)
  },
  async fetchConversationById({ commit }, conversationId: string) {
    const conversation = await this.$api.chat.fetchConversationById(conversationId)

    commit('chat/messages/insertMessages', conversation.messages, { root: true })
    commit('createOrUpdateConversation', conversation)
  },
  async createConversation({ commit }, payload: CreateConversationPayload) {
    const conversation = await this.$api.chat.createConversation(payload)

    commit('decrementRemainingConversations', conversation.kind)

    this.$router.push(`/chat/peer-conversations/${conversation.id}`)
  },
  async acceptInvitation(_, membershipId: number) {
    await this.$api.chat.updateMembership({ status: 'active' }, membershipId)
  },
  async denyInvitation({ commit }, membershipId: number) {
    const response = await this.$api.chat.updateMembership({ status: 'denied' }, membershipId)

    commit('deleteConversation', response.conversation_id)
  },
  setMembersModalParams({ commit }, params: ConversationState['membersModalParams']) {
    commit('setMembersModalParams', params)
  },
  closeMembersModal({ commit }) {
    commit('setMembersModalParams', {
      conversation: null,
      isOpen: false,
    })
  },
  setLimitConversationKind({ commit }, kind: LMConversationKind | null) {
    commit('setLimitConversationKind', kind)
  },
  setConversationToEdit({ commit }, conversation: LMConversation | null) {
    commit('setConversationToEdit', conversation)
  },
  async updateConversation(_, { id, payload }) {
    await this.$api.chat.updateConversation(payload, id)
  },
  async addMembers(_, payload: PostAddMembers) {
    await this.$api.chat.addMembers(payload)
  },
  async setAsRead({ commit }, { conversation, value }) {
    await this.$api.chat.updateMembership({ read: value }, conversation.user_membership.id)

    commit('setAsRead', {
      conversationId: conversation.id,
      read: value,
    })
  },
}
