export default {
  bookmarks: (state, _getters, _rootState, rootGetters) => {
    return Object.values(state)
      .sort((a, b) => b.messageCreatedAt - a.messageCreatedAt)
      .map((bookmark) => ({
        ...bookmark,
        message: rootGetters['messages/getMessageById'](bookmark.id),
      }))
  },
}
