import { AxiosResponse } from 'axios'
import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import { Assessment } from '@/models/Assessment'
import { Certification } from '@/models/Certification'
import { Coaching, CoachingContext, CoachingStatus } from '@/models/Coaching'
import { Document, DocumentKind, DocumentStatus } from '@/models/Document'
import { Objective, Task } from '@/models/FollowUp'
import { Lesson, LessonShort } from '@/models/Lesson'
import { Masterclass } from '@/models/Masterclass'
import { Module } from '@/models/Module'
import { Note } from '@/models/Note'
import { Onboarding } from '@/models/Onboarding'
import { SalesOwner } from '@/models/SalesOwner'
import { Template } from '@/models/Template'
import { TrainingCourse } from '@/models/TrainingCourse'
import { UserFromApi, UserProfile } from '@/models/User'
import { FeedBack } from '@/models/Feedback'
import { ApiUserAvaibilities } from '@/models/ApiUserAvaibilities'
import { FileUploadError, FileUploadErrorTypes } from '@/services/FileUploadError'
import { StatsResponse } from '@/models/Stats'
import { Enrollment, LMWebinar } from '@/models/WebinarAlgolia'
import { OffsetPaginatedResult, PaginatedResult } from '@/models/Pagination'
import Milestone from '@/models/Milestone'
import { ProfileShow, CityItem, IndexCommunityResponse } from '@/models/Community'
import {
  CreateConversationPayload,
  IndexConversationResponse,
  LMConversation,
  PostAddMembers,
  PostBlocking,
  PostReport,
  PutConversation,
  UpdateMembership,
} from '@/models/LMConversation'
import { LMMessage, PostMessage, PutMessage } from '@/models/Message'
import { Action } from '@/models/Action'
import { Journey } from '@/models/Journey'

const getDataFromCall = <T>(response: AxiosResponse): T => {
  try {
    return response.data.data
  } catch (error) {
    throw new Error(`[LmApi](getDataFromCall) ${error}`)
  }
}

function objectToFormData(obj: Record<string, any>, formData = new FormData(), parentKey = '') {
  for (const key in obj) {
    const propName = parentKey ? `${parentKey}[${key}]` : key

    if (obj[key] instanceof File) {
      formData.append(propName, obj[key])
    } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      objectToFormData(obj[key], formData, propName)
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((item: any) => {
        formData.append(`${propName}[]`, item)
      })
    } else {
      formData.append(propName, obj[key])
    }
  }
  return formData
}

interface GetCoachingsPayload {
  student: number
  mentor?: number
  context?: CoachingContext
  status?: CoachingStatus
  starting_after?: string
  ending_before?: string
}

export class LmApi {
  private readonly api: NuxtAxiosInstance

  constructor(readonly axiosInstance: NuxtAxiosInstance) {
    this.api = axiosInstance
  }

  get instance() {
    return this.api
  }

  axiosHelper = {
    post: async <T>(path: string, body?: Record<string, any>, options?: Record<string, any>) => {
      const response = await this.api.post<{ data: T }, any>(path, body, options)
      return getDataFromCall<T>(response)
    },
    get: async <T>(path: string, options?: Record<string, any>) => {
      const response = await this.api.get<{ data: T }, any>(path, options)
      return getDataFromCall<T>(response)
    },
    put: async <T>(path: string, options?: Record<string, any>) => {
      const response = await this.api.put<{ data: T }, any>(path, options)
      return getDataFromCall<T>(response)
    },
  }

  actions = {
    get: () => {
      return this.api.get<Action[]>('v1/actions')
    },
    cancel: (actionId: number) => {
      return this.api.put(`v1/actions/${actionId}/cancel`)
    },
  }

  assessment = {
    get: ({
      certification_id,
      with_exercises_only,
    }: {
      certification_id: number
      with_exercises_only: boolean
    }) => {
      return this.axiosHelper.get<Assessment[]>('/v1/assessments', {
        params: {
          certification_id,
          with_exercises_only,
        },
      })
    },

    upload: ({
      assessmentId,
      formData,
      config,
      maxFileSize,
      allowedFormats,
    }: {
      assessmentId: number
      formData: FormData
      config: Record<string, any>
      maxFileSize: number
      allowedFormats: string[]
    }) => {
      try {
        const file = formData.get('file') as File

        if (!file) {
          throw String('no file provided in formdata')
        }

        if (!allowedFormats.includes(file.type)) {
          return Promise.reject(
            new FileUploadError(
              FileUploadErrorTypes.FILE_FORMAT_NOT_ALLOWED,
              'File format is not allowed',
            ),
          )
        }

        if (file.size / Math.pow(1024, 2) > maxFileSize) {
          return Promise.reject(
            new FileUploadError(FileUploadErrorTypes.FILE_TOO_LARGE, 'File is too large'),
          )
        }
        return this.api.post(
          `/v1/assessments/${assessmentId}/files?fileName=${encodeURIComponent(file.name)}`,
          formData,
          {
            ...config,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
      } catch (error) {
        throw new Error(`[LmApi](assessment.upload) ${error}`)
      }
    },

    delete: ({ assessmentId, fileId }: { assessmentId: number; fileId: number }) => {
      return this.api.delete(`/v1/assessments/${assessmentId}/files/${fileId}`)
    },
  }

  community = {
    getProfiles: (query = {}) => {
      return this.api.$get<IndexCommunityResponse>(`/v1/community/profiles`, { params: query })
    },
    getProfile: (userId: number): Promise<ProfileShow> => {
      return this.axiosHelper.get(`/v1/community/profiles/${userId}`)
    },
    updateProfile: (userId: string, payload: any): Promise<ProfileShow> => {
      return this.axiosHelper.put(`v1/community/profiles/${userId}`, {
        community_profile: payload,
      })
    },
    createProfile: (): Promise<ProfileShow> => {
      return this.axiosHelper.post('v1/community/profiles')
    },
    updateProfilePicture: (userId: number, payload: FormData) => {
      return this.api.put(`v1/community/profiles/${userId}/avatar`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    getCurrentProfile: (): Promise<ProfileShow> => {
      return this.axiosHelper.get(`/v1/community/profiles/me`)
    },
    getCities: (query: string): Promise<CityItem[]> => {
      return this.axiosHelper.get(`/v1/community/profiles/cities?city_search=${query}`)
    },
  }

  journey = {
    get: () => {
      return this.instance.get<Journey>('/v1/journey')
    },
  }

  certifications = {
    get: (studentId: number | string) => {
      return this.axiosHelper.get<Certification[]>(`/v1/certifications?student=${studentId}`)
    },
  }

  isograd = {
    startTest: (
      certificationId: number,
    ): Promise<{
      created: boolean
      test_url: string
    }> => {
      return this.axiosHelper.put(`/v1/certifications/${certificationId}/start_test`)
    },
  }

  chat = {
    uploadAsset: (file: FormData) => {
      return this.axiosHelper.post<{ url: string }>('/v1/chat/assets', file, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
    },
    addMembers: (payload: PostAddMembers) => {
      return this.axiosHelper.post('/v1/chat/memberships', payload)
    },
    fetchConversations: () => {
      return this.axiosHelper.get<IndexConversationResponse>('/v1/chat/conversations')
    },
    fetchConversationById: (conversationId: string) => {
      return this.axiosHelper.get<LMConversation>(`/v1/chat/conversations/${conversationId}`)
    },
    createConversation: (payload: CreateConversationPayload) => {
      const formData = objectToFormData(payload)
      return this.axiosHelper.post<LMConversation>('/v1/chat/conversations', formData)
    },
    newConversation: (ids: string) => {
      return this.axiosInstance.$get<{ data: LMConversation }>(
        '/v1/chat/conversations/new?member_ids=' + ids,
      )
    },
    updateConversation: (payload: PutConversation, id: number) => {
      const formData = objectToFormData({
        chat_conversation: payload,
      })
      return this.axiosHelper.put<{ data: LMConversation }>(
        `/v1/chat/conversations/${id}`,
        formData,
      )
    },
    report: (payload: PostReport) => {
      return this.axiosHelper.post(`/v1/chat/reports`, payload)
    },
    block: (payload: PostBlocking) => {
      return this.axiosHelper.post(`/v1/chat/blockings`, payload)
    },
    unblock: (id: number) => {
      return this.axiosInstance.$delete(`/v1/chat/blockings/${id}`)
    },
    updateMembership: (payload: UpdateMembership, membershipId: number) => {
      return this.axiosHelper.put<any>(`/v1/chat/memberships/${membershipId}`, {
        chat_membership: payload,
      })
    },
    fetchSuggestions: (params: { search?: string; conversation_id?: number | null }) => {
      return this.axiosHelper.get<any>(`/v1/community/profiles/suggested_pairs`, {
        params,
      })
    },
  }

  messages = {
    fetch: ({
      conversationId,
      message_offset_id,
    }: {
      conversationId: number
      message_offset_id?: number
    }) => {
      return this.axiosInstance.get<OffsetPaginatedResult<LMMessage[]>>(
        `/v1/chat/messages?conversation_id=${conversationId}&message_offset_id=${message_offset_id}`,
      )
    },
    send: (payload: PostMessage) => {
      const formData = objectToFormData({
        message: payload,
      })

      return this.axiosHelper.post<{ data: LMMessage }>('/v1/chat/messages', formData)
    },
    edit: (payload: PutMessage) => {
      const { id, ...rest } = payload

      const formData = objectToFormData({
        message: rest,
      })
      return this.axiosHelper.put<{ data: LMMessage }>(`/v1/chat/messages/${payload.id}`, formData)
    },
    getBookmarks: async () => {
      const { data } = await this.axiosInstance.get<PaginatedResult<LMMessage>>(
        `/v1/chat/messages?bookmarked=true`,
      )
      return data.data
    },
    createBookmark: async (id: number) => {
      const { data } = await this.api.post('v1/bookmarks', {
        bookmarkable_id: id,
        bookmarkable_type: 'Chat::Message',
      })
      return data
    },
    deleteBookmark: async (id: number): Promise<void> => {
      await this.api.delete(`v1/bookmarks/${id}`)
    },
  }

  coachings = {
    get: (payload: GetCoachingsPayload) => {
      const queries: string[] = []

      const queriesTypes = [
        'context',
        'mentor',
        'student',
        'status',
        'starting_after',
        'ending_before',
      ]

      queriesTypes.forEach((query) => {
        if (payload[query as keyof GetCoachingsPayload]) {
          queries.push(`${query}=${payload[query as keyof GetCoachingsPayload]}`)
        }
      })

      const stringQuery = queries.length ? `?${queries.join('&')}` : ''

      return this.axiosHelper.get<Coaching[]>(`v1/coachings${stringQuery}`)
    },
    create: ({
      start,
      end,
      mentor_id,
      student_id,
      context,
      certification,
      training_course_id,
    }: {
      start: string
      end: string
      mentor_id: string | number
      student_id: string | number
      context: CoachingContext
      certification: number
      training_course_id: number
    }) => {
      return this.axiosHelper.post<Coaching>('v1/coachings', {
        start,
        end,
        mentor_id,
        student_id,
        context,
        certification,
        training_course_id,
      })
    },
    reschedule: ({
      start,
      end,
      id,
      request_owner_id,
    }: {
      id: number
      request_owner_id: string | number
      start?: string
      end?: string
    }) => {
      return this.axiosHelper.put<Coaching>(`v1/coachings/${id}/reschedule`, {
        id,
        start,
        end,
        request_owner_id,
      })
    },
    cancel: (coachingId: number) => {
      return this.api.put(`v1/coachings/${coachingId}/cancel`)
    },
    complete: (coachingId: number) => {
      return this.api.put(`v1/coachings/${coachingId}/complete`)
    },
    confirmed_as_no_show: (coachingId: number) => {
      return this.axiosHelper.put<Coaching>(`v1/coachings/${coachingId}/confirm_no_show`, {
        coaching_id: coachingId,
      })
    },
  }

  form = {
    onboarding: {
      get: (section?: string) => {
        return this.axiosHelper.get<Onboarding>('v1/form/onboarding/section', {
          params: { section_ref: section },
        })
      },
      put: (data: any) => {
        return this.axiosHelper.put<Onboarding>('v1/form/onboarding', data)
      },
    },
  }

  signable_documents = {
    get: ({
      trainingCourseId,
      kind,
      status,
    }: {
      trainingCourseId: number
      kind: DocumentKind
      status?: DocumentStatus
    }) => {
      return this.axiosHelper.get<Document[]>('v1/documents', {
        params: {
          training_course: trainingCourseId,
          kind,
          status,
        },
      })
    },
    getById: (documentId: number) => {
      return this.axiosHelper.get<Document>(`v1/documents/${documentId}`)
    },
    toBeSigned: (userId?: number) => {
      return this.axiosHelper.get<Document[]>('v1/documents/to_be_signed', {
        params: {
          user_id: userId,
        },
      })
    },
  }

  settings = {
    get: () => {
      return this.axiosHelper.get<{ run_host_url: string; api_url: string }>('v1/settings')
    },
  }

  training_courses = {
    get: (studentId: number) => {
      try {
        return this.axiosHelper.get<TrainingCourse[]>(`v1/training_courses?student=${studentId}`)
      } catch (error) {
        throw new Error(`[Api](trainingCourses/get) ${error}`)
      }
    },
    getById: (trainingCourseId: number) => {
      try {
        return this.axiosHelper.get<TrainingCourse>(`v1/training_courses/${trainingCourseId}`)
      } catch (error) {
        throw new Error(`[Api](trainingCourses/getById) ${error}`)
      }
    },
    recommendForTestimonial: (trainingCourseId: number) => {
      return this.api.put(`/v1/training_courses/${trainingCourseId}/flag_for_testimonial`, {})
    },
  }

  users = {
    get: (userId: number | string) => {
      try {
        return this.axiosHelper.get<UserFromApi>(`v1/users/${userId}`)
      } catch (error) {
        throw new Error(`[Api](users/get) ${error}`)
      }
    },

    me: () => {
      try {
        return this.axiosHelper.get<UserFromApi>(`v1/users/me`)
      } catch (error) {
        throw new Error(`[Api](users/me) ${error}`)
      }
    },

    project: (userId: number | string) => {
      return this.api.get(`v1/users/${userId}/project`)
    },

    resetPassword: ({ email, host }: { email: string; host: string }) => {
      return this.api.post('passwords/reset', {
        email,
        redirect_uri: `https://${host}/resetPassword`,
      })
    },

    resetTokenIsValid: (token: string) => {
      return this.axiosHelper.get<{ available_for_reset: boolean }>(
        `/passwords/reset?token=${token}`,
      )
    },

    updatePassword: ({
      token,
      password,
      passwordConfirmation,
    }: {
      token: string
      password: string
      passwordConfirmation: string
    }) => {
      return this.api.post('/passwords', {
        reset_password_token: token,
        password_confirmation: passwordConfirmation,
        password,
      })
    },

    update: (userId: string | number, payload: any) => {
      return this.api.put(`v1/users/${userId}`, payload)
    },

    preferredAvailabilities: async ({
      userId,
      context,
    }: {
      userId: string | number
      context: 'kickoff' | 'global'
    }): Promise<ApiUserAvaibilities> => {
      return (
        await this.api.get('v1/preferred_availabilities', {
          params: { context, user_id: userId },
        })
      ).data.data
    },
  }

  profile = {
    get: () => {
      return this.axiosHelper.get<UserProfile>('v1/users/me/profile')
    },

    update: (payload: any, requestOptions?: any) => {
      return this.api.put('v1/users/me/profile', payload, {
        ...requestOptions,
      })
    },

    changePassword: ({
      currentPassword,
      password,
      passwordConfirmation,
    }: {
      currentPassword: string
      password: string
      passwordConfirmation: string
    }) => {
      return this.api.post('/passwords/change', {
        current_password: currentPassword,
        password,
        password_confirmation: passwordConfirmation,
      })
    },
  }

  project = {
    get: () => {
      return this.api.get('v1/users/me/project')
    },

    update: (payload: any) => {
      return this.api.put('v1/users/me/project', payload)
    },
  }

  invoices = {
    get: () => {
      return this.api.get('/v1/invoices')
    },
  }

  contracts = {
    get: () => {
      return this.api.get('/v1/contracts/')
    },
  }

  absence = {
    get: (payload: { timeframes: 'past' | 'present' | 'future' }) => {
      return this.api.get('/v1/absences', { params: payload })
    },

    add: (payload: any) => {
      return this.api.post('/v1/absences', payload)
    },

    delete: (payload: { id: number }) => {
      return this.api.delete(`/v1/absences/${payload.id}`)
    },
  }

  calendars_sync = {
    get: () => {
      return this.api.get('v1/calendar_sync')
    },
    update: ({ calendar_id, sync }: { calendar_id: number | string; sync: boolean }) => {
      return this.api.put('v1/calendar_sync', { calendar_id, sync })
    },
    switch: ({ timekit_id }: { timekit_id: string }) => {
      return this.api.put('v1/calendar_sync/switch', { timekit_id })
    },
    syncNow: () => {
      return this.api.put('v1/calendar_sync/sync_now')
    },
  }

  masterclasses = {
    get: async () => {
      try {
        const { data } = await this.api.get<Masterclass[]>('v1/masterclasses')
        return data
      } catch (error) {
        throw new Error(`[Api](masterclasses/get) ${error}`)
      }
    },
    getById: (masterclassId: Masterclass['id']) => {
      try {
        return this.axiosHelper.get<Masterclass>(`v1/masterclasses/${masterclassId}`)
      } catch (error) {
        throw new Error(`[Api](masterclasses/getById) ${error}`)
      }
    },
    getModulesByMasterClassId: (masterclassId: Masterclass['id']) => {
      try {
        return this.axiosHelper.get<Module[]>(`v1/modules?masterclasses=${masterclassId}`)
      } catch (error) {
        throw new Error(`[Api](masterclasses/getModulesByMasterClassId) ${error}`)
      }
    },
    getModulesById: (moduleId: number) => {
      try {
        return this.axiosHelper.get<Module>(`v1/modules/${moduleId}`)
      } catch (error) {
        throw new Error(`[Api](masterclasses/getModulesById) ${error}`)
      }
    },
    getLessonsByMasterClassId: (masterclassId: Masterclass['id']) => {
      try {
        return this.axiosHelper.get<LessonShort[]>(`v1/lessons?masterclasses=${masterclassId}`)
      } catch (error) {
        throw new Error(`[Api](masterclasses/getLessonsByMasterClassId) ${error}`)
      }
    },
    getBonusByMasterClassId: (masterclassId: Masterclass['id']) => {
      try {
        return this.axiosHelper.get<LessonShort[]>(`v1/bonus?masterclasses=${masterclassId}`)
      } catch (error) {
        throw new Error(`[Api](masterclasses/getBonusByMasterClassId) ${error}`)
      }
    },
    getMilestonesByMasterClassId: (masterclassId: Masterclass['id']): Promise<Milestone[]> => {
      try {
        return this.axiosHelper.get(`v1/milestones?masterclasses=${masterclassId}`)
      } catch (error) {
        throw new Error(`[Api](masterclasses/getMilestoneByMasterClassId) ${error}`)
      }
    },
    getLessonById: (lessonId: number) => {
      try {
        return this.axiosHelper.get<Lesson>(`v1/lessons/${lessonId}`)
      } catch (error) {
        throw new Error(`[Api](masterclasses/getLessonById) ${error}`)
      }
    },
    getBonusById: (id: number) => {
      return this.api.get(`v1/bonus/${id}`)
    },
    getMilestoneById: (id: number): Promise<Milestone> => {
      return this.axiosHelper.get(`v1/milestones/${id}`)
    },
    getLessonViews: (ids: number[]) => {
      const lessonsQuery = `?lessons=${[ids || []].join(',')}`
      return this.api.get(`v1/lesson_views/${lessonsQuery}`)
    },
    setLessonView: (id: number) => {
      return this.api.post('v1/lesson_views', { lesson_id: id })
    },
  }

  webinar = {
    getPaginated: async (query: any): Promise<PaginatedResult<LMWebinar>> => {
      const { data } = await this.api.get('v1/webinars', { params: query })
      return data
    },
    getBookmarks: async (): Promise<PaginatedResult<LMWebinar>> => {
      const { data } = await this.api.get('v1/bookmarks?bookmarkable_type=webinar')
      return data
    },
    createBookmark: async (id: number): Promise<LMWebinar> => {
      const { data } = await this.api.post('v1/bookmarks', {
        bookmarkable_id: id,
        bookmarkable_type: 'Webinar',
      })
      return data
    },
    deleteBookmark: async (id: number): Promise<void> => {
      await this.api.delete(`v1/bookmarks/${id}`)
    },
    getById: (id: number | string) => {
      return this.api.get('v1/webinars/' + id)
    },
    enroll: (id: number | string): Promise<Enrollment> => {
      return this.axiosHelper.post('v1/enrollments/', {
        enrollable_id: id,
        enrollable_type: 'webinar',
      })
    },
    join: (id: number | string) => {
      return this.axiosHelper.put<{ join_url: string }>(`v1/enrollments/${id}/join`)
    },
    unsubscribe: (id: number | string) => {
      return this.api.delete('v1/enrollments/' + id)
    },
    getUserEnrollment: async (id: number | string): Promise<PaginatedResult<Enrollment>> => {
      const { data } = await this.api.get(
        'v1/enrollments?enrollables=' + id + '&enrollable_type=webinar',
      )
      return data
    },
    setWebinarView: (id: number, mode: 'live' | 'replay') => {
      return this.api.post('v1/webinar_views', {
        webinar_id: id,
        mode,
      })
    },
  }

  edition = {
    get: () => {
      return this.api.get('v1/editions')
    },
  }

  feedback = {
    get: (id: number | string, type: 'webinar' | 'course') => {
      return this.api.get(`v1/feedbacks?feedbackables=${id}&feedbackable_type=${type}`)
    },
    send: (feedback: FeedBack) => {
      return this.instance.post<{ data: FeedBack }>('v1/feedbacks', feedback)
    },
  }

  objective = {
    get: (projectId: number) => {
      return this.api.get('v1/objective', {
        params: {
          project_id: projectId,
        },
      })
    },
    update: (objective: any) => {
      return this.api.put('v1/objective', objective, {
        params: {
          project_id: objective.project_id,
        },
      })
    },
  }

  steps = {
    get: (objectiveId: number) => {
      return this.api.get('v1/steps', {
        params: {
          objective_id: objectiveId,
        },
      })
    },
    create: (objectiveId: number, step: any) => {
      return this.api.post('v1/steps', step, {
        params: {
          objective_id: objectiveId,
        },
      })
    },
    update: (step: any) => {
      return this.api.put(`v1/steps/${step.id}`, step)
    },
    delete: (stepId: number) => {
      return this.api.delete(`v1/steps/${stepId}`)
    },
    check: (stepId: number) => {
      return this.api.put(`v1/steps/${stepId}/check`)
    },
  }

  tasks = {
    get: (objectiveId: number) => {
      return this.api.get('v1/tasks', {
        params: {
          objective_id: objectiveId,
        },
      })
    },
    create: (stepId: number, task: any) => {
      return this.api.post('v1/tasks', task, {
        params: {
          step_id: stepId,
        },
      })
    },
    update: (task: Task) => {
      return this.api.put(`v1/tasks/${task.id}`, task)
    },
    delete: (taskId: Task['id']) => {
      return this.api.delete(`v1/tasks/${taskId}`)
    },
    check: (taskId: Task['id']) => {
      return this.api.put(`v1/tasks/${taskId}/check`)
    },
    uncheck: (taskId: Task['id']) => {
      return this.api.put(`v1/tasks/${taskId}/uncheck`)
    },
    drop: (taskId: Task['id']) => {
      return this.api.put(`v1/tasks/${taskId}/drop`)
    },
  }

  notes = {
    get: (objectiveId: Objective['id']) => {
      return this.api.get('v1/notes', {
        params: {
          objective_id: objectiveId,
        },
      })
    },
    create: (objectiveId: Objective['id'], note: any) => {
      return this.api.post('v1/notes', note, {
        params: {
          objective_id: objectiveId,
        },
      })
    },
    update: (note: any) => {
      return this.api.put(`v1/notes/${note.id}`, note)
    },
    delete: (noteId: Note['id']) => {
      return this.api.delete(`v1/notes/${noteId}`)
    },
  }

  stats = {
    /**
     * @returns {Promise<import('@/models/Stats').StatsResponse>}
     */
    get: () => {
      return this.axiosHelper.get<StatsResponse>('v1/stats')
    },
  }

  template = {
    create: (title: string, content: string) => {
      return this.api.post('v1/message_templates', {
        title,
        html_content: content,
      })
    },
    list: () => {
      return this.api.get('v1/message_templates')
    },
    get: (id: Template['id']) => {
      return this.api.get(`v1/message_templates/${id}`)
    },
    update: ({ id, title, content }: { id: number; title: string; content: string }) => {
      return this.api.put(`v1/message_templates/${id}`, {
        title,
        html_content: content,
      })
    },
  }

  sales = {
    getSalesOwner: () => {
      try {
        return this.axiosHelper.get<SalesOwner>('v1/sales/sales_owner')
      } catch (error) {
        throw new Error(`[Api](getSalesOwner) ${error}`)
      }
    },
    askForCall: async (phoneNumber: string): Promise<{ phone_number: string }> => {
      try {
        const { data } = await this.api.post('v1/sales/ask_for_call', {
          phone_number: phoneNumber,
        })

        return data
      } catch (error) {
        throw new Error(`[Api](askForCall) ${error}`)
      }
    },
    bookConfirmation: (): Promise<void> => {
      try {
        return this.api.post('v1/sales/booking_confirmation')
      } catch (error) {
        throw new Error(`[Api](bookConfirmation) ${error}`)
      }
    },
  }
}
