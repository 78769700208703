import Vue from 'vue'
import { MERGE_TRAINING_COURSES } from '@/constants/mutations-type'

const mutations = {
  [MERGE_TRAINING_COURSES]: (state, { trainingCourses }) => {
    trainingCourses.forEach((x) => Vue.set(state, x.id, x))
  },
  MERGE_SIGNABLE_DOCUMENTS: (state, { trainingCourseId, signableDocuments }) => {
    state[trainingCourseId].signable_documents = signableDocuments
  },
}

export default mutations
