// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/backgrounds/mouette.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*purgecss start ignore*/\n.LoginLayout[data-v-0ae2845a]{\n  overflow:auto\n}\n@media (min-width: 1024px){\n.LoginLayout[data-v-0ae2845a]{\n    background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat:no-repeat;\n    background-position-x:right;\n    background-position-y:top;\n    background-size:contain\n}\n}\n.LoginLayout .btn[data-v-0ae2845a]:hover{\n  opacity:.5\n}\n\n/*purgecss end ignore*/", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
