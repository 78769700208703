import { CoachingContext } from './Coaching'
import { User, UserRole } from './User'

export enum ActionKind {
  FIRSTMESSAGE = 'first_message',
  FOLLOWUP = 'follow_up',
  REPLY = 'reply',
  SCHEDULE_CERTIF = 'schedule_certif',
  SCHEDULE_CANCELLED_COACHING = 'schedule_cancelled_coaching',
  SCHEDULE_NO_SHOW = 'schedule_no_show',
  SCHEDULE_KICKOFF = 'schedule_kickoff',
}

export type ActionFilter = 'first_message' | 'follow_up' | 'reply' | 'appointment'

export enum ActionStatus {
  TODO = 'todo',
  DONE = 'done',
  CANCELLED = 'cancelled',
}

export type ActionUser = Pick<User, 'id' | 'given_name' | 'family_name' | 'picture' | 'role'>

export interface ActionMentor extends ActionUser {
  role: UserRole.MENTOR
}

export interface ActionStudent extends ActionUser {
  role: UserRole.STUDENT
}

export interface Action {
  id: number
  kind: ActionKind
  status: ActionStatus
  due_on: string
  completed_at?: string | null
  created_at: string
  mentor: ActionMentor
  student: ActionStudent
  metadata: {
    conversation_id: string
    message_id?: string
    coaching_start_date?: string
    coaching_context?: CoachingContext
    tc_title?: string
    tc_start_date?: string
  }
  training_course_id: number
}

export interface INotification extends Action {
  user: User
}
