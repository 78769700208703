import Vue from 'vue'
import { MERGE_ASSESSMENTS, DELETE_ASSESSMENT_FILE } from '@/constants/mutations-type'

export default {
  [MERGE_ASSESSMENTS]: (state, assessments) => {
    assessments.forEach((assessment) => {
      Vue.set(state, assessment.id, { ...state[assessment.id], ...assessment })
    })
  },
  [DELETE_ASSESSMENT_FILE]: (state, { assessmentId, fileId }) => {
    state[assessmentId].skill.exercise.files = state[assessmentId].skill.exercise.files.filter(
      (file) => file.id !== fileId,
    )
  },
}
