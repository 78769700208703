import { defineNuxtMiddleware } from '@/types/nuxt-helpers'

export default defineNuxtMiddleware(({ store, route, error: nuxtError }) => {
  try {
    // recupere "featureFlag" de la route
    const [routeFeatureFlag] = route.meta.flatMap((m: any) => m.featureFlag).filter(Boolean)

    // si la route a un featureFlag
    if (routeFeatureFlag) {
      // si l'utilisateur n'a pas le featureFlag
      if (!store.getters.userHasFeature(routeFeatureFlag)) {
        // redirige vers une page 404
        throw new Error('404')
      }
    }
  } catch (error: string | any) {
    nuxtError(error)
  }
})
