export enum CoachingContext {
  KICKOFF = 'kickoff',
  CERTIFICATION_EXAM = 'certification_exam',
  QCM = 'qcm',
  REGULAR = 'regular',
}

export enum CoachingStatus {
  CONFIRMED = 'confirmed',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  RESCHEDULED = 'rescheduled',
  NO_SHOW = 'no_show',
  // CANCELLED_BY_CUSTOMER = 'cancelled_by_customer',
  // RESCHEDULED_BY_CUSTOMER = 'rescheduled_by_customer',
}

export type Coaching = {
  id: number
  status: CoachingStatus
  context: CoachingContext
  start_date: string
  end_date: string
  timekit_id?: string
  zoom_id?: string
  replay_url?: string
  replay_deleted_at?: string
  zoom_start_url?: string
  stop_asking_feedback_at?: string
  confirmed_no_show_at?: string
  issue_detection_at?: string
  no_show_kind?: string
  student: {
    id: number
    first_name: string
    last_name: string
    picture?: string
    avatar?: string
  }
  mentor: {
    id: number
    first_name: string
    last_name: string
    picture?: string
    avatar?: string
  }
  training_course: {
    id: number
  }
}

export type GetCoachingResponse = {
  data: {
    data: Coaching[]
    total_items: number
    pagination: {
      current_page: string
      first_page: string
      last_page: string
      next_page?: unknown
      prev_page?: unknown
    }
  }
  status: number
}

export type CoachingPayload = {
  start: string
  end: string
  mentor_id?: number
  training_course_id: number
  context: CoachingContext
  student_id: number
  certification?: number
  requestOwnerId?: number
  coaching?: {
    id: number
    context: CoachingContext
    student: { id: number }
    mentor: { id?: string }
  }
}
