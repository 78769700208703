export default {
  getObjectiveById: (state) => (objectiveId) => {
    const { steps, notes, pendingNote, ...objective } = state[objectiveId]
    return objective
  },
  getObjectiveForProject: (_state, getters, rootState) => (projectId) => {
    const project = rootState.projects[projectId]
    if (!project || !project.objective) {
      return null
    }
    return getters.getObjectiveById(project.objective)
  },
}
