import { createWebSocketURL } from '@rails/actioncable'
import { defineNuxtPlugin } from '@/types/nuxt-helpers'
import ActionCablePlugin from '@/services/action-cable'
import { LMMessage } from '@/models/Message'
import { LMConversation } from '@/models/LMConversation'

export enum Channel {
  ACTIONS = 'ActionsChannel',
  COACHINGS = 'CoachingsChannel',
  MESSAGES = 'MessagesChannel',
  CONVERSATIONS = 'ConversationsChannel',
}

export type WebsocketResponse<T> = {
  verb: string
  object: T
  type: string
}

export default defineNuxtPlugin(({ $cookies, $config, store }, inject) => {
  function getWebSocketUrl(): string {
    const websocketUrl = createWebSocketURL($config.API_URL + '/cable')

    const token = $cookies.get('auth._token.local')

    return `${websocketUrl}?token=${token || ''}`
  }

  const cablePlugin = new ActionCablePlugin(getWebSocketUrl())

  function subscribeToChannels() {
    const userIsMentor = store.getters['users/currentUserIsMentor']
    const hasFeatureFlag = store.getters.hasAccessToCommunity

    const mentorChannels = {
      [Channel.ACTIONS]: {
        received: () => store.dispatch('actions/fetchActions'),
      },
    }

    const communityChannels = {
      [Channel.MESSAGES]: {
        received: (message: LMMessage) => {
          const userId = store.getters['auth/user']?.id
          const isCurrentLoggedUser = message.author?.user_id === userId

          store.commit('chat/messages/createOrUpdateMessage', message)
          store.commit('chat/conversations/reorderConversation', message.conversation_id)

          if (!isCurrentLoggedUser) {
            store.commit('chat/conversations/setAsRead', {
              conversationId: message.conversation_id,
              read: false,
            })
          }
        },
      },
      [Channel.CONVERSATIONS]: {
        received: (response: WebsocketResponse<LMConversation>) => {
          if (response.verb === 'deleted' && response.type === 'conversation') {
            store.commit('chat/conversations/deleteConversation', response.object.id)

            return
          }

          store.commit('chat/conversations/createOrUpdateConversation', response.object)
          store.commit('chat/messages/insertMessages', response.object.messages)
        },
      },
    }

    const channels = {
      ...(userIsMentor ? mentorChannels : {}),
      ...(hasFeatureFlag ? communityChannels : {}),
    }

    Object.entries(channels).forEach(([channel, mixin]) => {
      cablePlugin.subscribeTo(channel as Channel, mixin)
    })
  }

  subscribeToChannels()

  store.watch(
    (_, getters: any) => getters['auth/loggedIn'],
    (loggedIn: boolean) => {
      if (loggedIn) {
        cablePlugin.reconnect(getWebSocketUrl())
        subscribeToChannels()
      } else {
        cablePlugin.consumer.disconnect()
      }
    },
  )

  inject('cable', cablePlugin)
})
