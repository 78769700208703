import Vue from 'vue'
import { DELETE_DRAFT, SET_DRAFTS } from '@/constants/mutations-type'

export default {
  [SET_DRAFTS]: (state, drafts) => {
    for (const draft of drafts) {
      Vue.set(state, draft.id, draft)
    }
  },
  [DELETE_DRAFT]: (state, conversationId) => {
    Vue.delete(state, conversationId)
  },
}
