import { SET_COURSES, SET_MODULES, SET_LESSONS } from '@/constants/mutations-type'

export default {
  async GET_MASTERCLASSES({ commit }) {
    const masterclasses = await this.$api.masterclasses.get()
    commit(SET_COURSES, masterclasses)
  },
  async GET_MODULES_BY_MASTERCLASS_ID({ commit }, id) {
    const modules = await this.$api.masterclasses.getModulesByMasterClassId(id)
    commit(SET_MODULES, { modules, id })
  },
  async GET_LESSONS_BY_MASTERCLASS_ID({ commit }, id) {
    const lessons = await this.$api.masterclasses.getLessonsByMasterClassId(id)
    commit(SET_LESSONS, { lessons, id })
  },
  async GET_BONUS_BY_MASTERCLASS_ID({ commit }, id) {
    const bonuses = await this.$api.masterclasses.getBonusByMasterClassId(id)
    commit('SET_BONUSES', { bonuses, id })
  },
  async GET_LESSON_BY_ID({ commit }, { id, masterclassId }) {
    const _lesson = await this.$api.masterclasses.getLessonById(id)
    const { data: feedback } = await this.$api.feedback.get(id, 'course')
    const lesson = {
      ..._lesson,
      feedback: feedback.data[0] || null,
    }

    commit('UPDATE_LESSON', { lesson, masterclassId })
  },
  async GET_BONUS_BY_ID({ commit }, { id, masterclassId }) {
    const { data } = await this.$api.masterclasses.getBonusById(id)
    commit('UPDATE_BONUS', { bonus: data.data, masterclassId })
  },
  async GET_LESSON_VIEWS({ commit }, { ids, masterclassId }) {
    const { data } = await this.$api.masterclasses.getLessonViews(ids)
    commit('SET_LESSON_VIEWS', { lessonViews: data.data, masterclassId })
  },
  SET_LESSON_PAGE_IS_LOADING({ commit }, value) {
    commit('SET_LESSON_PAGE_IS_LOADING', value)
  },
  async SET_LESSON_VIEWS({ commit, getters }, { id, masterclassId }) {
    await this.$api.masterclasses.setLessonView(id)
    const lesson = getters.getCurrentLesson(id, masterclassId)
    const newLesson = {
      ...lesson,
      viewed_by_user: true,
    }
    commit('UPDATE_LESSON', { lesson: newLesson, masterclassId })
  },
  async SEND_FEEDBACK({ commit }, { feedback, masterclassId }) {
    const { data } = await this.$api.feedback.send(feedback)
    commit('UPDATE_FEEDBACK', { feedback: data.data, masterclassId })
  },
  async GET_MILESTONES_BY_MASTERCLASS_ID({ commit }, id) {
    const milestones = await this.$api.masterclasses.getMilestonesByMasterClassId(id)
    commit('SET_MILESTONES', { milestones, id })
  },
  async GET_MILESTONE_BY_ID({ commit }, { id, masterclassId }) {
    const milestone = await this.$api.masterclasses.getMilestoneById(id)
    commit('UPDATE_MILESTONE', { milestone, masterclassId })
  },
}
