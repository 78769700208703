import { socialNetWorkEntries } from '../social-network-entries'
import { NavigationCommunityItem } from '@/types/navigation-items'

export const communityEntries: NavigationCommunityItem[][] = [
  [
    {
      available: ['current_student', 'reader', 'past_student', 'future_student'],
      labelKey: 'forum',
      icon: 'chat',
      url: 'https://forum.livementor.com',
      external: true,
      tracking: 'Forum',
    },
    {
      available: ['current_student', 'reader', 'past_student', 'future_student'],
      labelKey: 'community',
      icon: 'user-group',
      url: 'https://www.facebook.com/groups/634406606734752/',
      external: true,
      tracking: 'Facebook group',
    },
  ],
  socialNetWorkEntries,
]
