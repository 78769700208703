import { ADD_ACTIONS, REMOVE_ACTION, SET_ACTIONS_TOTAL } from '@/constants/mutations-type'

export default {
  [ADD_ACTIONS]: (state, actions) => {
    state.actions = Object.fromEntries(actions.map((action) => [action.id, action]))
  },
  [REMOVE_ACTION]: (state, actionId) => {
    const { [actionId]: removedAction, ...actions } = state.actions
    state.actions = actions
  },
  [SET_ACTIONS_TOTAL]: (state, total) => {
    state.totalCount = total
  },
}
