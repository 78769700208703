import {
  ADD_ABSENCE_FUTURE,
  DELETE_ABSENCE_FUTURE,
  SET_ABSENCES_LOADING,
  SET_ABSENCES_PAST,
  SET_ABSENCES_PRESENT,
  SET_ABSENCES_FUTURE,
} from '@/constants/mutations-type'

export default {
  [SET_ABSENCES_PAST]: (state, pastAbsences) => {
    state.past = pastAbsences
  },

  [SET_ABSENCES_PRESENT]: (state, presentAbsences) => {
    state.present = presentAbsences
  },

  [SET_ABSENCES_FUTURE]: (state, futureAbsences) => {
    state.future = futureAbsences
  },

  [DELETE_ABSENCE_FUTURE]: (state, absence) => {
    const i = state.future.findIndex((abs) => abs.id === absence.id)
    if (i !== -1) {
      state.future.splice(i, 1)
    }
  },

  [ADD_ABSENCE_FUTURE]: (state, absence) => {
    state.future.push(absence)
  },

  [SET_ABSENCES_LOADING]: (state, isLoading) => {
    state.isLoading = isLoading
  },
}
