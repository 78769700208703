import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '..'

export type WebinarBookmarkState = {}

export const state = (): WebinarBookmarkState => ({})

export const getters: GetterTree<WebinarBookmarkState, RootState> = {}

export const mutations: MutationTree<WebinarBookmarkState> = {}

export const actions: ActionTree<WebinarBookmarkState, RootState> = {
  async createBookmark({ dispatch }, webinarId) {
    // @ts-ignore
    await this.$api.webinar.createBookmark(webinarId)
    await dispatch('webinar/fetchWebinar', webinarId, { root: true })
  },
  async deleteBookmark({ dispatch }, { webinarId, bookmarkId }) {
    // @ts-ignore
    await this.$api.webinar.deleteBookmark(bookmarkId)
    await dispatch('webinar/fetchWebinar', webinarId, { root: true })
  },
}
