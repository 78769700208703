export default {
  getStepById: (state) => (stepId) => state[stepId],
  getStepsForObjective: (_state, getters, rootState) => (objectiveId) => {
    const { steps } = rootState.objectives[objectiveId]
    if (steps && steps.length) {
      return steps.map((stepId) => getters.getStepById(stepId))
    }
    return []
  },
  getStepsWithTasksForObjective: (_state, getters, _rootState, rootGetters) => (objectiveId) => {
    const steps = getters.getStepsForObjective(objectiveId)
    if (steps.length) {
      return steps.map((step) => ({
        ...step,
        tasks: rootGetters['tasks/getTasksForStep'](step.id),
      }))
    }
    return []
  },
}
