export const CREATE_COACHING = 'coachings/CREATE_COACHING'
export const DISPLAY_MENU = 'DISPLAY_MENU'
export const DISPLAY_USER_DETAILS = 'DISPLAY_USER_DETAILS'
export const FETCH_COACHINGS = 'coachings/FETCH_COACHINGS'
export const GET_CERTIFICATIONS = 'certifications/GET_CERTIFICATIONS'
export const GET_TRAINING_COURSES = 'GET_TRAINING_COURSES'
export const RESCHEDULE_COACHING = 'coachings/RESCHEDULE_COACHING'
export const SELECT_CONVERSATION = 'SELECT_CONVERSATION'
export const SET_GLOBAL_MODAL = 'SET_GLOBAL_MODAL'
export const SET_MESSAGE = 'messages/SET_MESSAGE'
export const SET_USERS = 'users/SET_USERS'
export const RESET_SHOULD_LOGOUT = 'RESET_SHOULD_LOGOUT'
export const SELECT_COACHING = 'SELECT_COACHING'
export const CONFIRM_COACHING_AS_NO_SHOW = 'CONFIRM_COACHING_AS_NO_SHOW'
export const GET_SIGNABLE_DOCUMENTS = 'GET_SIGNABLE_DOCUMENTS'
export const GET_MASTERCLASSES = 'masterclasses/GET_MASTERCLASSES'
export const SET_LESSON_PAGE_IS_LOADING = 'masterclasses/SET_LESSON_PAGE_IS_LOADING'
export const GET_ABSENCES = 'absences/GET_ABSENCES'
export const CREATE_ABSENCE = 'absences/CREATE_ABSENCE'
export const DELETE_ABSENCE = 'absences/DELETE_ABSENCE'
export const SET_FREE_COURSE_lAYOUT_TITLE = 'SET_FREE_COURSE_lAYOUT_TITLE'
