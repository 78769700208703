export enum DocumentKind {
  ABSENCE_PROOF = 'absence_proof',
  COMPLETION_CERTIFICATE = 'completion_certificate',
  LEGAL_CERTIFICATION = 'legal_certification',
  WEBINAR_AGREEMENT = 'webinar_agreement',
  TRAINING_DIPLOMA = 'training_diploma',
  ATTENDANCE_CERTIFICATE = 'attendance_certificate',
}

export enum DocumentStatus {
  WAITING = 'waiting',
  SIGNED = 'signed',
}

export interface Document {
  id: number
  kind: DocumentKind
  file_url: string
  hellosign_signature_id: number
  limit_date: string
  status: DocumentStatus
  sign_url: string
  training_course_id: number
}
