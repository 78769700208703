import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'
import pick from 'lodash/pick'
import { CoachingContext, CoachingStatus } from '@/models/Coaching'

function findCoachingByContextAndStatus({ coachings, context, status }) {
  return coachings.find(
    (coaching) => coaching.context === context && status.includes(coaching.status),
  )
}

export default {
  getCoachingsByDateForUser: (state, __, rootState) => (date, userId) => {
    if (!userId) {
      return false
    }

    const coachings = Object.values(state).filter((coaching) => {
      const hasMentorIdAdStudentIds = coaching.mentor?.id && coaching.student?.id
      if (!hasMentorIdAdStudentIds) {
        return false
      }

      const isStudentOfCoaching = coaching.student.id.toString() === userId.toString()
      const isMentorOfCoaching = coaching.mentor.id.toString() === userId.toString()

      const isSameDate = dayjs(coaching.start_date).isSame(dayjs(date), 'date')

      return hasMentorIdAdStudentIds && (isMentorOfCoaching || isStudentOfCoaching) && isSameDate
    })

    const clonedCoachings = cloneDeep(coachings)

    clonedCoachings.forEach((coaching) => {
      if (rootState.users[coaching.student.id]) {
        const picked = pick(rootState.users[coaching.student.id], ['name', 'avatar'])
        coaching.student = { ...coaching.student, ...picked }
      }
    })
    return clonedCoachings
  },
  getCoachingById: (state) => (coachingId) => {
    return state[coachingId]
  },
  getCoachingsByStudentId: (state) => (studentId) => {
    return Object.values(state).filter(
      (coaching) => coaching.student?.id?.toString() === studentId.toString(),
    )
  },
  getCoachingsByTrainingCourseId: (state) => (trainingCourseId) => {
    return Object.values(state).filter(
      (coaching) => coaching.training_course?.id?.toString() === trainingCourseId?.toString(),
    )
  },
  getKickoffCoachingByTrainingCourseId: (__, getters) => (trainingCourseId) => {
    const trainingCourseCoachings = getters.getCoachingsByTrainingCourseId(trainingCourseId)

    return findCoachingByContextAndStatus({
      coachings: trainingCourseCoachings,
      context: CoachingContext.KICKOFF,
      status: [CoachingStatus.COMPLETED, CoachingStatus.CONFIRMED],
    })
  },
  getCertifCoachingByTrainingCourseId: (__, getters) => (trainingCourseId) => {
    const trainingCourseCoachings = getters.getCoachingsByTrainingCourseId(trainingCourseId)

    return findCoachingByContextAndStatus({
      coachings: trainingCourseCoachings,
      context: CoachingContext.CERTIFICATION_EXAM,
      status: [CoachingStatus.COMPLETED, CoachingStatus.CONFIRMED],
    })
  },
}
