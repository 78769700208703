interface FilterNumberOptions extends Intl.NumberFormatOptions {
  locale: string
}

const validRequiredAttributes = (number: number | string, options: FilterNumberOptions) => {
  if (typeof number === 'undefined') {
    throw new TypeError('[FilterNumber] The `number` attribute is required.')
  }
  if (Number.isNaN(number)) {
    throw new TypeError('[FilterNumber] The `number` attribute must be a valid number.')
  }
  if (typeof options.locale === 'undefined') {
    throw new TypeError('[FilterNumber] The `options.locale` attribute is required.')
  }
  if (typeof options.locale !== 'string') {
    throw new TypeError('[FilterNumber] The `options.locale` attribute must be a string.')
  }
}

export const number =
  (defaultLocale: string) =>
  (number: string | number, options?: Partial<FilterNumberOptions>): string => {
    const DEFAULT_OPTIONS: FilterNumberOptions = {
      locale: defaultLocale,
      minimumFractionDigits: 0,
    }

    const opts = {
      ...DEFAULT_OPTIONS,
      ...options,
    }

    validRequiredAttributes(number, opts)

    try {
      return new Intl.NumberFormat(opts.locale, opts).format(Number(number))
    } catch (error) {
      throw new Error(`[FilterNumber] ${error}`)
    }
  }
