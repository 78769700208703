import {
  MERGE_ASSESSMENTS,
  MERGE_CERTIFICATION_ASSESSMENTS,
  DELETE_ASSESSMENT_FILE,
} from '@/constants/mutations-type'

export default {
  async fetchAssessments({ commit }, certificationId) {
    const assessments = await this.$api.assessment.get({
      certification_id: certificationId,
      with_exercises_only: true,
    })

    commit(MERGE_ASSESSMENTS, assessments)
    commit(
      `certifications/${MERGE_CERTIFICATION_ASSESSMENTS}`,
      { certificationId, assessments },
      { root: true },
    )
  },
  async deleteFile({ commit }, { assessmentId, fileId }) {
    commit(DELETE_ASSESSMENT_FILE, { assessmentId, fileId })
    await this.$api.assessment.delete({
      assessmentId,
      fileId,
    })
  },
}
