export default {
  backActionRoute: (state) => {
    return state.backActionRoute
  },
  currentScreenName: (state) => {
    return state.currentScreenName
  },
  isDetailView: (state) => {
    return state.currentScreenView.includes('detailView')
  },
  isMasterView: (state) => {
    return state.currentScreenView.includes('masterView')
  },
  isDisplayMenu: (state) => {
    return state.displayMenu
  },
  isDisplayUserDetails: (state) => {
    return state.displayUserDetails
  },
  isMobile: (state) => {
    return state.isMobile
  },
  getGlobalModal: (state) => {
    return state.globalModal
  },
  hasAccessToCommunity: (state) => {
    return (
      state.auth.user?.feature_flags?.includes('community') &&
      state.auth.user?.student?.status?.includes('community')
    )
  },
  userHasFeature: (state) => (flag) => {
    return state.auth.user?.feature_flags?.includes(flag) || false
  },
  freeCourseLayoutTitle: (state) => {
    return state.freeCourseLayoutTitle
  },
}
