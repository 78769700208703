































import { Component, namespace, Prop, Vue } from 'nuxt-property-decorator'
import { MkrNavItem, MkrNavItemGroup } from '@livementor/mikado_reborn/src/index-core'
import { User } from '@/models/User'

const usersModule = namespace('users')

interface Entry {
  labelKey?: string
  label?: string
  icon: string
  url?: string
  external: boolean
  tracking: string
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace)
}

@Component({
  components: {
    MkrNavItem,
    MkrNavItemGroup,
  },
})
export default class NavigationItemsList extends Vue {
  @Prop({ type: Array, default: [] }) entries!: Entry[][]

  @usersModule.Getter('getCurrentUser') currentUser?: User

  trackClick(entry: any) {
    if (!entry.tracking) {
      return
    }
    this.$segment.track(`Clicked ${entry.tracking}`, {
      tracking_version: '1.0',
      path: this.$route.path,
    })
  }

  replaceLinkVariables(link?: string) {
    if (!link || !this.currentUser?.email) {
      return undefined
    }

    const replacements: { [key: string]: string } = {
      email: this.currentUser.email,
      name: this.currentUser.given_name,
    }

    for (const [key, value] of Object.entries(replacements)) {
      link = replaceAll(link, '${' + key + '}', encodeURI(value))
    }

    return link
  }
}
