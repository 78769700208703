import { MERGE_CERTIFICATIONS } from '@/constants/mutations-type'

export default {
  async GET_CERTIFICATIONS({ commit }, userId) {
    try {
      const certifications = await this.$api.certifications.get(userId)
      commit(MERGE_CERTIFICATIONS, certifications)
      commit('users/MERGE_CERTIFICATIONS', certifications, { root: true })
      return certifications
    } catch (e) {
      throw new Error(`[store/certifications](GET_CERTIFICATIONS) ${e}`)
    }
  },
}
