import { defineNuxtMiddleware } from '@/types/nuxt-helpers'
import { unauthenticatedRoutes } from '@/utils/unauthenticated-routes'
import { User } from '@/models/User'

const NOT_ALLOWED_REDIRECT_PATH = ['/', '/logout']
const ALLOWED_STUDENT_STATUS_FOR_CHAT = ['current_student', 'past_student']

export const getRouteForLoggedInUser = ({ user }: { user: User | null }) => {
  const userIsMentor = user?.role === 'mentor'
  const studentHasChatAccess = user?.student?.status?.some((status: string) =>
    ALLOWED_STUDENT_STATUS_FOR_CHAT.includes(status),
  )

  return userIsMentor || studentHasChatAccess ? '/chat' : '/courses'
}

export default defineNuxtMiddleware(async ({ redirect, route, $auth, store, $cookies }) => {
  try {
    if (route.query && route.query.auth_jwt) {
      await $auth.setUserToken(route.query.auth_jwt as string)
      route.query = { auth_jwt: '' }
      redirect(route.path)
    }

    const isUnauthenticatedRoute = route.name && unauthenticatedRoutes.includes(route.name)
    const isIndexRoute = route.name === 'index'
    const user = store.getters['auth/user']

    if ($auth.isLoggedIn() && (isUnauthenticatedRoute || isIndexRoute)) {
      const redirectPath = $cookies.get('redirect-path')
      if (redirectPath) {
        $cookies.remove('redirect-path')
        redirect(redirectPath)
        return
      }

      const routeToRedirect = getRouteForLoggedInUser({ user })
      redirect(routeToRedirect)
    } else if (!$auth.isLoggedIn() && !isUnauthenticatedRoute) {
      const addRedirectPath = !NOT_ALLOWED_REDIRECT_PATH.includes(route.path)
      $cookies.set('redirect-path', addRedirectPath ? route.path : '')

      redirect('/login')
    }
  } catch (error) {
    throw new Error(`[Middleware](auth) ${error}`)
  }
})
