import { ProfilePreview } from './Community'
import { LMMessage, PostMessage } from './Message'

export enum MembershipStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  ARCHIVED = 'archived',
  BLOCKED = 'blocked',
  KICK = 'kicked',
  LEFT = 'left',
  DENIED = 'denied',
}

export enum LMConversationKind {
  PEER = 'peer',
  PARTY = 'party',
}

export enum LMConversationRole {
  ADMIN = 'admin',
  MEMBER = 'member',
}

export interface ConversationUser {
  id: number
  given_name: string
  family_name: string
  avatar: string | null
  profile_displayed: boolean
}

export enum ReportReason {
  NUDITY = 'nudity',
  HATE = 'hate',
  FRAUD = 'fraud',
  VIOLENCE = 'violence',
  ILLEGAL_PRODUCTS = 'illegal_products',
  INTIMIDATION = 'intimidation',
  IDENTITY_THEFT = 'identity_theft',
  INTELLECTUAL_PROPERTY = 'intellectual_property',
  SUICIDE = 'suicide',
  SPAM = 'spam',
  ILLEGAL = 'illegal',
  OTHER = 'other',
}

export enum ReportMessageReasons {
  PROMOTE = 'promote',
  SPAM = 'spam',
  OTHER = 'other',
  FRAUD = 'fraud',
  VIOLENCE = 'violence',
}

export interface PostBlocking {
  user_id: number
  conversation_id: number
  reason: ReportReason
  comment?: string
}

export interface PostReport {
  report: {
    reportable_id: number
    reportable_type: 'message'
    conversation_id: number
    reason: ReportReason
    comment?: string
  }
}

export interface PostAddMembers {
  chat_membership: {
    conversation_id: number
    member_ids: number[]
  }
}

export interface UserMembership {
  id: number
  status: MembershipStatus
  role: LMConversationRole
  unread: Date | boolean | null
  user: ConversationUser
  nb_members_left_to_add_today?: number
  suspended: boolean
  invite_sender?: {
    id: number
    full_name: string
  }
  conversation_last_activity_at: Date
}

export interface RecipientMembership {
  id: number
  user: ConversationUser
  role: LMConversationRole
  status: MembershipStatus
  blocking_id: number
  suspended: boolean
  unread: Date
}

export interface LMConversation {
  avatar: string | null
  id: number | null
  kind: LMConversationKind
  name: string
  user_ids: number[]
  other_memberships?: Array<RecipientMembership> | null
  created_at?: number
  startDate?: number
  messages?: LMMessage[]
  user_membership: UserMembership
  peer_profile?: ProfilePreview
  isArchived?: boolean
  isBlocked?: boolean
  hasLeft?: boolean
  allMessagesAreLoaded?: boolean
}

export interface PutConversation {
  avatar?: File | null
  title?: string
}

export interface CreateConversationPayload {
  chat_conversation: {
    title?: string
    member_ids: number[]
    first_message?: PostMessage
  }
}

export interface UpdateMembership {
  status?: 'active' | 'denied' | 'left' | 'kicked' | 'archived' | 'blocked'
  role?: LMConversationRole
  read?: boolean
}

export interface IndexConversationResponse {
  conversations: LMConversation[]
  user: {
    nb_conversations_left_to_start_peer: number
    nb_conversations_left_to_start_party: number
    suspended: boolean
    profile_displayed: boolean
  }
}
