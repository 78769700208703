export default {
  getSelectedCoachingId: (state) => {
    return state.selectedCoaching
  },
  getSelectedCoaching: (__, getters, rootState) => {
    return getters.getSelectedCoachingId
      ? rootState.coachings[getters.getSelectedCoachingId]
      : undefined
  },
}
