import {
  DELETE_OBJECTIVE_STEP,
  DELETE_STEP,
  MERGE_OBJECTIVE_STEP,
  MERGE_STEP,
  SET_OBJECTIVE_STEPS,
  SET_STEPS,
} from '@/constants/mutations-type'

export default {
  async fetchSteps({ commit }, objectiveId) {
    const {
      data: { data: steps },
    } = await this.$api.steps.get(objectiveId)
    commit(SET_STEPS, steps)
    commit(
      `objectives/${SET_OBJECTIVE_STEPS}`,
      {
        steps,
        objectiveId,
      },
      { root: true },
    )
  },
  async createStep({ commit }, { objectiveId, step }) {
    const {
      data: { data: createdStep },
    } = await this.$api.steps.create(objectiveId, {
      title: step.title,
    })
    commit(MERGE_STEP, createdStep)
    commit(
      `objectives/${MERGE_OBJECTIVE_STEP}`,
      {
        objectiveId,
        step: createdStep,
      },
      { root: true },
    )
  },
  async updateStep({ commit, getters }, step) {
    const currentStep = getters.getStepById(step.id)
    commit(MERGE_STEP, step)
    try {
      const {
        data: { data: updatedStep },
      } = await this.$api.steps.update(step)
      commit(MERGE_STEP, updatedStep)
    } catch (error) {
      commit(MERGE_STEP, currentStep)
      throw error
    }
  },
  async deleteStep({ commit, getters }, stepId) {
    const step = getters.getStepById(stepId)
    await this.$api.steps.delete(stepId)
    commit(DELETE_STEP, stepId)
    commit(
      `objectives/${DELETE_OBJECTIVE_STEP}`,
      {
        objectiveId: step.objective_id,
        stepId,
      },
      { root: true },
    )
  },
  async checkStep({ commit }, stepId) {
    const {
      data: { data: step },
    } = await this.$api.steps.check(stepId)
    commit(MERGE_STEP, step)
  },
}
