





























































import { Component, Vue, namespace, Prop } from 'nuxt-property-decorator'
import {
  MkrAvatar,
  MkrIcon,
  MkrNavItem,
  MkrNavItemGroup,
  MkrNotificationBadge,
} from '@livementor/mikado_reborn/src/index-core'

import FlyOutMenu from '@/components/navigation/common/FlyOutMenu.vue'
import HelpButton from '@/components/navigation/common/HelpButton.vue'
import { User } from '@/models/User'
import { Conversation } from '@/models/Conversation'

import {
  NavigationMainItem,
  NavigationProfileItem,
  NavigationCommunityItem,
} from '@/types/navigation-items'
import { LMConversation } from '@/models/LMConversation'

import NewTag from '@/components/global/NewTag.vue'

const usersModule = namespace('users')
const conversationsModule = namespace('conversations')

@Component({
  components: {
    NewTag,
    FlyOutMenu,
    MkrAvatar,
    MkrIcon,
    MkrNavItem,
    MkrNavItemGroup,
    MkrNotificationBadge,
    HelpButton,
  },
})
export default class DesktopNavigation extends Vue {
  @Prop({ default: Array }) mainEntries!: NavigationMainItem[]

  @Prop({ default: Array }) profileEntries!: NavigationProfileItem[][]

  @Prop({ default: Array }) communityEntries!: NavigationCommunityItem[][]

  @usersModule.Getter('currentUserIsMentor')
  currentUserIsMentor!: boolean

  @usersModule.Getter('getCurrentUser')
  currentUser?: User

  @conversationsModule.Getter('getUnreadConversations')
  unreadConversations!: Conversation[]

  get userPicture() {
    if (!this.currentUser) {
      return null
    }
    return (
      this.currentUser.avatar ||
      'https://hellomentor.s3-eu-west-1.amazonaws.com/users/avatars/john-doe.jpg'
    )
  }

  get hasUnreadConversations() {
    const hasUnreadFirebaseConversations = this.unreadConversations.length > 0
    const hasCommunityFeature = this.$store.getters.hasAccessToCommunity

    if (hasCommunityFeature) {
      const conversations: LMConversation[] = this.$store.state.chat.conversations.conversations
      const unreadConversations = conversations.some((c) => c.user_membership.unread)
      return unreadConversations || hasUnreadFirebaseConversations
    }

    return hasUnreadFirebaseConversations
  }
}
