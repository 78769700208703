import firebase from 'firebase/compat/app'
import { FirebaseCollection, FirebaseDb, FirebaseConfig } from '../types'
import { User } from '@/models/User'
import { CreateCoachingInput } from '@/plugins/firebase/FirebasePlugin'
import { Conversation } from '@/models/Conversation'

export class ConversationsCollection {
  private readonly conversationsCollection: FirebaseCollection

  constructor(private readonly db: FirebaseDb, config: FirebaseConfig) {
    this.conversationsCollection = this.db.collection(`${config.rootCollection}conversations`)
  }

  get instance(): FirebaseCollection {
    return this.conversationsCollection
  }

  listenForConversationUpdate(conversationId: string) {
    try {
      return this.conversationsCollection.doc(conversationId)
    } catch (error) {
      throw new Error(`[ConversationCollection](listenForConversationUpdate) ${error}`)
    }
  }

  blockConversation(userId: string, conversationId: string) {
    try {
      return this.conversationsCollection.doc(conversationId).update({
        displayTo: firebase.firestore.FieldValue.arrayRemove(userId),
      })
    } catch (error) {
      throw new Error(`[ConversationCollection](blockConversation) ${error}`)
    }
  }

  async fetchConversationById(conversationId: string) {
    try {
      const snapshot = await this.conversationsCollection.doc(conversationId).get()

      return snapshot?.data() as Conversation | undefined
    } catch (error) {
      throw new Error(`[ConversationCollection](fetchConversation) ${error}`)
    }
  }

  fetchConversations(user: User) {
    try {
      return this.conversationsCollection
        .where('participants', 'array-contains', user.id?.toString())
        .get()
    } catch (error) {
      throw new Error(`[ConversationCollection](fetchConversations) ${error}`)
    }
  }

  async getConversation(userId: string, otherParticipant: string) {
    try {
      const query = await this.conversationsCollection
        .where('participants', 'in', [
          [userId, otherParticipant],
          [otherParticipant, userId],
        ])
        .limit(1)
        .get()

      const snapshot = query.docs[0]

      return snapshot?.data() as Conversation | undefined
    } catch (error) {
      throw new Error(`[ConversationCollection](getConversation) ${error}`)
    }
  }

  getConversations(userId: string, otherParticipant: string) {
    try {
      return this.conversationsCollection
        .where('participants', 'in', [
          [userId, otherParticipant],
          [otherParticipant, userId],
        ])
        .get()
    } catch (error) {
      throw new Error(`[ConversationCollection](getConversations) ${error}`)
    }
  }

  createProposal(conversationId: string, coachingProposal: CreateCoachingInput['proposal']) {
    try {
      return this.conversationsCollection.doc(conversationId).update({
        coachingProposal,
      })
    } catch (error) {
      throw new Error(`[ConversationCollection](createProposal) ${error}`)
    }
  }

  async deleteProposal(conversationId: string) {
    try {
      return await this.conversationsCollection.doc(conversationId).update({
        coachingProposal: null,
      })
    } catch (error) {
      throw new Error(`[ConversationCollection](deleteProposal) ${error}`)
    }
  }
}
