import {
  DELETE_BOOKMARK,
  SET_BOOKMARKS,
  MERGE_MESSAGES,
  UPDATE_MESSAGE,
} from '@/constants/mutations-type'

export default {
  fetchBookmarks: ({ commit }, { messages, bookmarks }) => {
    commit(`messages/${MERGE_MESSAGES}`, { messages }, { root: true })
    commit(SET_BOOKMARKS, bookmarks)
  },

  addBookmark: ({ commit }, { userId, conversationId, message }) => {
    commit(SET_BOOKMARKS, [
      {
        id: message.id,
        conversationId,
        messageCreatedAt: message.created_at,
      },
    ])

    const newMessage = {
      id: message.id,
      savedBy: [...(message.savedBy || []), userId],
    }

    commit(`messages/${UPDATE_MESSAGE}`, newMessage, { root: true })
  },

  removeBookmark: ({ commit, rootGetters }, { messageId, userId }) => {
    commit(DELETE_BOOKMARK, messageId)

    const message = rootGetters['messages/getMessageById'](messageId)
    const savedBy = message.savedBy?.filter((savedUserId) => savedUserId !== userId)

    commit(
      `messages/${UPDATE_MESSAGE}`,
      {
        id: messageId,
        savedBy,
      },
      { root: true },
    )
  },
}
