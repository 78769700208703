import firebase from 'firebase/compat/app'
import { FirebaseCollection, FirebaseDb, FirebaseConfig } from '../types'
import { UserFromFirebase } from '@/models/User'

export class UsersCollection {
  readonly usersCollection: FirebaseCollection

  constructor(private readonly db: FirebaseDb, config: FirebaseConfig) {
    this.usersCollection = this.db.collection(`${config.rootCollection}lmusers`)
  }

  get instance(): FirebaseCollection {
    return this.usersCollection
  }

  listenForUserUpdate(userId: string) {
    try {
      return this.usersCollection.doc(userId)
    } catch (error) {
      throw new Error(`[UsersCollection](listenForUserUpdate) ${error}`)
    }
  }

  async fetchUser(userId: string) {
    try {
      const doc = await this.usersCollection.doc(userId).get()
      if (!doc.exists) {
        throw String('no user stored')
      }

      return doc.data() as UserFromFirebase
    } catch (error) {
      throw new Error(`[UsersCollection](fetchUser) ${error}`)
    }
  }

  async fetchUsers(usersIds: string[]) {
    try {
      const getUsersPromise = []
      const batchSize = 10

      for (let i = 0; i < usersIds.length + batchSize; i += batchSize) {
        const userIdsBatch = usersIds.slice(i, i + batchSize)

        if (userIdsBatch.length) {
          const user = await this.usersCollection.where('id', 'in', userIdsBatch).get()
          getUsersPromise.push(user)
        }
      }

      const docs = await Promise.all(getUsersPromise)

      const usersData = docs.flatMap((user) => user.docs)

      return usersData.map((userDoc) => userDoc.data() as UserFromFirebase)
    } catch (error) {
      throw new Error(`[UsersCollection](fetchUsers) ${error}`)
    }
  }

  markConversationAsUnreadOrRead({
    type,
    userId,
    conversationId,
  }: {
    type: 'read' | 'unread'
    conversationId: string
    userId: string
  }) {
    try {
      const unreadConversations =
        type === 'read'
          ? firebase.firestore.FieldValue.arrayRemove(conversationId)
          : firebase.firestore.FieldValue.arrayUnion(conversationId)
      return this.usersCollection.doc(userId).update({
        unreadConversations,
      })
    } catch (error) {
      throw new Error(`[UsersCollection](markConversationAsUnreadOrRead) ${error}`)
    }
  }
}
