import {
  ADD_NOTES,
  ADD_OBJECTIVE_NOTE,
  ADD_PENDING_NOTE,
  DELETE_NOTE,
  MERGE_NOTE,
  REMOVE_NOTE,
  REMOVE_PENDING_NOTE,
  SET_OBJECTIVE_NOTES,
} from '@/constants/mutations-type'

export default {
  async fetchNotes({ commit }, objectiveId) {
    const {
      data: { data: notes },
    } = await this.$api.notes.get(objectiveId)
    commit(ADD_NOTES, notes)
    commit(
      `objectives/${SET_OBJECTIVE_NOTES}`,
      {
        notes,
        objectiveId,
      },
      { root: true },
    )
  },
  async createNote({ commit }, { objectiveId, note }) {
    commit(
      `objectives/${ADD_PENDING_NOTE}`,
      {
        note,
        objectiveId,
      },
      { root: true },
    )
    try {
      const {
        data: { data: createdStep },
      } = await this.$api.notes.create(objectiveId, {
        content: note.content,
      })
      commit(MERGE_NOTE, createdStep)
      commit(
        `objectives/${ADD_OBJECTIVE_NOTE}`,
        {
          noteId: createdStep.id,
          objectiveId,
        },
        { root: true },
      )
    } finally {
      commit(`objectives/${REMOVE_PENDING_NOTE}`, objectiveId, { root: true })
    }
  },
  async updateNote({ commit, getters }, note) {
    const currentNote = getters.getNoteById(note.id)
    commit(MERGE_NOTE, note)
    try {
      const {
        data: { data: updatedNote },
      } = await this.$api.notes.update(note)
      commit(MERGE_NOTE, updatedNote)
    } catch (error) {
      commit(MERGE_NOTE, currentNote)
      throw error
    }
  },
  async deleteNote({ commit, getters }, noteId) {
    const note = getters.getNoteById(noteId)
    await this.$api.notes.delete(noteId)
    commit(DELETE_NOTE, noteId)
    commit(
      `objectives/${REMOVE_NOTE}`,
      {
        objectiveId: note.objective_id,
        noteId,
      },
      { root: true },
    )
  },
}
