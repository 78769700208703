
















import { Component, Vue, Prop } from 'nuxt-property-decorator'
import {
  MkrCard,
  MkrIcon,
  MkrPopUp,
  MkrNavItem,
  MkrNavItemGroup,
  MkrTooltip,
} from '@livementor/mikado_reborn/src/index-core'

import NavigationItemsList from '@/components/navigation/common/NavigationItemsList.vue'

@Component({
  components: {
    MkrCard,
    MkrIcon,
    MkrPopUp,
    MkrNavItem,
    MkrNavItemGroup,
    MkrTooltip,
    NavigationItemsList,
  },
})
export default class FlyOutMenu extends Vue {
  @Prop({ type: Array, default: [] }) entries!: unknown[]

  @Prop({ type: String, default: '' }) label!: string

  opened = false
}
