


















import { MkrSnackbar } from '@livementor/mikado_reborn/src/index-core'
import { Component, Vue } from 'nuxt-property-decorator'
import { events, INotifyOptions, INotifyProps } from './index'

const defaultOptions = {
  duration: 5000,
}

const defaultProps = {
  closable: true,
}

export interface INotification {
  props: INotifyProps
  timeout: null | any
  id: string
  options: INotifyOptions
}

@Component({
  components: {
    MkrSnackbar,
  },
})
export default class NotificationsModule extends Vue {
  notifications: INotification[] = []

  beforeMount() {
    events.$on('notify', this.handleNotify)
  }

  handleNotify({ props, options }: { props: INotifyProps; options: INotifyOptions }) {
    const notification: INotification = {
      id: Math.random().toString(36).slice(4),
      options: { ...defaultOptions, ...options },
      props: { ...defaultProps, ...props },
      timeout: null,
    }

    this.notifications.push(notification)

    if (notification.options.duration > 0) {
      notification.timeout = setTimeout(() => {
        this.close(notification.id)
      }, notification.options.duration)
    }
  }

  close(notificationId: string) {
    const notification = this.notifications.find((n) => n.id === notificationId)

    if (notification?.timeout) {
      clearTimeout(notification.timeout)
    }

    this.notifications = this.notifications.filter((s) => s.id !== notificationId)
  }
}
