import type { NuxtAxiosInstance } from '@nuxtjs/axios'

export class LmMonolith {
  private readonly monolith: NuxtAxiosInstance

  constructor(readonly axiosInstance: NuxtAxiosInstance) {
    this.monolith = axiosInstance
  }

  get instance() {
    return this.monolith
  }

  generateDiploma(certificationId: number) {
    return this.monolith.get(`/api/certifications/${certificationId}/generate_diploma`)
  }

  generateCertification(certificationId: number) {
    return this.monolith.get(`/api/certifications/${certificationId}/generate_certification`)
  }
}
