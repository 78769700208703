import Vue from 'vue'
import { ADD_NOTES, DELETE_NOTE, MERGE_NOTE } from '@/constants/mutations-type'

export default {
  [ADD_NOTES]: (state, notes) => {
    notes.forEach((note) => {
      Vue.set(state, note.id, note)
    })
  },
  [DELETE_NOTE]: (state, noteId) => {
    Vue.delete(state, noteId)
  },
  [MERGE_NOTE]: (state, note) => {
    Vue.set(state, note.id, { ...state[note.id], ...note })
  },
}
