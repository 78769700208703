import Vue from 'vue'
import { MERGE_COACHINGS } from '@/constants/mutations-type'
import { CoachingContext } from '@/models/Coaching'

export default {
  [MERGE_COACHINGS]: (state, { coachings }) => {
    coachings.forEach((coaching) => {
      if (
        [
          CoachingContext.KICKOFF,
          CoachingContext.CERTIFICATION_EXAM,
          CoachingContext.REGULAR,
        ].includes(coaching.context)
      ) {
        Vue.set(state, coaching.id, {
          ...state[coaching.id],
          ...coaching,
        })
      }
    })
  },
  UPDATE_COACHING: (state, coaching) => {
    Vue.set(state, coaching.id, { ...state[coaching.id], ...coaching })
  },
}
