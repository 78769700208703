import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { defineNuxtPlugin } from '@/types/nuxt-helpers'

export interface INotifyProps {
  message: string | TranslateResult
  error?: boolean
  success?: boolean
  neutral?: boolean
  closable?: boolean
}

export interface INotifyOptions {
  duration: number
}

export type VNotify = (props: INotifyProps, options?: INotifyOptions) => void

export const events = new Vue({ name: 'Notifications' })

export default defineNuxtPlugin((_context, inject) => {
  inject('notify', (props: INotifyProps, options: INotifyOptions) =>
    events.$emit('notify', { props, options }),
  )
})
