import { GetterTree } from 'vuex/types/index'
import { SyllabusRootState } from './state'
import { Syllabus, SyllabusModule } from '@/models/Syllabus'

export type SyllabusGetters = {
  getSyllabusByMasterclassId(masterclassId: number): Syllabus | undefined
  getModuleFromSyllabusById(payload: {
    masterclassId: number
    moduleId: number
  }): SyllabusModule | undefined
}

const getters: GetterTree<SyllabusRootState, SyllabusRootState> = {
  getSyllabusByMasterclassId: (state) => (masterclassId: number) => {
    const syllabus = state.find(({ masterclass }) => masterclass.id === masterclassId)
    return syllabus
  },
  getModuleFromSyllabusById:
    (state) =>
    ({ masterclassId, moduleId }: { masterclassId: number; moduleId: number }) => {
      const syllabus = state.find(({ masterclass }) => masterclass.id === masterclassId)
      return syllabus?.modules.find(({ id }) => moduleId === id)
    },
}

export default getters
