import Vue from 'vue'
import { MERGE_SIGNABLE_DOCUMENTS } from '@/constants/mutations-type'

export default {
  [MERGE_SIGNABLE_DOCUMENTS]: (state, payload) => {
    const signableDocuments = payload.signable_documents.filter((document) => {
      return document !== undefined
    })
    signableDocuments.forEach((x) => Vue.set(state, x.id, x))
  },
}
