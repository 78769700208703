import { ActionTree, GetterTree, MutationTree } from 'vuex/types/index'
import { RootState } from '..'
import { LMMessage, PostMessage, PutMessage } from '@/models/Message'

export type MessageState = {
  messages: LMMessage[]
}

export const state = (): MessageState => ({
  messages: [],
})

export const mutations: MutationTree<MessageState> = {
  insertMessages(state, messages) {
    // unique by id
    messages = messages.filter((message: LMMessage) => {
      const index = state.messages.findIndex((x) => x.id === message.id)
      return !(index >= 0)
    })

    state.messages = [...state.messages, ...messages]
  },
  createOrUpdateMessage(state, message) {
    const index = state.messages.findIndex((x) => x.id === message.id)
    if (index >= 0) {
      state.messages.splice(index, 1, message)
    } else {
      state.messages.push(message)
    }
  },
}

export const getters: GetterTree<MessageState, RootState> = {
  getMessages: (state) => (conversationId: number) => {
    return state.messages
      .filter((x) => x.conversation_id === conversationId)
      .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
  },
  bookmarks: (state) => state.messages.filter((x) => x.bookmark_id),
}

export const actions: ActionTree<MessageState, RootState> = {
  async loadMore({ commit }, { conversationId, message_offset_id }) {
    const messages = await this.$api.messages.fetch({ conversationId, message_offset_id })

    const pagination = messages.data.pagination
    commit('insertMessages', messages.data.data)

    if (pagination.end_of_input) {
      commit('chat/conversations/setEndOfMessages', conversationId, { root: true })
    }
  },
  async sendMessage(_, payload: PostMessage) {
    await this.$api.messages.send(payload)
  },
  async editMessage(_, payload: PutMessage) {
    await this.$api.messages.edit(payload)
  },
  async fetchBookmarks({ commit }) {
    const messages = await this.$api.messages.getBookmarks()
    commit('insertMessages', messages)
  },
  async createBookmark({ commit }, message: LMMessage) {
    const bookmark = await this.$api.messages.createBookmark(message.id)

    commit('createOrUpdateMessage', {
      ...message,
      bookmark_id: bookmark.data.id,
    })
  },
  async deleteBookmark({ commit }, message: LMMessage) {
    if (!message.bookmark_id) {
      return
    }
    await this.$api.messages.deleteBookmark(message.bookmark_id)
    commit('createOrUpdateMessage', {
      ...message,
      bookmark_id: null,
    })
  },
}
