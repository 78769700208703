import {
  DELETE_STEP_TASK,
  DELETE_TASK,
  MERGE_STEP_TASK,
  MERGE_TASK,
  RECOMPUTE_TASKS_POSTIONS,
  SET_STEP_TASKS,
  SET_TASKS,
} from '@/constants/mutations-type'

export default {
  async fetchTasks({ commit }, objectiveId) {
    const {
      data: { data: tasks },
    } = await this.$api.tasks.get(objectiveId)
    commit(SET_TASKS, tasks)
    Object.entries(
      tasks.reduce((tasksByStep, task) => {
        const currentTasks = tasksByStep[task.step_id] || []

        return {
          ...tasksByStep,
          [task.step_id]: [...currentTasks, task],
        }
      }, {}),
    ).forEach(([stepId, tasks]) => {
      commit(
        `steps/${SET_STEP_TASKS}`,
        {
          stepId,
          tasks,
        },
        { root: true },
      )
    })
  },
  async createTask({ commit }, { stepId, task }) {
    const { title, content, resources } = task
    const {
      data: { data: createdTask },
    } = await this.$api.tasks.create(stepId, {
      title,
      content,
      resources,
    })
    commit(MERGE_TASK, createdTask)
    commit(
      `steps/${MERGE_STEP_TASK}`,
      {
        stepId,
        task: createdTask,
      },
      { root: true },
    )
  },
  async updateTask({ commit, state, rootState }, task) {
    const currentTask = { ...state[task.id] }
    commit(MERGE_TASK, task)
    commit(
      `steps/${MERGE_STEP_TASK}`,
      {
        stepId: task.step_id,
        task,
      },
      { root: true },
    )
    try {
      const {
        data: { data: updatedTask },
      } = await this.$api.tasks.update(task)
      commit(MERGE_TASK, updatedTask)
      commit(
        `steps/${MERGE_STEP_TASK}`,
        {
          stepId: task.step_id,
          task: updatedTask,
        },
        { root: true },
      )
      if (currentTask.position !== updatedTask.position) {
        commit(RECOMPUTE_TASKS_POSTIONS, rootState.steps[task.step_id].tasks)
      }
    } catch (error) {
      commit(MERGE_TASK, currentTask)
      commit(
        `steps/${MERGE_STEP_TASK}`,
        {
          stepId: task.step_id,
          task: currentTask,
        },
        { root: true },
      )
      throw error
    }
  },
  async deleteTask({ commit, getters }, taskId) {
    const task = getters.getTaskById(taskId)
    await this.$api.tasks.delete(taskId)
    commit(DELETE_TASK, taskId)
    commit(
      `steps/${DELETE_STEP_TASK}`,
      {
        stepId: task.step_id,
        taskId,
      },
      { root: true },
    )
  },
  async checkTask({ commit }, taskId) {
    const {
      data: { data: checkedStep },
    } = await this.$api.tasks.check(taskId)
    commit(MERGE_TASK, checkedStep)
  },
  async uncheckTask({ commit }, taskId) {
    const {
      data: { data: uncheckedStep },
    } = await this.$api.tasks.uncheck(taskId)
    commit(MERGE_TASK, uncheckedStep)
  },
}
