import { ADD_ACTIONS, REMOVE_ACTION, SET_ACTIONS_TOTAL } from '@/constants/mutations-type'
import { ActionKind } from '@/models/Action'

export default {
  async fetchActions({ commit, state }) {
    try {
      const {
        data: { data: actions, total_items },
      } = await this.$api.actions.get()

      const validatedActions = actions.filter((action) =>
        Object.values(ActionKind).includes(action.kind),
      )

      const newActions = Object.fromEntries(validatedActions.map((action) => [action.id, action]))
      const newIds = Object.keys(newActions)
      const existingIds = Object.keys(state.actions)
      const filteredArray = newIds.filter((value) => !existingIds.includes(value))

      if (
        (validatedActions.length && filteredArray.length) ||
        newIds.length !== existingIds.length
      ) {
        commit(ADD_ACTIONS, validatedActions)
        commit(SET_ACTIONS_TOTAL, total_items)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`[store/actions](fetchActions) ${error}`)
    }
  },
  async cancelAction({ commit, state }, actionId) {
    await this.$api.actions.cancel(actionId)
    commit(REMOVE_ACTION, actionId)
    commit(SET_ACTIONS_TOTAL, state.totalCount - 1)
  },
}
