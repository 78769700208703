import { defineNuxtPlugin } from '@/types/nuxt-helpers'
import { User } from '@/models/User'
import { SET_GLOBAL_MODAL } from '@/constants/actions-type'
import { GlobalMeetingSalesModal, ModalTypes } from '@/constants/ModalTypes'

const MEETING_SALES_MODAL_ACTIVE_QUERY = 'meeting_sales_modal'
const MEETING_SALES_MODAL_UTM_QUERY = 'utm'
const DEFAULT_MEETING_SALES_MODAL_CONTEXT = 'url-query'

declare global {
  interface Window {
    stonlyTrack: (action: string, ...args: any[]) => void
    STONLY_WID: string
  }
}

export default defineNuxtPlugin(({ app, route, error: nuxtError, store }) => {
  app.router?.beforeEach((to, _from, next) => {
    const authUser = store.state.auth.user as User | null

    const routeRoles = [...new Set(route.meta.flatMap((m: any) => m.role))].filter(Boolean)

    if (routeRoles.length && !routeRoles.includes(authUser?.role)) {
      nuxtError({ statusCode: 404 })
    }

    const routesWithFeatureFlags: Record<string, string>[] = []
    routesWithFeatureFlags.forEach((route) => {
      if (to?.name?.includes(route.name)) {
        if (!store.getters.userHasFeature(route.featureFlag)) {
          nuxtError({ statusCode: 404 })
        }
      }
    })
    next()
  })

  app.router?.afterEach((to, _from) => {
    const authUser = store.state.auth.user as User | null
    const is_suspended = store.state.chat.conversations.isSuspended

    if (authUser && process.client) {
      window.stonlyTrack('init', window.STONLY_WID)
      const params: Record<string, string | undefined | boolean> = {
        user_role: authUser.role,
        has_profile: authUser.student?.has_community_profile || false,
        has_community_feature_flag: store.getters.hasAccessToCommunity,
        is_suspended,
      }

      if (authUser.role === 'student') {
        const studentStatus = authUser.student?.status
        params.student_status = 'courses'
        if (studentStatus?.includes('current_student')) {
          params.student_status = 'current'
        } else if (studentStatus?.includes('future_student')) {
          params.student_status = 'future'
        } else if (studentStatus?.includes('past_student')) {
          params.student_status = 'past'
        }
      }

      window.stonlyTrack('identify', authUser.id?.toString(), params)
    }

    const shouldOpenMeetingSalesModal =
      to.query[MEETING_SALES_MODAL_ACTIVE_QUERY] &&
      to.query[MEETING_SALES_MODAL_ACTIVE_QUERY] === 'true'

    const context = (to.query[MEETING_SALES_MODAL_UTM_QUERY] ??
      DEFAULT_MEETING_SALES_MODAL_CONTEXT) as string

    if (shouldOpenMeetingSalesModal) {
      const payloadMeetingSalesModal: GlobalMeetingSalesModal = {
        type: ModalTypes.MEETING_SALES_MODAL,
        payload: {
          context,
        },
      }
      store.dispatch(SET_GLOBAL_MODAL, payloadMeetingSalesModal)
    }
  })
})
