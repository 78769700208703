import Vue from 'vue'
import VueSanitize from 'vue-sanitize'
import { IOptions as SanitizeHtmlOptions } from 'sanitize-html'

export const SANITIZE_DEFAULT_CONFIG: SanitizeHtmlOptions = {
  allowedTags: [
    'a',
    'abbr',
    'acronym',
    'address',
    'b',
    'big',
    'blockquote',
    'br',
    'cite',
    'code',
    'dd',
    'del',
    'dfn',
    'div',
    'dl',
    'dt',
    'em',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hr',
    'i',
    'img',
    'ins',
    'kbd',
    'li',
    'ol',
    'p',
    'pre',
    'samp',
    'small',
    'span',
    'strong',
    'sub',
    'sup',
    'tt',
    'u',
    'ul',
    'var',
    'figure',
  ],
  allowedAttributes: {
    '*': [
      'abbr',
      'alt',
      'cite',
      'class',
      'datetime',
      'height',
      'href',
      'target',
      'name',
      'src',
      'title',
      'width',
      'xml:lang',
    ],
  },
}

Vue.use(VueSanitize, SANITIZE_DEFAULT_CONFIG)
