import startCase from 'lodash/startCase'
import { defineNuxtMiddleware } from '@/types/nuxt-helpers'

export default defineNuxtMiddleware(({ route, $segment, store }) => {
  try {
    const user = store.getters['auth/user']
    if (!(user?.id && $segment)) {
      return
    }

    const props = {
      user_id: user.id.toString(),
      tracking_version: '1.0',
    }

    const routeName = route.name || ''

    const pageName = startCase(
      routeName
        .replace('/-/g', ' ')
        .replace('/id/g', '')
        .replace('/page/g', route.params.page)
        .trim(),
    )

    if (routeName?.includes('courses') && route.query.lesson) {
      $segment.page(pageName, {
        ...props,
        lesson_id: route.query.lesson,
        course_id: route.params.id,
      })
    } else {
      $segment.page(pageName, props)
    }
  } catch (error) {
    throw new Error(`[analytics] ${error}`)
  }
})
