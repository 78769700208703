export default {
  draftsMessages: (state) => {
    return Object.fromEntries(Object.entries(state).map((draft) => [draft[0], draft[1].message]))
  },
  drafts: (state) => {
    return Object.values(state)
      .sort((a, b) => b.updated_at - a.updated_at)
      .map((draft) => draft)
  },
  draftsCount: (state) => {
    return Object.keys(state).length
  },
}
