


























import { Component, Vue, Prop, Getter, Watch, namespace } from 'nuxt-property-decorator'
import Navigation from '@/components/navigation/index.vue'

const usersModule = namespace('users')

@Component({
  name: 'AppLayout',
  components: {
    Navigation,
  },
  fetchOnServer: false,
})
export default class AppLayout extends Vue {
  @Prop({ type: String, default: undefined }) pageName?: string

  @Getter('isDetailView') isDetailView!: boolean

  @Getter('isMasterView') isMasterView!: boolean

  @usersModule.Getter('currentUserIsMentor')
  currentUserIsMentor!: boolean

  @Watch('pageName', { immediate: true })
  onPageNameChange(newPageName: string) {
    this.$store.dispatch('SET_CURRENT_SCREEN_NAME', newPageName)
  }

  @Watch('$mq', { immediate: true })
  breakpointChanged(newBreakpoint: string) {
    this.$store.dispatch('SET_IS_MOBILE', ['sm', 'md'].includes(newBreakpoint))
  }

  get hasLeftPanel() {
    return !!this.$slots['left-panel']
  }
}
