
















































import { Component, mixins } from 'nuxt-property-decorator'
import { MkrNavItem, MkrNavItemGroup } from '@livementor/mikado_reborn/src/index-core'

import NavigationManager from '@/components/navigation/common/NavigationManager.vue'

import LogoMentor from '@/assets/img/logo/logo-mentor.svg'

import {
  mainEntries,
  profileEntries,
  subEntries,
  communityEntries,
} from '@/constants/mentor-entries'

import Responsive from '@/mixins/Responsive'

@Component({
  components: {
    LogoMentor,
    MkrNavItem,
    MkrNavItemGroup,
    NavigationManager,
  },
})
export default class MentorNavigation extends mixins(Responsive) {
  profileEntries = profileEntries

  subEntries = subEntries

  communityEntries = communityEntries

  get hasAccessToEdition(): boolean {
    return this.$store.getters['users/getCurrentUser'].mentor.edition
  }

  get mainEntries() {
    if (this.isMobile) {
      return [
        ...mainEntries,
        ...subEntries,
        ...(this.hasAccessToEdition
          ? [
              {
                labelKey: 'hot-seat',
                icon: 'video-camera-circle',
                url: '/edition',
              },
            ]
          : []),
      ]
    }
    return mainEntries
  }
}
