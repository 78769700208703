import { NavigationProfileItem } from '@/types/navigation-items'

export const profileEntries: NavigationProfileItem[][] = [
  [
    {
      labelKey: 'profile',
      icon: 'user',
      url: '/account/profile',
    },
    {
      labelKey: 'settings',
      icon: 'cog',
      url: '/account/settings',
    },
    {
      labelKey: 'absence',
      icon: 'sun',
      url: '/account/absence',
    },
  ],
  [
    {
      labelKey: 'logout',
      url: '/logout',
    },
  ],
]
