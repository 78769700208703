import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _22e78ca7 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _bc58f456 = () => interopDefault(import('../pages/account/absence.vue' /* webpackChunkName: "pages/account/absence" */))
const _2fa2c0d0 = () => interopDefault(import('../pages/account/documents.vue' /* webpackChunkName: "pages/account/documents" */))
const _8dd78c50 = () => interopDefault(import('../pages/account/journey.vue' /* webpackChunkName: "pages/account/journey" */))
const _d958034a = () => interopDefault(import('../pages/account/journey/index.vue' /* webpackChunkName: "pages/account/journey/index" */))
const _4fcf2224 = () => interopDefault(import('../pages/account/journey/exercises.vue' /* webpackChunkName: "pages/account/journey/exercises" */))
const _8506117e = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _7ae080b1 = () => interopDefault(import('../pages/account/project.vue' /* webpackChunkName: "pages/account/project" */))
const _68ae448a = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _768c570c = () => interopDefault(import('../pages/activation.vue' /* webpackChunkName: "pages/activation" */))
const _7a3cc63b = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _2de1d264 = () => interopDefault(import('../pages/chat.vue' /* webpackChunkName: "pages/chat" */))
const _0ff6cad1 = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _120f9a39 = () => interopDefault(import('../pages/chat/archived-conversations/index.vue' /* webpackChunkName: "pages/chat/archived-conversations/index" */))
const _5fc98afc = () => interopDefault(import('../pages/chat/bookmarks.vue' /* webpackChunkName: "pages/chat/bookmarks" */))
const _c18e40dc = () => interopDefault(import('../pages/chat/conversations/index.vue' /* webpackChunkName: "pages/chat/conversations/index" */))
const _74a00903 = () => interopDefault(import('../pages/chat/drafts.vue' /* webpackChunkName: "pages/chat/drafts" */))
const _31a3e22a = () => interopDefault(import('../pages/chat/notifications/index.vue' /* webpackChunkName: "pages/chat/notifications/index" */))
const _69916a8a = () => interopDefault(import('../pages/chat/templates/index.vue' /* webpackChunkName: "pages/chat/templates/index" */))
const _3ab3a9c7 = () => interopDefault(import('../pages/chat/peer-conversations/new.vue' /* webpackChunkName: "pages/chat/peer-conversations/new" */))
const _06f81e39 = () => interopDefault(import('../pages/chat/templates/new/_messageId.vue' /* webpackChunkName: "pages/chat/templates/new/_messageId" */))
const _ef84c30c = () => interopDefault(import('../pages/chat/conversations/_id.vue' /* webpackChunkName: "pages/chat/conversations/_id" */))
const _a38f31dc = () => interopDefault(import('../pages/chat/notifications/_id.vue' /* webpackChunkName: "pages/chat/notifications/_id" */))
const _26fdd4e1 = () => interopDefault(import('../pages/chat/peer-conversations/_id.vue' /* webpackChunkName: "pages/chat/peer-conversations/_id" */))
const _c002803a = () => interopDefault(import('../pages/chat/templates/_id.vue' /* webpackChunkName: "pages/chat/templates/_id" */))
const _e37295a4 = () => interopDefault(import('../pages/chat/unread-conversations/_id.vue' /* webpackChunkName: "pages/chat/unread-conversations/_id" */))
const _757a97b4 = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "pages/community/index" */))
const _1b650629 = () => interopDefault(import('../pages/community/index/index.vue' /* webpackChunkName: "pages/community/index/index" */))
const _ad8bdd1a = () => interopDefault(import('../pages/community/index/map.vue' /* webpackChunkName: "pages/community/index/map" */))
const _0c1321b5 = () => interopDefault(import('../pages/courses/index.vue' /* webpackChunkName: "pages/courses/index" */))
const _5048510e = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _c620b78a = () => interopDefault(import('../pages/edition/index.vue' /* webpackChunkName: "pages/edition/index" */))
const _74692463 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _65cc7080 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _1418bc5e = () => interopDefault(import('../pages/recover.vue' /* webpackChunkName: "pages/recover" */))
const _4f48f784 = () => interopDefault(import('../pages/resetPassword.vue' /* webpackChunkName: "pages/resetPassword" */))
const _70db9118 = () => interopDefault(import('../pages/student-directory/index.vue' /* webpackChunkName: "pages/student-directory/index" */))
const _f8ff8b6a = () => interopDefault(import('../pages/token-expired.vue' /* webpackChunkName: "pages/token-expired" */))
const _dd532632 = () => interopDefault(import('../pages/webinar/index.vue' /* webpackChunkName: "pages/webinar/index" */))
const _ad3d2bac = () => interopDefault(import('../pages/webinar/index/index.vue' /* webpackChunkName: "pages/webinar/index/index" */))
const _62c66d0e = () => interopDefault(import('../pages/webinar/index/bookmark.vue' /* webpackChunkName: "pages/webinar/index/bookmark" */))
const _2e8ab480 = () => interopDefault(import('../pages/webinar/index/search.vue' /* webpackChunkName: "pages/webinar/index/search" */))
const _79be39cb = () => interopDefault(import('../pages/community/rules.vue' /* webpackChunkName: "pages/community/rules" */))
const _30dc6ea0 = () => interopDefault(import('../pages/courses/empty.vue' /* webpackChunkName: "pages/courses/empty" */))
const _73182f29 = () => interopDefault(import('../pages/courses/free/index.ts' /* webpackChunkName: "pages/courses/free/index" */))
const _8593fca2 = () => interopDefault(import('../pages/edition/hot-seat/index.vue' /* webpackChunkName: "pages/edition/hot-seat/index" */))
const _32da0662 = () => interopDefault(import('../pages/onboarding/waiting.vue' /* webpackChunkName: "pages/onboarding/waiting" */))
const _5ce5f40c = () => interopDefault(import('../pages/community/profile/edit.vue' /* webpackChunkName: "pages/community/profile/edit" */))
const _11f1ccb0 = () => interopDefault(import('../pages/community/profile/_id.vue' /* webpackChunkName: "pages/community/profile/_id" */))
const _2b5d0cbb = () => interopDefault(import('../pages/courses/free/_masterclassId/index.vue' /* webpackChunkName: "pages/courses/free/_masterclassId/index" */))
const _0c66a3d7 = () => interopDefault(import('../pages/edition/hot-seat/_id.vue' /* webpackChunkName: "pages/edition/hot-seat/_id" */))
const _3e05d332 = () => interopDefault(import('../pages/courses/free/_masterclassId/modules/index.ts' /* webpackChunkName: "pages/courses/free/_masterclassId/modules/index" */))
const _11fa345b = () => interopDefault(import('../pages/courses/free/_masterclassId/modules/_moduleId/index.ts' /* webpackChunkName: "pages/courses/free/_masterclassId/modules/_moduleId/index" */))
const _7a2cadaf = () => interopDefault(import('../pages/courses/free/_masterclassId/modules/_moduleId/lessons/index.ts' /* webpackChunkName: "pages/courses/free/_masterclassId/modules/_moduleId/lessons/index" */))
const _23c7e954 = () => interopDefault(import('../pages/courses/free/_masterclassId/modules/_moduleId/lessons/_lessonId.vue' /* webpackChunkName: "pages/courses/free/_masterclassId/modules/_moduleId/lessons/_lessonId" */))
const _79f80d5d = () => interopDefault(import('../pages/courses/_id.vue' /* webpackChunkName: "pages/courses/_id" */))
const _1c6a5a20 = () => interopDefault(import('../pages/courses/_id/index.vue' /* webpackChunkName: "pages/courses/_id/index" */))
const _731865f4 = () => interopDefault(import('../pages/courses/_id/404.vue' /* webpackChunkName: "pages/courses/_id/404" */))
const _2d3c3c08 = () => interopDefault(import('../pages/courses/_id/certification.vue' /* webpackChunkName: "pages/courses/_id/certification" */))
const _25a93ddd = () => interopDefault(import('../pages/courses/_id/lesson/index.vue' /* webpackChunkName: "pages/courses/_id/lesson/index" */))
const _10a8fe7e = () => interopDefault(import('../pages/courses/_id/milestone/index.vue' /* webpackChunkName: "pages/courses/_id/milestone/index" */))
const _2ac12188 = () => interopDefault(import('../pages/courses/_id/lesson/_lesson_id.vue' /* webpackChunkName: "pages/courses/_id/lesson/_lesson_id" */))
const _0a019998 = () => interopDefault(import('../pages/courses/_id/milestone/_milestone_id.vue' /* webpackChunkName: "pages/courses/_id/milestone/_milestone_id" */))
const _2eff7963 = () => interopDefault(import('../pages/edition/_id.vue' /* webpackChunkName: "pages/edition/_id" */))
const _0e9629a6 = () => interopDefault(import('../pages/edition/_id/index.vue' /* webpackChunkName: "pages/edition/_id/index" */))
const _2411fa8e = () => interopDefault(import('../pages/edition/_id/planning.vue' /* webpackChunkName: "pages/edition/_id/planning" */))
const _1ef20b80 = () => interopDefault(import('../pages/edition/_id/replays.vue' /* webpackChunkName: "pages/edition/_id/replays" */))
const _2a578748 = () => interopDefault(import('../pages/follow-up/_id.vue' /* webpackChunkName: "pages/follow-up/_id" */))
const _64b89bf0 = () => interopDefault(import('../pages/onboarding/_page.vue' /* webpackChunkName: "pages/onboarding/_page" */))
const _5502e7d1 = () => interopDefault(import('../pages/planning/_date.vue' /* webpackChunkName: "pages/planning/_date" */))
const _3ec1b1dd = () => interopDefault(import('../pages/signature/_id.vue' /* webpackChunkName: "pages/signature/_id" */))
const _65987d0f = () => interopDefault(import('../pages/webinar/_id.vue' /* webpackChunkName: "pages/webinar/_id" */))
const _aa7cfd68 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _22e78ca7,
    name: "account",
    children: [{
      path: "absence",
      component: _bc58f456,
      name: "account-absence"
    }, {
      path: "documents",
      component: _2fa2c0d0,
      name: "account-documents"
    }, {
      path: "journey",
      component: _8dd78c50,
      children: [{
        path: "",
        component: _d958034a,
        name: "account-journey"
      }, {
        path: "exercises",
        component: _4fcf2224,
        name: "account-journey-exercises"
      }]
    }, {
      path: "profile",
      component: _8506117e,
      name: "account-profile"
    }, {
      path: "project",
      component: _7ae080b1,
      name: "account-project"
    }, {
      path: "settings",
      component: _68ae448a,
      name: "account-settings"
    }]
  }, {
    path: "/activation",
    component: _768c570c,
    name: "activation"
  }, {
    path: "/callback",
    component: _7a3cc63b,
    name: "callback"
  }, {
    path: "/chat",
    component: _2de1d264,
    children: [{
      path: "",
      component: _0ff6cad1,
      name: "chat"
    }, {
      path: "archived-conversations",
      component: _120f9a39,
      name: "chat-archived-conversations"
    }, {
      path: "bookmarks",
      component: _5fc98afc,
      name: "chat-bookmarks"
    }, {
      path: "conversations",
      component: _c18e40dc,
      name: "chat-conversations"
    }, {
      path: "drafts",
      component: _74a00903,
      name: "chat-drafts"
    }, {
      path: "notifications",
      component: _31a3e22a,
      name: "chat-notifications"
    }, {
      path: "templates",
      component: _69916a8a,
      name: "chat-templates"
    }, {
      path: "peer-conversations/new",
      component: _3ab3a9c7,
      name: "chat-peer-conversations-new"
    }, {
      path: "templates/new/:messageId?",
      component: _06f81e39,
      name: "chat-templates-new-messageId"
    }, {
      path: "conversations/:id",
      component: _ef84c30c,
      name: "chat-conversations-id"
    }, {
      path: "notifications/:id",
      component: _a38f31dc,
      name: "chat-notifications-id"
    }, {
      path: "peer-conversations/:id?",
      component: _26fdd4e1,
      name: "chat-peer-conversations-id"
    }, {
      path: "templates/:id",
      component: _c002803a,
      name: "chat-templates-id"
    }, {
      path: "unread-conversations/:id?",
      component: _e37295a4,
      name: "chat-unread-conversations-id"
    }]
  }, {
    path: "/community",
    component: _757a97b4,
    children: [{
      path: "",
      component: _1b650629,
      name: "community-index"
    }, {
      path: "map",
      component: _ad8bdd1a,
      name: "community-index-map"
    }]
  }, {
    path: "/courses",
    component: _0c1321b5,
    name: "courses"
  }, {
    path: "/dashboard",
    component: _5048510e,
    name: "dashboard"
  }, {
    path: "/edition",
    component: _c620b78a,
    name: "edition"
  }, {
    path: "/login",
    component: _74692463,
    name: "login"
  }, {
    path: "/logout",
    component: _65cc7080,
    name: "logout"
  }, {
    path: "/recover",
    component: _1418bc5e,
    name: "recover"
  }, {
    path: "/resetPassword",
    component: _4f48f784,
    name: "resetPassword"
  }, {
    path: "/student-directory",
    component: _70db9118,
    name: "student-directory"
  }, {
    path: "/token-expired",
    component: _f8ff8b6a,
    name: "token-expired"
  }, {
    path: "/webinar",
    component: _dd532632,
    children: [{
      path: "",
      component: _ad3d2bac,
      name: "webinar-index"
    }, {
      path: "bookmark",
      component: _62c66d0e,
      name: "webinar-index-bookmark"
    }, {
      path: "search",
      component: _2e8ab480,
      name: "webinar-index-search"
    }]
  }, {
    path: "/community/rules",
    component: _79be39cb,
    name: "community-rules"
  }, {
    path: "/courses/empty",
    component: _30dc6ea0,
    name: "courses-empty"
  }, {
    path: "/courses/free",
    component: _73182f29,
    name: "courses-free"
  }, {
    path: "/edition/hot-seat",
    component: _8593fca2,
    name: "edition-hot-seat"
  }, {
    path: "/onboarding/waiting",
    component: _32da0662,
    name: "onboarding-waiting"
  }, {
    path: "/community/profile/edit",
    component: _5ce5f40c,
    name: "community-profile-edit"
  }, {
    path: "/community/profile/:id?",
    component: _11f1ccb0,
    name: "community-profile-id"
  }, {
    path: "/courses/free/:masterclassId",
    component: _2b5d0cbb,
    name: "courses-free-masterclassId"
  }, {
    path: "/edition/hot-seat/:id?",
    component: _0c66a3d7,
    name: "edition-hot-seat-id"
  }, {
    path: "/courses/free/:masterclassId/modules",
    component: _3e05d332,
    name: "courses-free-masterclassId-modules"
  }, {
    path: "/courses/free/:masterclassId/modules/:moduleId",
    component: _11fa345b,
    name: "courses-free-masterclassId-modules-moduleId"
  }, {
    path: "/courses/free/:masterclassId/modules/:moduleId/lessons",
    component: _7a2cadaf,
    name: "courses-free-masterclassId-modules-moduleId-lessons"
  }, {
    path: "/courses/free/:masterclassId/modules/:moduleId/lessons/:lessonId",
    component: _23c7e954,
    name: "courses-free-masterclassId-modules-moduleId-lessons-lessonId"
  }, {
    path: "/courses/:id",
    component: _79f80d5d,
    children: [{
      path: "",
      component: _1c6a5a20,
      name: "courses-id"
    }, {
      path: "404",
      component: _731865f4,
      name: "courses-id-404"
    }, {
      path: "certification",
      component: _2d3c3c08,
      name: "courses-id-certification"
    }, {
      path: "lesson",
      component: _25a93ddd,
      name: "courses-id-lesson"
    }, {
      path: "milestone",
      component: _10a8fe7e,
      name: "courses-id-milestone"
    }, {
      path: "lesson/:lesson_id",
      component: _2ac12188,
      name: "courses-id-lesson-lesson_id"
    }, {
      path: "milestone/:milestone_id",
      component: _0a019998,
      name: "courses-id-milestone-milestone_id"
    }]
  }, {
    path: "/edition/:id",
    component: _2eff7963,
    children: [{
      path: "",
      component: _0e9629a6,
      name: "edition-id"
    }, {
      path: "planning",
      component: _2411fa8e,
      name: "edition-id-planning"
    }, {
      path: "replays",
      component: _1ef20b80,
      name: "edition-id-replays"
    }]
  }, {
    path: "/follow-up/:id?",
    component: _2a578748,
    name: "follow-up-id"
  }, {
    path: "/onboarding/:page?",
    component: _64b89bf0,
    name: "onboarding-page"
  }, {
    path: "/planning/:date?",
    component: _5502e7d1,
    name: "planning-date"
  }, {
    path: "/signature/:id?",
    component: _3ec1b1dd,
    name: "signature-id"
  }, {
    path: "/webinar/:id",
    component: _65987d0f,
    name: "webinar-id"
  }, {
    path: "/",
    component: _aa7cfd68,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
