import { FirebaseSubCollection } from '../types'
import { MessagesCollection } from './MessagesCollection'
import { UsersCollection } from './UsersCollection'
import { Message } from '@/models/Message'

export class BookmarksCollection {
  private readonly bookmarksCollection: FirebaseSubCollection

  constructor(
    private readonly usersCollection: UsersCollection,
    private readonly messageCollection: MessagesCollection,
  ) {
    this.bookmarksCollection = (userId) => {
      return this.usersCollection.instance.doc(userId).collection('drafts')
    }
  }

  async fetchBookmarkedMessages(userId: string) {
    try {
      const querySnapshot = await this.bookmarksCollection(userId)
        .orderBy('messageCreatedAt', 'desc')
        .get()
      const bookmarks: Record<string, any>[] = []
      const messages: Message[] = []

      const promises = querySnapshot.docs.map(async (doc) => {
        const { messageRef, ...bookmark } = doc.data()
        bookmarks.push(bookmark)
        const message = await messageRef.get()
        messages.push(message.data())
      })

      await Promise.all(promises)

      return {
        bookmarks,
        messages,
      }
    } catch (error) {
      throw new Error(`[BookMarksCollection](fetchBookmarkedMessages) ${error}`)
    }
  }

  async bookmarkMessage({
    conversationId,
    message,
    userId,
  }: {
    conversationId: string
    message: Message
    userId: string
  }) {
    try {
      if (!message || !message.id) {
        throw new Error(
          `BookmarkMessage - the text message is empty - UserId: ${userId} - CID: ${conversationId}`,
        )
      }
      return await this.bookmarksCollection(userId)
        .doc(message.id)
        .set({
          id: message.id,
          conversationId,
          messageRef: this.messageCollection.getConversationMessageById({
            conversationId,
            messageId: message.id,
          }),
          messageCreatedAt: message.created_at,
        })
    } catch (error) {
      throw new Error(`[BookMarksCollection](bookmarkMessage) ${error}`)
    }
  }

  async unbookmarkMessage({ messageId, userId }: { messageId: string; userId: string }) {
    try {
      return await this.bookmarksCollection(userId).doc(messageId).delete()
    } catch (error) {
      throw new Error(`[BookMarksCollection](unbookmarkMessage) ${error}`)
    }
  }
}
