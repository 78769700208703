export default {
  getNoteById: (state) => (noteId) => state[noteId],
  getNotesForObjective: (state, _getters, rootState) => (objectiveId) => {
    const objective = rootState.objectives[objectiveId]
    if (!objective || !objective.notes) {
      return []
    }
    return [
      ...objective.notes.map((noteId) => state[noteId]),
      ...(objective.pendingNote ? [objective.pendingNote] : []),
    ].reverse()
  },
}
