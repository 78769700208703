import { Component, Vue } from 'nuxt-property-decorator'
import { DISPLAY_MENU } from '@/constants/actions-type'

@Component
export default class Responsive extends Vue {
  get isMobile() {
    return this.$mq === 'sm' || this.$mq === 'md'
  }

  displayMenu() {
    if (this.isMobile) {
      this.$emit('close-sidepanel')
    }
    this.$store.dispatch(DISPLAY_MENU)
  }
}
