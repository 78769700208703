import { User, UserRole } from '@/models/User'
import { defineNuxtMiddleware } from '@/types/nuxt-helpers'
import { unauthenticatedRoutes } from '@/utils/unauthenticated-routes'

let previousRouteName: string | undefined | null

export default defineNuxtMiddleware(async ({ $auth, redirect, route, $api, store }) => {
  try {
    const user: User = store.getters['auth/user']
    const isSameRoute = previousRouteName === route.name
    const isUnauthenticatedRoute = route.name && unauthenticatedRoutes.includes(route.name)
    const isLogoutRoute = route.name === 'logout'
    const isLoggedIn = $auth.isLoggedIn()

    previousRouteName = route.name

    if (!isLoggedIn || isSameRoute || isUnauthenticatedRoute || isLogoutRoute) {
      return
    }

    const isStudent = user?.role === UserRole.STUDENT && user?.student

    if (!isStudent) {
      return
    }

    const onboarding = await $api.form.onboarding.get()

    // If the student has a training course or a manual to onboard, redirect to current onboarding page
    if (onboarding.required && !onboarding.EOF && onboarding.ref) {
      if (route.name !== 'onboarding-page') {
        redirect(`/onboarding/${onboarding.ref}`)
      }
      return
    }

    const student = user?.student

    // If the student is waiting for a mentor :
    //  and has access to the app, redirect to courses
    //  otherwise, redirect to waiting
    const authorizedStudentStatuses = ['current_student', 'reader', 'past_student']
    const studentHasAccess = student?.status.some((status: string) =>
      authorizedStudentStatuses.includes(status),
    )

    if (student?.training_course_id_waiting_for_mentor) {
      const routesWaitingForMentor = ['onboarding-waiting']
      if (!store.getters.hasAccessToCommunity) {
        routesWaitingForMentor.push('chat')
      }

      if (studentHasAccess && route.name && routesWaitingForMentor.includes(route.name)) {
        redirect('/courses')
      } else if (!studentHasAccess && route.name !== 'onboarding-waiting') {
        redirect('/onboarding/waiting')
      }
    } else if (route.name === 'onboarding-waiting') {
      // If the student is on waiting route, has access and has no training course waiting for mentor, redirect to root
      redirect('/')
    }
  } catch (err) {
    throw new Error(`[middleware/onboarding] ${err}`)
  }
})
