import Vue from 'vue'
import {
  DELETE_TASK,
  MERGE_TASK,
  RECOMPUTE_TASKS_POSTIONS,
  SET_TASKS,
} from '@/constants/mutations-type'

export default {
  [SET_TASKS]: (state, tasks) => {
    tasks.forEach((task) => {
      Vue.set(state, task.id, task)
    })
  },
  [DELETE_TASK]: (state, taskId) => {
    Vue.delete(state, taskId)
  },
  [MERGE_TASK]: (state, task) => {
    Vue.set(state, task.id, { ...state[task.id], ...task })
  },
  [RECOMPUTE_TASKS_POSTIONS]: (state, taskIds) => {
    taskIds.forEach((taskId, index) => {
      Vue.set(state, taskId, { ...state[taskId], position: index + 1 })
    })
  },
}
