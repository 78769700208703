import { createConsumer, Mixin, Consumer, Subscription } from '@rails/actioncable'
import { Channel } from '@/plugins/cable'

export default class ActionCablePlugin {
  constructor(url: string) {
    this.websocketUrl = url
    this.consumer = this.createCableConsumer()
  }

  public subscriptions: Array<{
    [key in Channel]?: Subscription
  }> = []

  public consumer: Consumer
  public websocketUrl = ''
  public createCableConsumer(): Consumer {
    return createConsumer(this.websocketUrl)
  }

  public reconnect(url?: string) {
    this.consumer.disconnect()
    this.websocketUrl = url || this.websocketUrl
    this.consumer = this.createCableConsumer()
  }

  public subscribeTo(channel: Channel, mixin: Mixin) {
    const subscription = this.consumer.subscriptions.create(channel, mixin)
    this.subscriptions.push({
      [channel]: subscription,
    })
  }

  public unsubscribeFrom(channel: Channel) {
    const subscription = this.subscriptions.find((subscription) => subscription[channel])
    if (subscription) {
      subscription[channel]?.unsubscribe()
    }
  }
}
