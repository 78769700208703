import dayjs from 'dayjs'

export default {
  reversedFuture: (state) => {
    const absences = [...state.future]
    absences.sort((a, b) => {
      if (dayjs(a.start_at).isBefore(dayjs(b.start_at), 'day')) {
        return -1
      } else {
        return 1
      }
    })

    return absences
  },
}
