export const ADD_ACTIONS = 'ADD_ACTIONS'
export const ADD_CONVERSATION = 'ADD_CONVERSATION'
export const ADD_NOTES = 'ADD_NOTES'
export const ADD_OBJECTIVE_NOTE = 'ADD_OBJECTIVE_NOTE'
export const ADD_PENDING_NOTE = 'ADD_PENDING_NOTE'
export const CREATE_PROPOSAL = 'CREATE_PROPOSAL'
export const DELETE_ASSESSMENT_FILE = 'DELETE_ASSESSMENT_FILE'
export const DELETE_DRAFT = 'DELETE_DRAFT'
export const DELETE_BOOKMARK = 'DELETE_BOOKMARK'
export const DELETE_NOTE = 'DELETE_NOTE'
export const DELETE_OBJECTIVE_STEP = 'DELETE_OBJECTIVE_STEP'
export const DELETE_PROPOSAL = 'DELETE_PROPOSAL'
export const DELETE_STEP = 'DELETE_STEP'
export const DELETE_STEP_TASK = 'DELETE_STEP_TASK'
export const DELETE_TASK = 'DELETE_TASK'
export const GLOBAL_MODAL = 'GLOBAL_MODAL'
export const SET_SHOULD_LOGOUT = 'SET_SHOULD_LOGOUT'
export const HIDE_CONVERSATION = 'HIDE_CONVERSATION'
export const LOCAL_ERROR = 'LOCAL_ERROR'
export const MARK_CONVERSATION_AS_READ = 'MARK_CONVERSATION_AS_READ'
export const MARK_CONVERSATION_AS_UNREAD = 'MARK_CONVERSATION_AS_UNREAD'
export const MERGE_ASSESSMENTS = 'MERGE_ASSESSMENTS'
export const MERGE_COACHINGS = 'MERGE_COACHINGS'
export const MERGE_CERTIFICATIONS = 'MERGE_CERTIFICATIONS'
export const MERGE_CERTIFICATION_ASSESSMENTS = 'MERGE_CERTIFICATION_ASSESSMENTS'
export const MERGE_CONVERSATIONS = 'MERGE_CONVERSATIONS'
export const MERGE_MESSAGES = 'MERGE_MESSAGES'
export const MERGE_NOTE = 'MERGE_NOTE'
export const MERGE_OBJECTIVE = 'MERGE_OBJECTIVE'
export const MERGE_OBJECTIVE_STEP = 'MERGE_OBJECTIVE_STEP'
export const MERGE_PROJECT = 'MERGE_PROJECT'
export const MERGE_PROJECT_OBJECTIVE = 'MERGE_PROJECT_OBJECTIVE'
export const MERGE_STEP = 'MERGE_STEP'
export const MERGE_STEP_TASK = 'MERGE_STEP_TASK'
export const MERGE_TASK = 'MERGE_TASK'
export const MERGE_TRAINING_COURSES = 'MERGE_TRAINING_COURSES'
export const MERGE_USERS = 'MERGE_USERS'
export const MERGE_TEMPLATES = 'MERGE_TEMPLATES'
export const MERGE_USER_PROJECT = 'MERGE_USER_PROJECT'
export const RECOMPUTE_TASKS_POSTIONS = 'RECOMPUTE_TASKS_POSTIONS'
export const REMOVE_ACTION = 'REMOVE_ACTION'
export const REMOVE_NOTE = 'REMOVE_NOTE'
export const REMOVE_PENDING_NOTE = 'REMOVE_PENDING_NOTE'
export const SET_ACTIONS_TOTAL = 'SET_ACTIONS_TOTAL'
export const SET_BACK_ACTION_ROUTE = 'SET_BACK_ACTION_ROUTE'
export const SET_DRAFTS = 'SET_DRAFTS'
export const SET_BOOKMARKS = 'SET_BOOKMARKS'
export const SET_CHATBOT = 'SET_CHATBOT'
export const SET_CONVERSATION_IS_LOADED = 'SET_CONVERSATION_IS_LOADED'
export const SET_DISPLAY_MENU = 'SET_DISPLAY_MENU'
export const SET_DISPLAY_USER_DETAILS = 'SET_DISPLAY_USER_DETAILS'
export const SET_IS_MOBILE = 'SET_IS_MOBILE'
export const SET_OBJECTIVE_STEPS = 'SET_OBJECTIVE_STEPS'
export const SET_OBJECTIVE_NOTES = 'SET_OBJECTIVE_NOTES'
export const SET_PROPOSAL_AVAILABILITY = 'SET_PROPOSAL_AVAILABILITY'
export const SET_SELECTED_CONVERSATION = 'SET_SELECTED_CONVERSATION'
export const SET_SELECTED_CONVERSATION_ID = 'SET_SELECTED_CONVERSATION_ID'
export const SET_TRAINING_COURSES = 'SET_TRAINING_COURSES'
export const SET_SELECTED_COACHING = 'SET_SELECTED_COACHING'
export const SET_STEPS = 'SET_SELECTED_COACHING'
export const SET_STEP_TASKS = 'SET_STEP_TASKS'
export const SET_TASKS = 'SET_TASKS'
export const SET_USER_WAITING_SIGNATURE_DOCUMENTS = 'SET_USER_WAITING_SIGNATURE_DOCUMENTS'
export const DISPLAY_MENU = 'DISPLAY_MENU'
export const MERGE_SIGNABLE_DOCUMENTS = 'MERGE_SIGNABLE_DOCUMENTS'
export const SET_COURSES = 'SET_COURSES'
export const SET_MODULES = 'SET_MODULES'
export const SET_LESSONS = 'SET_LESSONS'
export const SET_ABSENCES_LOADING = 'SET_ABSENCES_LOADING'
export const SET_ABSENCES_PAST = 'SET_ABSENCES_PAST'
export const SET_ABSENCES_PRESENT = 'SET_ABSENCES_PRESENT'
export const SET_ABSENCES_FUTURE = 'SET_ABSENCES_FUTURE'
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'
export const DELETE_ABSENCE_FUTURE = 'DELETE_ABSENCE_FUTURE'
export const ADD_ABSENCE_FUTURE = 'ADD_ABSENCE_FUTURE'
export const SET_FREE_COURSE_lAYOUT_TITLE = 'SET_FREE_COURSE_lAYOUT_TITLE'
