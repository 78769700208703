import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/actions.js'), 'actions.js')
  resolveStoreModules(require('../store/getters.js'), 'getters.js')
  resolveStoreModules(require('../store/mutations.js'), 'mutations.js')
  resolveStoreModules(require('../store/state.js'), 'state.js')
  resolveStoreModules(require('../store/webinar/index.ts'), 'webinar/index.ts')
  resolveStoreModules(require('../store/editions/index.ts'), 'editions/index.ts')
  resolveStoreModules(require('../store/community/index.ts'), 'community/index.ts')
  resolveStoreModules(require('../store/auth/index.ts'), 'auth/index.ts')
  resolveStoreModules(require('../store/absences/actions.js'), 'absences/actions.js')
  resolveStoreModules(require('../store/absences/getters.js'), 'absences/getters.js')
  resolveStoreModules(require('../store/absences/mutations.js'), 'absences/mutations.js')
  resolveStoreModules(require('../store/absences/state.js'), 'absences/state.js')
  resolveStoreModules(require('../store/actions/actions.js'), 'actions/actions.js')
  resolveStoreModules(require('../store/actions/getters.js'), 'actions/getters.js')
  resolveStoreModules(require('../store/actions/mutations.js'), 'actions/mutations.js')
  resolveStoreModules(require('../store/actions/state.js'), 'actions/state.js')
  resolveStoreModules(require('../store/assessments/actions.js'), 'assessments/actions.js')
  resolveStoreModules(require('../store/assessments/getters.js'), 'assessments/getters.js')
  resolveStoreModules(require('../store/assessments/mutations.js'), 'assessments/mutations.js')
  resolveStoreModules(require('../store/assessments/state.js'), 'assessments/state.js')
  resolveStoreModules(require('../store/bookmarks/actions.js'), 'bookmarks/actions.js')
  resolveStoreModules(require('../store/bookmarks/getters.js'), 'bookmarks/getters.js')
  resolveStoreModules(require('../store/bookmarks/mutations.js'), 'bookmarks/mutations.js')
  resolveStoreModules(require('../store/bookmarks/state.js'), 'bookmarks/state.js')
  resolveStoreModules(require('../store/certifications/actions.js'), 'certifications/actions.js')
  resolveStoreModules(require('../store/certifications/getters.js'), 'certifications/getters.js')
  resolveStoreModules(require('../store/certifications/mutations.js'), 'certifications/mutations.js')
  resolveStoreModules(require('../store/certifications/state.js'), 'certifications/state.js')
  resolveStoreModules(require('../store/chat/conversations.ts'), 'chat/conversations.ts')
  resolveStoreModules(require('../store/chat/messages.ts'), 'chat/messages.ts')
  resolveStoreModules(require('../store/chat/report.ts'), 'chat/report.ts')
  resolveStoreModules(require('../store/coachings/actions.js'), 'coachings/actions.js')
  resolveStoreModules(require('../store/coachings/getters.js'), 'coachings/getters.js')
  resolveStoreModules(require('../store/coachings/mutations.js'), 'coachings/mutations.js')
  resolveStoreModules(require('../store/coachings/state.js'), 'coachings/state.js')
  resolveStoreModules(require('../store/conversations/actions.js'), 'conversations/actions.js')
  resolveStoreModules(require('../store/conversations/getters.js'), 'conversations/getters.js')
  resolveStoreModules(require('../store/conversations/mutations.js'), 'conversations/mutations.js')
  resolveStoreModules(require('../store/conversations/state.js'), 'conversations/state.js')
  resolveStoreModules(require('../store/drafts/actions.js'), 'drafts/actions.js')
  resolveStoreModules(require('../store/drafts/getters.js'), 'drafts/getters.js')
  resolveStoreModules(require('../store/drafts/mutations.js'), 'drafts/mutations.js')
  resolveStoreModules(require('../store/drafts/state.js'), 'drafts/state.js')
  resolveStoreModules(require('../store/masterclasses/actions.js'), 'masterclasses/actions.js')
  resolveStoreModules(require('../store/masterclasses/getters.js'), 'masterclasses/getters.js')
  resolveStoreModules(require('../store/masterclasses/mutations.js'), 'masterclasses/mutations.js')
  resolveStoreModules(require('../store/masterclasses/state.js'), 'masterclasses/state.js')
  resolveStoreModules(require('../store/messages/actions.js'), 'messages/actions.js')
  resolveStoreModules(require('../store/messages/getters.js'), 'messages/getters.js')
  resolveStoreModules(require('../store/messages/mutations.js'), 'messages/mutations.js')
  resolveStoreModules(require('../store/messages/state.js'), 'messages/state.js')
  resolveStoreModules(require('../store/notes/actions.js'), 'notes/actions.js')
  resolveStoreModules(require('../store/notes/getters.js'), 'notes/getters.js')
  resolveStoreModules(require('../store/notes/mutations.js'), 'notes/mutations.js')
  resolveStoreModules(require('../store/notes/state.js'), 'notes/state.js')
  resolveStoreModules(require('../store/objectives/actions.js'), 'objectives/actions.js')
  resolveStoreModules(require('../store/objectives/getters.js'), 'objectives/getters.js')
  resolveStoreModules(require('../store/objectives/mutations.js'), 'objectives/mutations.js')
  resolveStoreModules(require('../store/objectives/state.js'), 'objectives/state.js')
  resolveStoreModules(require('../store/planning/actions.js'), 'planning/actions.js')
  resolveStoreModules(require('../store/planning/getters.js'), 'planning/getters.js')
  resolveStoreModules(require('../store/planning/mutations.js'), 'planning/mutations.js')
  resolveStoreModules(require('../store/planning/state.js'), 'planning/state.js')
  resolveStoreModules(require('../store/projects/actions.js'), 'projects/actions.js')
  resolveStoreModules(require('../store/projects/getters.js'), 'projects/getters.js')
  resolveStoreModules(require('../store/projects/mutations.js'), 'projects/mutations.js')
  resolveStoreModules(require('../store/projects/state.js'), 'projects/state.js')
  resolveStoreModules(require('../store/signableDocuments/actions.js'), 'signableDocuments/actions.js')
  resolveStoreModules(require('../store/signableDocuments/getters.js'), 'signableDocuments/getters.js')
  resolveStoreModules(require('../store/signableDocuments/mutations.js'), 'signableDocuments/mutations.js')
  resolveStoreModules(require('../store/signableDocuments/state.js'), 'signableDocuments/state.js')
  resolveStoreModules(require('../store/steps/actions.js'), 'steps/actions.js')
  resolveStoreModules(require('../store/steps/getters.js'), 'steps/getters.js')
  resolveStoreModules(require('../store/steps/mutations.js'), 'steps/mutations.js')
  resolveStoreModules(require('../store/steps/state.js'), 'steps/state.js')
  resolveStoreModules(require('../store/syllabus/actions.ts'), 'syllabus/actions.ts')
  resolveStoreModules(require('../store/syllabus/getters.ts'), 'syllabus/getters.ts')
  resolveStoreModules(require('../store/syllabus/mutations.ts'), 'syllabus/mutations.ts')
  resolveStoreModules(require('../store/syllabus/state.ts'), 'syllabus/state.ts')
  resolveStoreModules(require('../store/tasks/actions.js'), 'tasks/actions.js')
  resolveStoreModules(require('../store/tasks/getters.js'), 'tasks/getters.js')
  resolveStoreModules(require('../store/tasks/mutations.js'), 'tasks/mutations.js')
  resolveStoreModules(require('../store/tasks/state.js'), 'tasks/state.js')
  resolveStoreModules(require('../store/templates/actions.js'), 'templates/actions.js')
  resolveStoreModules(require('../store/templates/getters.js'), 'templates/getters.js')
  resolveStoreModules(require('../store/templates/mutations.js'), 'templates/mutations.js')
  resolveStoreModules(require('../store/templates/state.js'), 'templates/state.js')
  resolveStoreModules(require('../store/trainingCourses/actions.js'), 'trainingCourses/actions.js')
  resolveStoreModules(require('../store/trainingCourses/getters.js'), 'trainingCourses/getters.js')
  resolveStoreModules(require('../store/trainingCourses/mutations.js'), 'trainingCourses/mutations.js')
  resolveStoreModules(require('../store/trainingCourses/state.js'), 'trainingCourses/state.js')
  resolveStoreModules(require('../store/users/actions.js'), 'users/actions.js')
  resolveStoreModules(require('../store/users/getters.js'), 'users/getters.js')
  resolveStoreModules(require('../store/users/mutations.js'), 'users/mutations.js')
  resolveStoreModules(require('../store/users/state.js'), 'users/state.js')
  resolveStoreModules(require('../store/webinar/bookmark.ts'), 'webinar/bookmark.ts')
  resolveStoreModules(require('../store/webinar/enrollments.ts'), 'webinar/enrollments.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
