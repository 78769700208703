import { defineNuxtMiddleware } from '@/types/nuxt-helpers'

export default defineNuxtMiddleware(({ route, store }) => {
  const currentScreenView = route.meta?.reduce(
    (screenView: any, meta: any) => meta.screenView || screenView,
    [],
  )

  store.commit('SET_CURRENT_SCREEN_VIEW', currentScreenView)
})
