import { defineNuxtMiddleware } from '@/types/nuxt-helpers'
import { DocumentKind, Document, DocumentStatus } from '@/models/Document'

/* Signature
 * This middleware checks if any document needs to be signed and redirect to signature page if needed
 * It also redirect any user on signature that doesn't have any document needing signature
 */

let isFetching = false

export default defineNuxtMiddleware(async ({ redirect, route, $api, $cookies, store }) => {
  const isLogoutRoute = route.name === 'logout'
  const user = store.getters['auth/user']

  if (!user || isFetching || isLogoutRoute) {
    return
  }
  // Fetch documents waiting for a signature and get the first expired document signature if any client only
  try {
    isFetching = true

    const documents = await $api.signable_documents.toBeSigned()

    const mandatoryDocumentsKind = [
      DocumentKind.LEGAL_CERTIFICATION,
      DocumentKind.WEBINAR_AGREEMENT,
      DocumentKind.ABSENCE_PROOF,
      DocumentKind.TRAINING_DIPLOMA,
      DocumentKind.ATTENDANCE_CERTIFICATE,
    ]

    const documentToBeSigned = documents.find((document: Document) => {
      return (
        mandatoryDocumentsKind.includes(document.kind) && document.status === DocumentStatus.WAITING
      )
    })

    const shouldNotSignTrainingDiploma = Boolean(
      $cookies.get('shouldNotSignTrainingDiploma') &&
        documentToBeSigned?.kind === DocumentKind.TRAINING_DIPLOMA,
    )

    if (shouldNotSignTrainingDiploma) {
      return
    }

    // If the user is on a signature URL without any expired document signature, we redirect to default route
    if (route.name?.includes('signature') && !documentToBeSigned) {
      redirect('/')
      return
    }

    // If the user is not on a signature URL and has expired document signature, we redirect to signature
    if (!route.name?.includes('signature') && documentToBeSigned) {
      redirect(`/signature/${documentToBeSigned.id}`)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('[middleware signature]', error)
  } finally {
    isFetching = false
  }
})
