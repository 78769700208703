import { Context } from '@nuxt/types'
import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import { defaultAxiosConfig } from '@/config/modules/axios'
import { LmApi } from '@/services/lm-api.provider'
import { LmMonolith } from '@/services/lm-monolith.provider'
import { defineNuxtPlugin } from '@/types/nuxt-helpers'

let lmMonolith: LmMonolith
let lmApi: LmApi

export default defineNuxtPlugin(
  async ({ $axios, store, $auth, route, redirect, $config }, inject) => {
    try {
      // API instance
      const apiInstance = $axios.create({
        ...defaultAxiosConfig,
        baseURL: $config.API_URL,
      })

      setTokenOnEachRequest({ instance: apiInstance, $auth })
      startAxiosErrorsInterceptor({ route, redirect, $auth, instance: apiInstance })

      lmApi = new LmApi(apiInstance)

      const settings = await lmApi.settings.get()
      store.dispatch('SET_RUN_HOST_URL', settings.run_host_url)

      // Monolith instance
      const monolithInstance = $axios.create({
        ...defaultAxiosConfig,
        baseURL: settings.run_host_url,
      })

      setTokenOnEachRequest({ instance: monolithInstance, $auth })
      startAxiosErrorsInterceptor({ route, redirect, $auth, instance: monolithInstance })

      lmMonolith = new LmMonolith(monolithInstance)

      inject('api', lmApi)
      inject('monolith', lmMonolith)
    } catch (error) {
      throw new Error(`[Plugin/Axios] ${error}`)
    }
  },
)

const setTokenOnEachRequest = ({
  instance,
  $auth,
}: {
  instance: NuxtAxiosInstance
  $auth: Context['$auth']
}) => {
  instance.onRequest(() => {
    const isLoggedIn = $auth.isLoggedIn()
    if (isLoggedIn) {
      const token = $auth.getToken() || ''
      instance.setToken(token, 'Bearer')
    }
  })
}

const startAxiosErrorsInterceptor = ({
  instance,
  $auth,
  route,
  redirect,
}: {
  instance: Context['$axios']
  $auth: Context['$auth']
  route: Context['route']
  redirect: Context['redirect']
}) => {
  instance.onError((error) => {
    try {
      if (
        error.response &&
        [401].includes(error.response.status) &&
        !route.query.auth_jwt &&
        $auth.isLoggedIn()
      ) {
        redirect('/logout')
      }

      return Promise.reject(error)
    } catch (error) {
      throw new Error(`[Plugin/Axios](axiosErrorsInterceptor) ${error}`)
    }
  })
}

// Exporting instances to be allowed to use it outside of the context
const getLmApi = () => lmApi
const getLmMonolith = () => lmMonolith

export { getLmApi, getLmMonolith }
