import { MutationTree } from 'vuex/types/index'
import { SyllabusRootState } from './state'
import { Syllabus } from '@/models/Syllabus'

const mutations: MutationTree<SyllabusRootState> = {
  SET_SYLLABUS: (state, syllabus: Syllabus) => state.push(syllabus),
  UPDATE_SYLLABUS: (state, syllabus: Syllabus) => {
    const index = state.findIndex(({ masterclass }) => masterclass.id === syllabus.masterclass.id)
    state[index] = syllabus
  },
}

export default mutations
