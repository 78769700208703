







import { Component, Vue } from 'nuxt-property-decorator'
import { MkrApp } from '@livementor/mikado_reborn/src/index-core'
import GlobalModalManager from '@/components/global/modals/GlobalModalManager.vue'

@Component({
  name: 'Full',
  components: {
    GlobalModalManager,
    MkrApp,
  },
})
export default class FullLayout extends Vue {}
