import { ActionTree, GetterTree, MutationTree } from 'vuex/types/index'
import { RootState } from '..'
import {
  ConversationUser,
  PostBlocking,
  PostReport,
  RecipientMembership,
} from '@/models/LMConversation'
import { LMMessage } from '@/models/Message'

export type ReportState = {
  userToReport: (ConversationUser & { conversation_id: number }) | null
  messageToReport: LMMessage | null
}

export const state = (): ReportState => ({
  userToReport: null,
  messageToReport: null,
})

export const getters: GetterTree<ReportState, RootState> = {
  getIsReportModalOpen: (state) => {
    return !!state.userToReport
  },
  getIsMessageReportModalOpen: (state) => {
    return !!state.messageToReport
  },
}

export const mutations: MutationTree<ReportState> = {
  setUserToReport(state, userToReport) {
    state.userToReport = userToReport
  },
  setMessageToReport(state, messageToReport) {
    state.messageToReport = messageToReport
  },
}

export const actions: ActionTree<ReportState, RootState> = {
  setUserToReport({ commit }, userToReport: null | RecipientMembership) {
    commit('setUserToReport', userToReport)
  },
  setMessageToReport({ commit }, messageToReport) {
    commit('setMessageToReport', messageToReport)
  },
  async reportMessage({ commit, state }, payload: PostReport) {
    if (!state.messageToReport?.id) {
      return
    }
    // WARNING bien vérifier les endpoints
    await this.$api.chat.report(payload)
    commit('setMessageToReport', null)
  },
  async reportUser({ commit }, payload: PostBlocking) {
    await this.$api.chat.block(payload)

    commit('setUserToReport', null)
  },
  async unblockUser({ commit }, id: number) {
    await this.$api.chat.unblock(id)
    commit('setUserToReport', null)
  },
}
