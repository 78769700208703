








































import { Action, Component, Getter, Vue } from 'nuxt-property-decorator'
import { MkrTextButton, MkrContainedButton, MkrApp } from '@livementor/mikado_reborn/src/index-core'

import FreeCourseIllu from '@/assets/img/illustrations/free_course_illu.svg'

import GlobalModalManager from '@/components/global/modals/GlobalModalManager.vue'
import Navigation from '@/components/navigation/index.vue'
import { SET_GLOBAL_MODAL } from '@/constants/actions-type'
import { ModalTypes, SetGlobalMeetingSalesModal } from '@/constants/ModalTypes'
import Testimonial from '@/components/global/Testimonial.vue'

@Component({
  components: {
    MkrApp,
    Navigation,
    GlobalModalManager,
    MkrTextButton,
    MkrContainedButton,
    FreeCourseIllu,
    Testimonial,
  },
})
export default class FreeCourseLayout extends Vue {
  @Getter('freeCourseLayoutTitle') freeCourseLayoutTitle?: string

  @Action(SET_GLOBAL_MODAL) setGlobalMeetingSalesModal!: SetGlobalMeetingSalesModal

  get backPath() {
    const masterclassId = this.$route.params.masterclassId
    const lessonId = this.$route.params.lessonId
    return lessonId ? `/courses/free/${masterclassId}` : '/courses'
  }

  openMeetingSalesModal() {
    const context =
      this.$route.name === 'courses-free-masterclassId'
        ? 'free-syllabus-sidebar'
        : 'free-lesson-sidebar'

    this.setGlobalMeetingSalesModal({
      type: ModalTypes.MEETING_SALES_MODAL,
      payload: {
        context,
        masterclassTitle: this.freeCourseLayoutTitle,
      },
    })
  }

  testimonialImages = [
    require('@/assets/img/testimonial/user-1.png'),
    require('@/assets/img/testimonial/user-2.png'),
    require('@/assets/img/testimonial/user-3.png'),
    require('@/assets/img/testimonial/user-4.png'),
    require('@/assets/img/testimonial/user-5.png'),
  ]
}
