interface FilterDateOptions extends Intl.DateTimeFormatOptions {
  locale: string
}

const validRequiredAttributes = (date: string | number | Date, options: FilterDateOptions) => {
  if (typeof date === 'undefined') {
    throw new TypeError('[FilterDate] The `date` attribute is required.')
  }
  if (typeof options.locale === 'undefined') {
    throw new TypeError('[FilterDate] The `options.locale` attribute is required.')
  }
  if (typeof options.locale !== 'string') {
    throw new TypeError('[FilterDate] The `options.locale` attribute must be a string.')
  }
}

export const date =
  (defaultLocale: string) =>
  (date: string | number | Date, options?: Partial<FilterDateOptions>): string => {
    const DEFAULT_OPTIONS: FilterDateOptions = {
      locale: defaultLocale,
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }

    const opts: FilterDateOptions = {
      ...DEFAULT_OPTIONS,
      ...options,
    }

    validRequiredAttributes(date, opts)

    try {
      const dateToParse = date instanceof Date ? date : new Date(date)
      return new Intl.DateTimeFormat(opts.locale, options).format(dateToParse)
    } catch (error) {
      throw new Error(`[FilterDate] ${error}`)
    }
  }
