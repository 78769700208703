var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-layout",class:{
    'app-layout--full': !_vm.hasLeftPanel,
    'app-layout--two-cols--left': _vm.hasLeftPanel,
    'app-layout--detail-content': _vm.isDetailView,
    'app-layout--master-content': _vm.isMasterView,
  }},[_c('Navigation'),_vm._v(" "),(_vm.hasLeftPanel)?_c('aside',{staticClass:"border-r border-neutral-20",class:{
      'hidden md:block': !_vm.isMasterView,
    }},[_vm._t("left-panel")],2):_vm._e(),_vm._v(" "),_c('main',{class:{ 'hidden md:block': _vm.isMasterView }},[_vm._t("content")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }