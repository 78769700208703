import { Context } from '@nuxt/types'
import { AnalyticsBrowser } from '@segment/analytics-next'

export default ({ $config }: Context, inject: any) => {
  try {
    const analytics = AnalyticsBrowser.load({ writeKey: $config.SEGMENT_WRITE_KEY })

    inject('segment', analytics)
  } catch (error) {
    inject('segment', {
      page: () => {},
      track: () => {},
      identify: () => {},
    })
    throw new Error(`Segment failed to load: ${error}`)
  }
}
