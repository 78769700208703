import firebase from 'firebase/compat/app'
// MODULES
import 'firebase/compat/analytics'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

// COLLECTIONS
import { FirebaseConfig, FirebaseApp, FirebaseDb } from './types'
import { UsersCollection } from '@/services/lm-firebase/collections/UsersCollection'
import { ConversationsCollection } from '@/services/lm-firebase/collections/ConversationsCollection'
import { BookmarksCollection } from '@/services/lm-firebase/collections/BookMarksCollection'
import { MessagesCollection } from '@/services/lm-firebase/collections/MessagesCollection'
import { DraftsCollection } from '@/services/lm-firebase/collections/DraftsCollection'
import { SessionsCollection } from '@/services/lm-firebase/collections/SessionsCollection'

import { User } from '@/models/User'

export class LmFirebase {
  private readonly app: FirebaseApp
  private readonly db: FirebaseDb

  readonly usersCollection: UsersCollection
  readonly conversationsCollection: ConversationsCollection
  readonly messagesCollection: MessagesCollection
  readonly bookmarksCollection: BookmarksCollection
  readonly draftsCollection: DraftsCollection
  readonly sessionCollection: SessionsCollection

  constructor(private readonly config: FirebaseConfig) {
    this.app = firebase.initializeApp(this.config)
    this.db = this.app.firestore()

    this.usersCollection = new UsersCollection(this.db, config)
    this.sessionCollection = new SessionsCollection(this.db, config)

    this.conversationsCollection = new ConversationsCollection(this.db, config)
    this.messagesCollection = new MessagesCollection(this.conversationsCollection)
    this.bookmarksCollection = new BookmarksCollection(
      this.usersCollection,
      this.messagesCollection,
    )
    this.draftsCollection = new DraftsCollection(this.usersCollection)
  }

  terminateDb() {
    try {
      return this.db.terminate()
    } catch (error) {
      throw new Error(`[LmFirebase](terminateDb) ${error}`)
    }
  }

  logout() {
    try {
      return this.app.auth().signOut()
    } catch (error) {
      throw new Error(`[LmFirebase](logout) ${error}`)
    }
  }

  login(user: User) {
    try {
      if (!user.firebase_password) {
        throw String('user invalid')
      }
      if (!user.email) {
        throw String('User has not email')
      }

      this.setFirebaseAnalytics(user)

      return this.app.auth().signInWithEmailAndPassword(user.email, user.firebase_password)
    } catch (error) {
      throw new Error(`[LmFirebase](login) ${error}`)
    }
  }

  setFirebaseAnalytics(user: User) {
    if (this.app.auth().currentUser === null && user.id) {
      this.app.analytics().setUserId(user.id.toString())
      this.app.analytics().logEvent('login')
    }
  }

  async fetchMessage(messageId: string) {
    try {
      const querySnapshot = await this.db
        .collectionGroup('messages')
        .where('id', '==', messageId)
        .get()

      if (querySnapshot.empty) {
        return undefined
      }

      return querySnapshot.docs[0].data()
    } catch (error) {
      throw new Error(`[LmFirebase](fetchMessage) ${error}`)
    }
  }
}
