




import { Component, Vue, namespace } from 'nuxt-property-decorator'

import MentorNavigation from '@/components/navigation/mentor/mentorNavigation.vue'
import StudentNavigation from '@/components/navigation/student/studentNavigation.vue'

const usersModule = namespace('users')

@Component({
  components: {
    MentorNavigation,
    StudentNavigation,
  },
})
export default class Navigation extends Vue {
  @usersModule.Getter('currentUserIsMentor')
  currentUserIsMentor!: boolean

  get navigationComponent() {
    return this.currentUserIsMentor ? 'MentorNavigation' : 'StudentNavigation'
  }
}
