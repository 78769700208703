import Vue from 'vue'
import {
  DELETE_STEP,
  DELETE_STEP_TASK,
  MERGE_STEP,
  MERGE_STEP_TASK,
  SET_STEPS,
  SET_STEP_TASKS,
} from '@/constants/mutations-type'

export default {
  [SET_STEPS]: (state, steps) => {
    steps.forEach((step) => {
      Vue.set(state, step.id, step)
    })
  },
  [DELETE_STEP]: (state, stepId) => {
    Vue.delete(state, stepId)
  },
  [MERGE_STEP]: (state, step) => {
    Vue.set(state, step.id, { ...state[step.id], ...step })
  },
  [SET_STEP_TASKS]: (state, { stepId, tasks }) => {
    Vue.set(state, stepId, {
      ...state[stepId],
      tasks: tasks.map((task) => task.id),
    })
  },
  [MERGE_STEP_TASK]: (state, { stepId, task }) => {
    const tasks = [...(state[stepId].tasks || [])]
    const taskIndex = tasks.findIndex((stateTaskId) => stateTaskId === task.id)
    const taskPosition = task.position - 1
    if (taskIndex === -1) {
      tasks.push(task.id)
    } else if (taskPosition !== taskIndex) {
      tasks.splice(taskIndex, 1)
      tasks.splice(taskPosition, 0, task.id)
    }
    Vue.set(state, stepId, {
      ...state[stepId],
      tasks,
    })
  },
  [DELETE_STEP_TASK]: (state, { stepId, taskId }) => {
    const step = state[stepId]
    if (step && step.tasks.length) {
      step.tasks = step.tasks.filter((storeTaskId) => storeTaskId !== taskId)
    }
  },
}
