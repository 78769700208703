import {
  MARK_CONVERSATION_AS_READ,
  MARK_CONVERSATION_AS_UNREAD,
  MERGE_USERS,
} from '@/constants/mutations-type'

export default {
  SET_USER: ({ commit }, user) => {
    commit(MERGE_USERS, user)
  },
  SET_CONVERSATION_AS_READ: ({ commit }, { conversationId, userId }) => {
    commit(MARK_CONVERSATION_AS_READ, {
      conversationId,
      userId,
    })
  },
  SET_CONVERSATION_AS_UNREAD: ({ commit }, { conversationId, userId }) => {
    commit(MARK_CONVERSATION_AS_UNREAD, {
      conversationId,
      userId,
    })
  },
  SET_USERS: ({ commit }, payload) => {
    return new Promise((resolve) => {
      resolve()
      commit(MERGE_USERS, payload)
    })
  },
}
