import { NavigationProfileItem } from '@/types/navigation-items'

/* eslint-disable no-template-curly-in-string */
export const profileEntries: NavigationProfileItem[][] = [
  [
    {
      labelKey: 'profile',
      icon: 'user',
      url: '/account/profile',
    },
    {
      labelKey: 'project',
      icon: 'briefcase',
      url: '/account/project',
    },
    {
      labelKey: 'journey',
      icon: 'play-circle',
      url: '/account/journey',
    },
    {
      labelKey: 'documents',
      icon: 'document-text',
      url: '/account/documents',
    },
  ],
  [
    {
      labelKey: 'membership',
      url: 'https://www.livementor.com/programme-fidelite-livementor',
      external: true,
    },
    {
      labelKey: 'referral',
      url: 'https://www.livementor.com/programme-parrainage-livementor/?last_source_level_2=${email}&hidden_field_1=${name}',
      external: true,
    },
    {
      labelKey: 'tools',
      url: 'https://www.livementor.com/la-boite-a-outils/',
      external: true,
    },
  ],
  [
    {
      labelKey: 'logout',
      url: '/logout',
    },
  ],
]
/* eslint-enable no-template-curly-in-string */
