export default {
  getMasterclasses: (state) => {
    return state.courses
  },
  getModules: (state) => (masterclassId) => {
    return state.modules && state.modules[masterclassId]
  },
  getLessons: (state) => (masterclassId) => {
    return state.lessons && state.lessons[masterclassId]
  },
  getBonuses: (state) => (masterclassId) => {
    return state.bonuses && state.bonuses[masterclassId]
  },
  getLessonViews: (state) => (masterclassId) => {
    return state.lessonViews && state.lessonViews[masterclassId]
  },
  getCurrentLesson: (state) => (lessonId, masterclassId) => {
    return state.lessons && state.lessons[masterclassId]?.find(({ id }) => lessonId === id)
  },
  getCurrentBonus: (state) => (bonusId, masterclassId) => {
    return state.bonuses && state.bonuses[masterclassId]?.find(({ id }) => bonusId === id)
  },
  getCurrentCourse: (state) => (courseId) => {
    return state.courses && state.courses.find(({ id }) => courseId === id)
  },
  lessonPageIsLoading: (state) => {
    return state.lessonPageIsLoading
  },
  getMilestones: (state) => (masterclassId) => {
    return state.milestones && state.milestones[masterclassId]
  },
  getCurrentMilestone: (state) => (milestoneId, masterclassId) => {
    return state.milestones && state.milestones[masterclassId]?.find(({ id }) => milestoneId === id)
  },
  getSummaryByMasterclassId: (state) => (masterclassId) => {
    const modules = state.modules[masterclassId] || []
    const lessons = state.lessons[masterclassId] || []
    const milestones = state.milestones[masterclassId] || []

    // modules contains a property "lessons_ids" which is an array of lesson ids
    // modules also contains a property "milestones_ids" which is an array of milestone ids
    // map over the modules and create a new array of objects with the following properties:
    // position, id, type, title, isCompleted

    return modules.map((module) => {
      const lessonSummary =
        module.lesson_ids?.map(([position, id]) => {
          const lesson = lessons.find((lesson) => lesson.id === id)

          return {
            position,
            id,
            type: 'lesson',
            title: lesson.title,
            isCompleted: lesson.viewed_by_user,
          }
        }) || []

      const milestoneSummary =
        module.milestone_ids?.map(([position, id]) => {
          const milestone = milestones.find((milestone) => milestone.id === id)
          return {
            position,
            id: milestone.id,
            type: 'milestone',
            title: milestone.title,
            isCompleted: milestone.completed_by_user,
          }
        }) || []

      const summary = [...lessonSummary, ...milestoneSummary].sort(
        (a, b) => a.position - b.position,
      )

      return {
        ...module,
        summary,
      }
    })
  },
}
