import { MERGE_TEMPLATES } from '@/constants/mutations-type'

export default {
  createTemplate({ commit }, template) {
    this.$api.template.create(template.title, template.content).then((response) => {
      commit(MERGE_TEMPLATES, [response.data.data])
    })
  },
  updateTemplate({ commit }, template) {
    this.$api.template.update(template).then((response) => {
      commit(MERGE_TEMPLATES, [response.data.data])
    })
  },
  getTemplate({ commit }, id) {
    this.$api.template.get(id).then((response) => {
      commit(MERGE_TEMPLATES, [response.data.data])
    })
  },
  fetchTemplates({ commit }) {
    this.$api.template.list().then((response) => {
      commit(MERGE_TEMPLATES, response.data.data)
    })
  },
}
