import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import * as Sentry from '@sentry/browser'
import { CaptureConsole } from '@sentry/integrations'

export default defineNuxtPlugin(({ $config, store }) => {
  const user = store.getters['auth/user']

  Sentry.init({
    dsn: $config.SENTRY_DSN,
    environment: $config.DEPLOY_TARGET ?? $config.NODE_ENV,

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    release: process.env.npm_package_version,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
      new CaptureConsole({ levels: ['error'] }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

  if (user) {
    Sentry.setUser({
      email: user.email,
      id: user.id,
    })
  }
})
