import { dateMin } from './dateMin'
import { dayInterval } from './dayInterval'
import { phone } from './phone'
import { url } from './url'

export default {
  dateMin,
  dayInterval,
  phone,
  url,
}
