var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('NavigationManager',{attrs:{"main-entries":_vm.mainEntries,"profile-entries":_vm.profileEntries,"community-entries":_vm.communityEntries},scopedSlots:_vm._u([{key:"logo",fn:function(){return [_c('LogoMentor')]},proxy:true},{key:"secondary",fn:function(){return [_c('MkrNavItemGroup',{attrs:{"top-nav":""}},[_c('nuxt-link',{attrs:{"to":"/webinar","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('MkrNavItem',{attrs:{"icon":"tribe","href":href,"active":isActive,"title":_vm.$t('navigation.menu_entries.webinar')},on:{"click":navigate}})]}}])}),_vm._v(" "),(_vm.hasAccessToEdition)?_c('nuxt-link',{attrs:{"to":"/edition","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('MkrNavItem',{attrs:{"icon":"video-camera-circle","href":href,"active":isActive,"title":_vm.$t('navigation.menu_entries.hot-seat')},on:{"click":navigate}})]}}],null,false,1442238802)}):_vm._e(),_vm._v(" "),_c('nuxt-link',{attrs:{"to":"/courses","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('MkrNavItem',{attrs:{"icon":"book-open","href":href,"active":isActive,"title":_vm.$t('navigation.menu_entries.courses')},on:{"click":navigate}})]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }