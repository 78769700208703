












import { Component, Vue, namespace } from 'nuxt-property-decorator'
import { MkrContainedButton } from '@livementor/mikado_reborn/src/index-core'

const usersModule = namespace('users')

@Component({
  components: {
    MkrContainedButton,
  },
})
export default class HelpButton extends Vue {
  @usersModule.Getter('currentUserIsMentor')
  currentUserIsMentor!: boolean

  helpClicked() {
    this.$segment.track('Clicked Help', { tracking_version: '1.0', path: this.$route.path })
    window.open('https://livementor.zendesk.com/hc/fr', '_blank')
  }
}
