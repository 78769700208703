import { NuxtConfig } from '@nuxt/types'
import { Config } from '../environment'

export const defaultAxiosConfig = {
  headers: {
    common: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
}

export const axios = ({ API_URL }: Config): NuxtConfig['axios'] => ({
  ...defaultAxiosConfig,
  baseURL: API_URL,
})
