import { ConversationsCollection } from './ConversationsCollection'
import { getLmApi } from '@/plugins/axios'
import { FirebaseDoc, FirebaseSnapshot, FirebaseSubCollection } from '@/services/lm-firebase/types'
import { CoachingContext } from '@/models/Coaching'

export enum MessageType {
  MEDIA = 'media',
  TEXT = 'text',
  CUSTOM = 'custom',
}

interface MessageDoc {
  message: {
    content?: any
    author: string
    type: MessageType
    id: string

    created_at: string | number
    updated_at: string | number
    /* eslint-enable camelcase */
  }
  fireCall: Promise<void>
}

export class MessagesCollection {
  private readonly messagesCollection: FirebaseSubCollection

  constructor(private readonly conversationsCollection: ConversationsCollection) {
    this.messagesCollection = (conversationId: string) => {
      return this.conversationsCollection.instance.doc(conversationId).collection('messages')
    }
  }

  private async createMediaMessage({ data }: { data: any }) {
    try {
      const file = new FormData()
      file.set('asset[file]', data.file)

      const { url } = await getLmApi().chat.uploadAsset(file)

      const fileUrl = url as string | undefined

      if (!fileUrl) {
        throw String('no file url')
      }

      const isMediaFile = ['file', 'image', 'video'].includes(data.type)

      return {
        content: {
          data: isMediaFile
            ? {
                url: fileUrl,
                filename: data.file.name,
              }
            : fileUrl,
        },
        type: data.type,
      }
    } catch (error) {
      throw new Error(`[MessageCollection](createMediaMessage) ${error}`)
    }
  }

  private createTextMessage({ text }: { text: string }) {
    const message: { content: { text: string } } = {
      content: {
        text,
      },
    }
    return message
  }

  private createCustomMessage({
    data,
  }: {
    data: {
      context?: CoachingContext
      title?: string
      kind?: string
      description?: string
    }
  }) {
    return {
      content: {
        ...data,
      },
    }
  }

  // TODO: use better type for data
  private getContent(messageType: MessageType, content: { text: string; data: any }) {
    switch (messageType) {
      case MessageType.MEDIA: {
        return this.createMediaMessage(content)
      }
      case MessageType.TEXT: {
        return this.createTextMessage(content)
      }
      case MessageType.CUSTOM: {
        return this.createCustomMessage(content)
      }
      default:
        return undefined
    }
  }

  listenForUpdate(conversationId: string) {
    try {
      return this.messagesCollection(conversationId)
    } catch (error) {
      throw new Error(`[MessageCollection](listenForUpdate) ${error}`)
    }
  }

  getConversationMessageById({
    conversationId,
    messageId,
  }: {
    conversationId: string
    messageId: string
  }): FirebaseDoc {
    try {
      return this.messagesCollection(conversationId).doc(messageId)
    } catch (error) {
      throw new Error(`[MessageCollection](getConversationMessageById) ${error}`)
    }
  }

  async sendMessage(
    authorId: string,
    conversationId: string,
    type: MessageType,
    text: string,
    data?: any,
  ): Promise<MessageDoc> {
    try {
      const content = await this.getContent(type, {
        text,
        data,
      })

      const newMessage = this.messagesCollection(conversationId).doc()

      const message = {
        id: newMessage.id,
        author: type === MessageType.CUSTOM ? null : authorId.toString(),
        type,
        ...content,
      }

      return {
        message,
        fireCall: newMessage.set(message),
      } as MessageDoc
    } catch (error) {
      throw new Error(`[MessageCollection](sendMessage) ${error}`)
    }
  }

  updateMessage(conversationId: string, messageId: string, text: string) {
    try {
      return this.messagesCollection(conversationId).doc(messageId).update({
        content: {
          text,
        },
      })
    } catch (error) {
      throw new Error(`[MessageCollection](updateMessage) ${error}`)
    }
  }

  private async queryMessages(
    conversationId: string,
    count: number,
    lastMRef?: FirebaseSnapshot,
    messageId?: string,
  ) {
    try {
      let query = this.messagesCollection(conversationId).orderBy('created_at', 'desc')

      if (lastMRef) {
        query = query.startAfter(lastMRef)
      }

      if (messageId) {
        const fromMRef = await this.messagesCollection(conversationId).doc(messageId).get()
        query = query.endAt(fromMRef)
      } else {
        query = query.limit(count)
      }

      return await query.get()
    } catch (error) {
      throw new Error(`[MessageCollection](queryMessages) ${error}`)
    }
  }

  async fetchMessages(
    conversationId: string,
    lastMessageId?: string,
    fromMessageId?: string,
    count = 10,
  ) {
    try {
      const lastMRef = lastMessageId
        ? await this.messagesCollection(conversationId).doc(lastMessageId).get()
        : undefined

      const results = await this.queryMessages(conversationId, count, lastMRef, fromMessageId)

      if (results.docs.length < count && fromMessageId) {
        return await this.queryMessages(conversationId, count, lastMRef)
      }

      return results
    } catch (error) {
      throw new Error(`[MessageCollection](fetchMessages) ${error}`)
    }
  }
}
