


































import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { MkrContainedButton } from '@livementor/mikado_reborn/src/index-core'
import { NuxtError } from '@nuxt/types'

@Component({
  components: {
    MkrContainedButton,
  },
})
export default class Error extends Vue {
  @Prop({ type: Object, default: () => {} })
  error!: NuxtError
}
