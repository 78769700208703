



























import { Component, Prop, mixins } from 'nuxt-property-decorator'

import DesktopNavigation from '@/components/navigation/common/DesktopNavigation.vue'
import MobileNavigation from '@/components/navigation/common/MobileNavigation.vue'

import Responsive from '@/mixins/Responsive'
import {
  NavigationMainItem,
  NavigationProfileItem,
  NavigationCommunityItem,
} from '@/types/navigation-items'

@Component({
  components: {
    DesktopNavigation,
    MobileNavigation,
  },
})
export default class NavigationManager extends mixins(Responsive) {
  @Prop({ default: [] }) mainEntries!: NavigationMainItem[]

  @Prop({ default: [] }) profileEntries!: NavigationProfileItem[][]

  @Prop({ default: [] }) communityEntries!: NavigationCommunityItem[][]
}
