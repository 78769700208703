import { MERGE_COACHINGS } from '@/constants/mutations-type'
import { events } from '@/plugins/notifications'
import { CoachingStatus } from '@/models/Coaching'

export default {
  async FETCH_COACHINGS(
    { commit },
    { studentId, mentorId, endingBefore, startingAfter, certificationId },
  ) {
    try {
      const coachings = await this.$api.coachings.get({
        mentor: mentorId,
        student: studentId,
        ending_before: endingBefore,
        starting_after: startingAfter,
      })

      commit(MERGE_COACHINGS, { coachings })
      commit(
        'users/MERGE_COACHINGS',
        {
          coachings,
          studentId,
        },
        { root: true },
      )
      commit(
        'certifications/MERGE_COACHINGS',
        {
          certification: certificationId,
          coachings,
        },
        { root: true },
      )
    } catch (error) {
      throw new Error(error)
    }
  },
  async CONFIRM_COACHING_AS_NO_SHOW({ commit }, { coachingId, certificationId }) {
    try {
      const coaching = await this.$api.coachings.confirmed_as_no_show(coachingId)
      commit('UPDATE_COACHING', coaching)
      commit(MERGE_COACHINGS, {
        coachings: [coaching],
      })
      commit(
        'users/MERGE_COACHINGS',
        {
          coachings: [coaching],
          studentId: coaching.student_id,
        },
        { root: true },
      )
      commit(
        'certifications/MERGE_COACHINGS',
        {
          coachings: [coaching],
          certification: certificationId,
        },
        { root: true },
      )
    } catch (error) {
      throw new Error(`[CONFIRM_COACHING_AS_NO_SHOW] ${error}`)
    }
  },
  async RESCHEDULE_COACHING({ commit }, { end, start, requestOwnerId, coaching, certification }) {
    try {
      const coachingUpdated = await this.$api.coachings.reschedule({
        end,
        start,
        id: coaching.id,
        request_owner_id: requestOwnerId,
      })

      const previousCoaching = {
        ...coaching,
        status: CoachingStatus.CANCELLED,
      }
      const coachingPayload = {
        ...coaching,
        id: coachingUpdated.id,
        start_date: start,
        end_date: end,
        status: CoachingStatus.CONFIRMED,
      }

      commit(MERGE_COACHINGS, {
        coachings: [previousCoaching, coachingPayload],
      })
      commit(
        'users/MERGE_COACHINGS',
        {
          coachings: [previousCoaching, coachingPayload],
          studentId: parseInt(coaching.student.id),
        },
        { root: true },
      )
      commit(
        'certifications/MERGE_COACHINGS',
        {
          certification,
          coachings: [previousCoaching, coachingPayload],
        },
        { root: true },
      )
    } catch (error) {
      events.$emit('notify', {
        props: {
          message: 'Une erreur est survenue lors de la création de votre rendez-vous.',
          error: true,
        },
      })
      throw new Error(`[RESCHEDULE_COACHING] ${error}`)
    }
  },
  async STUDENT_RESCHEDULE_COACHING({ commit }, { coaching, certificationId, requestOwnerId }) {
    try {
      await this.$api.coachings.reschedule({
        id: coaching.id,
        request_owner_id: requestOwnerId,
      })

      const updatedCoaching = {
        ...coaching,
        status: CoachingStatus.RESCHEDULED,
      }

      commit(MERGE_COACHINGS, {
        coachings: [updatedCoaching],
      })
      commit(
        'users/MERGE_COACHINGS',
        {
          coachings: [updatedCoaching],
          studentId: parseInt(coaching.student.id),
        },
        { root: true },
      )
      commit(
        'certifications/MERGE_COACHINGS',
        {
          certification: certificationId,
          coachings: [updatedCoaching],
        },
        { root: true },
      )
    } catch (error) {
      throw new Error(`[STUDENT_RESCHEDULE_COACHING] ${error}`)
    }
  },
  async CREATE_COACHING({ commit }, payload) {
    try {
      const newCoaching = await this.$api.coachings.create(payload)

      const coaching = {
        ...payload,
        mentor: {
          id: payload.mentor_id,
        },
        status: 'confirmed',
        student: {
          id: payload.student_id,
        },
        id: newCoaching.id,
      }

      commit(MERGE_COACHINGS, {
        coachings: [
          {
            ...coaching,
            training_course: {
              id: coaching.training_course_id,
            },
            start_date: payload.start,
            end_date: payload.end,
          },
        ],
      })
      commit(
        'users/MERGE_COACHINGS',
        {
          coachings: [
            {
              ...coaching,
              start_date: payload.start,
              end_date: payload.end,
            },
          ],
          studentId: parseInt(coaching.student.id),
        },
        { root: true },
      )
      if (payload.certification) {
        commit(
          'certifications/MERGE_COACHINGS',
          {
            certification: payload.certification,
            coachings: [coaching],
          },
          { root: true },
        )
      }
    } catch (error) {
      events.$emit('notify', {
        props: {
          message: 'Une erreur est survenue lors de la création de votre rendez-vous.',
          error: true,
        },
      })
      throw new Error(`[CREATE_COACHING] ${error}`)
    }
  },
}
