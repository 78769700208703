import { GET_SIGNABLE_DOCUMENTS } from '@/constants/actions-type'
import {
  MERGE_SIGNABLE_DOCUMENTS,
  SET_USER_WAITING_SIGNATURE_DOCUMENTS,
} from '@/constants/mutations-type'

export default {
  async [GET_SIGNABLE_DOCUMENTS]({ commit }, trainingCourseId) {
    if (!trainingCourseId) {
      return undefined
    }

    const documents = await this.$api.signable_documents.get({
      trainingCourseId,
      kind: 'legal_certification',
    })

    commit(MERGE_SIGNABLE_DOCUMENTS, {
      signable_documents: documents,
      trainingCourseId,
    })
    commit(
      'trainingCourses/MERGE_SIGNABLE_DOCUMENTS',
      { trainingCourseId, signableDocuments: documents },
      { root: true },
    )

    return documents
  },
  async fetchDocument({ commit, rootGetters }, documentId) {
    if (!documentId) {
      return undefined
    }

    const document = await this.$api.signable_documents.getById(documentId)

    if (document) {
      commit(MERGE_SIGNABLE_DOCUMENTS, {
        signable_documents: [document],
      })
      commit(
        `users/${SET_USER_WAITING_SIGNATURE_DOCUMENTS}`,
        {
          userId: rootGetters['users/getCurrentUserId'],
          documents: [document],
        },
        { root: true },
      )
    }

    return document
  },
}
