export default {
  getProjectById: (state) => (projectId) => {
    const { objective, ...project } = state[projectId]
    return project
  },
  getProjectsForStudent: (_state, getters, _rootState, rootGetters) => (studentId) => {
    const student = rootGetters['users/getUserById'](studentId)
    if (!student || !student.project) {
      return {}
    }
    return getters.getProjectById(student.project)
  },
}
