import VueRouter from 'vue-router'
import { FirebasePlugin } from './FirebasePlugin'
import { FirebaseConfig } from '@/services/lm-firebase/types'
import { defineNuxtPlugin } from '@/types/nuxt-helpers'

export default defineNuxtPlugin(({ $config, store, app: { router } }, inject) => {
  const firebaseConfig: FirebaseConfig = {
    apiKey: $config.FIRESTORE_API_KEY,
    authDomain: $config.FIRESTORE_AUTH_DOMAIN,
    databaseURL: $config.FIRESTORE_DATABASE_URL,
    projectId: $config.FIRESTORE_PROJECT_ID,
    storageBucket: $config.FIRESTORE_STORAGE_BUCKET,
    messagingSenderId: $config.FIRESTORE_MESSAGING_SENDER_ID,
    appId: $config.FIRESTORE_APP_ID,
    measurementId: $config.FIRESTORE_MEASUREMENT_ID,
    rootCollection: $config.FIRESTORE_ROOT_COLLECTION,
  }

  const firebase = new FirebasePlugin(firebaseConfig, store, router as VueRouter)

  inject('firebase', firebase)
})
