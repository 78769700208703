import {
  GLOBAL_MODAL,
  SET_BACK_ACTION_ROUTE,
  SET_SHOULD_LOGOUT,
  SET_DISPLAY_MENU,
  SET_DISPLAY_USER_DETAILS,
  SET_SELECTED_CONVERSATION,
} from '@/constants/mutations-type'
import {
  DISPLAY_MENU,
  DISPLAY_USER_DETAILS,
  SET_GLOBAL_MODAL,
  SELECT_CONVERSATION,
  RESET_SHOULD_LOGOUT,
  SET_FREE_COURSE_lAYOUT_TITLE,
} from '@/constants/actions-type'

export default {
  async nuxtServerInit({ dispatch, rootGetters, getters }) {
    try {
      if (!rootGetters['auth/loggedIn']) {
        return
      }

      await dispatch('actions/fetchActions')

      const user = getters['auth/user']
      const hasCommunityAccess = getters.hasAccessToCommunity

      if (user.student && user.student.has_community_profile && hasCommunityAccess) {
        await dispatch('chat/conversations/fetchConversations')
      }

      await dispatch('trainingCourses/GET_TRAINING_COURSES', user?.id)
    } catch (error) {
      throw new Error(`[Store](nuxtServerInit) ${error}`)
    }
  },
  [DISPLAY_MENU]: ({ commit }, value) => {
    commit(SET_DISPLAY_MENU, value)
  },
  [DISPLAY_USER_DETAILS]: ({ commit }, value) => {
    commit(SET_DISPLAY_USER_DETAILS, value)
  },
  [SELECT_CONVERSATION]: ({ commit }, conversationId) => {
    commit(SET_SELECTED_CONVERSATION, conversationId)
  },
  [SET_GLOBAL_MODAL]: ({ commit }, payload) => {
    commit(GLOBAL_MODAL, payload)
  },
  [RESET_SHOULD_LOGOUT]: ({ commit }) => {
    commit(SET_SHOULD_LOGOUT, false)
  },
  SET_BACK_ACTION_ROUTE: ({ commit }, route) => {
    commit(SET_BACK_ACTION_ROUTE, route)
  },
  SET_CURRENT_SCREEN_VIEW: ({ commit }, screenView) => {
    commit('SET_CURRENT_SCREEN_VIEW', screenView)
  },
  SET_CURRENT_SCREEN_NAME: ({ commit }, screenName) => {
    commit('SET_CURRENT_SCREEN_NAME', screenName)
  },
  SET_CURRENT_SCREEN_CHILD_NAME: ({ commit }, childName) => {
    commit('SET_CURRENT_SCREEN_CHILD_NAME', childName)
  },
  SET_IS_MOBILE: ({ commit }, isMobile) => {
    commit('SET_IS_MOBILE', isMobile)
  },
  SET_RUN_HOST_URL: ({ commit }, url) => {
    commit('SET_RUN_HOST_URL', url)
  },
  [SET_FREE_COURSE_lAYOUT_TITLE]: ({ commit }, title) => {
    commit('SET_FREE_COURSE_lAYOUT_TITLE', title)
  },
}
