import Vue from 'vue'

import { MERGE_PROJECT, MERGE_PROJECT_OBJECTIVE } from '@/constants/mutations-type'

export default {
  [MERGE_PROJECT]: (state, project) => {
    Vue.set(state, project.project_id, { ...state[project.project_id], ...project })
  },
  [MERGE_PROJECT_OBJECTIVE]: (state, { projectId, objectiveId }) => {
    Vue.set(state, projectId, { ...state[projectId], objective: objectiveId })
  },
}
