









import { Component, Vue, Watch } from 'nuxt-property-decorator'
import { MkrApp } from '@livementor/mikado_reborn/src/index-core'
import NotificationsModule from '@/plugins/notifications/NotificationsModule.vue'
import GlobalModalManager from '@/components/global/modals/GlobalModalManager.vue'
import Debug from '@/components/global/Debug.vue'

@Component({
  name: 'Default',
  components: {
    Debug,
    MkrApp,
    NotificationsModule,
    GlobalModalManager,
  },
})
export default class DefaultLayout extends Vue {
  get isMkrContextPage() {
    return this.$route.fullPath.includes('account')
  }

  @Watch('$store.state.shouldLogout')
  logoutChanged(newVal: boolean) {
    if (newVal && this.$route.name !== 'logout') {
      this.$router.push('/logout')
    }
  }
}
