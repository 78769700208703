import dayjs from 'dayjs'

import cloneDeep from 'lodash/cloneDeep'
import orderBy from 'lodash/orderBy'

export default {
  getConversations: (state, __, rootState) => {
    if (!rootState.auth.user?.id) {
      return []
    }

    return orderBy(state, ['lastMessageDate'], ['desc']).filter(({ displayTo }) =>
      displayTo.includes(rootState.auth.user.id.toString()),
    )
  },

  getActivedConversations: (state, __, rootState) => {
    if (!rootState.auth.user?.id) {
      return []
    }

    return orderBy(state, ['lastMessageDate'], ['desc']).filter(
      ({ displayTo, startDate }) =>
        displayTo.includes(rootState.auth.user.id.toString()) &&
        (startDate ? dayjs().isAfter(startDate) : true),
    )
  },

  getArchivedConversations: (state, __, rootState) => {
    if (!rootState.auth.user?.id) {
      return []
    }

    return orderBy(state, ['lastMessageDate'], ['desc']).filter(
      ({ displayTo }) => !displayTo.includes(rootState.auth.user.id.toString()),
    )
  },

  getFuturesConversations: (state, __, rootState) => {
    if (!rootState.auth.user?.id) {
      return []
    }

    return orderBy(state, ['lastMessageDate'], ['desc']).filter(
      ({ displayTo, startDate }) =>
        displayTo.includes(rootState.auth.user.id.toString()) &&
        startDate &&
        dayjs().isBefore(startDate, 'date'),
    )
  },
  getConversationById: (state) => (conversationId) => {
    return state[conversationId]
  },
  getHasConversations: (state) => {
    const conversations = Object.values(state)
    return conversations.length > 0
  },
  getSelectedConversation: (state, __, rootState) => {
    if (state[rootState.selectedConversation]) {
      const selectedConv = cloneDeep(state[rootState.selectedConversation])
      const participants = selectedConv.participants

      selectedConv.participants = participants.map((userId) => {
        if (rootState.users[userId]) {
          return rootState.users[userId]
        }
        return null
      })

      return selectedConv.participants.includes(null) ? undefined : selectedConv
    }
    return undefined
  },
  // return an array of user ids which are not the current user id
  getReceiversIdsForConversation: (state, __, rootState) => (conversationId) => {
    const conversation = state[conversationId]
    if (!conversation) {
      return []
    }

    return conversation.participants.filter((participant) => {
      return participant !== rootState.auth.user?.id?.toString()
    })
  },
  getResponderForCurrentConversation(_state, getters, _rootState, rootGetters) {
    if (!getters.getSelectedConversation) {
      return {}
    }
    const responderId = getters.getReceiversIdsForConversation(getters.getSelectedConversation.id)
    return rootGetters['users/getUserById'](responderId)
  },

  getConversationByUserId: (__, getters) => (userId) => {
    return getters.getConversations.find((conversation) =>
      conversation.displayTo.includes(userId?.toString()),
    )
  },
  getUnreadConversations: (state, __, rootState) => {
    const currentUser = rootState.users[rootState.auth.user?.id]
    if (!currentUser || !state) {
      return []
    }
    const unreadConversations = currentUser.unreadConversations

    if (unreadConversations) {
      return unreadConversations.map((x) => {
        if (state[x]) {
          return state[x]
        }
        return undefined
      })
    } else {
      return []
    }
  },
}
