import {
  DELETE_OBJECTIVE_STEP,
  MERGE_OBJECTIVE,
  MERGE_OBJECTIVE_STEP,
  MERGE_PROJECT_OBJECTIVE,
  SET_OBJECTIVE_STEPS,
} from '@/constants/mutations-type'

export default {
  async fetchObjectiveForProject({ commit }, projectId) {
    const {
      data: { data: objective },
    } = await this.$api.objective.get(projectId)
    commit(MERGE_OBJECTIVE, objective)
    commit(
      `projects/${MERGE_PROJECT_OBJECTIVE}`,
      {
        projectId,
        objectiveId: objective.id,
      },
      { root: true },
    )
    return objective
  },
  async updateObjective({ commit }, objective) {
    await this.$api.objective.update(objective)
    commit(MERGE_OBJECTIVE, objective)
    commit(
      `projects/${MERGE_PROJECT_OBJECTIVE}`,
      {
        projectId: objective.project_id,
        objectiveId: objective.id,
      },
      { root: true },
    )
  },
  async fetchSteps({ commit }, objectiveId) {
    const {
      data: { data: steps },
    } = await this.$api.steps.get(objectiveId)
    commit(SET_OBJECTIVE_STEPS, { objectiveId, steps })
  },
  async createStep({ commit }, { objectiveId, step }) {
    commit(MERGE_OBJECTIVE_STEP, {
      objectiveId,
      step: {
        ...step,
        isCreating: true,
      },
    })
    const {
      data: { data: createdStep },
    } = await this.$api.steps.create(objectiveId, step)
    commit(MERGE_OBJECTIVE_STEP, { objectiveId, step: createdStep })
  },
  async deleteStep({ commit }, stepId) {
    await this.$api.steps.delete(stepId)
    commit(DELETE_OBJECTIVE_STEP, stepId)
  },
}
