import { ActionTree, GetterTree, MutationTree } from 'vuex/types/index'
import { RootState } from '..'
import { User } from '@/models/User'

export interface AuthState {
  user: User | null
  token: string | null
}

export const state = (): AuthState => ({
  user: null,
  token: null,
})

export const getters: GetterTree<AuthState, RootState> = {
  user: (state) => state.user,
  loggedIn: (state) => !!state.user?.email,
  token: (state) => state.token,
}

export const mutations: MutationTree<AuthState> = {
  SET_USER(state: AuthState, user: User) {
    state.user = user
  },
  SET_TOKEN(state: AuthState, token: string) {
    state.token = token
  },
}

export const actions: ActionTree<AuthState, RootState> = {
  async fetchUser({ commit }) {
    try {
      // @ts-ignore
      const { data: user } = await this.$axios.$get('/v1/users/me', {
        headers: {
          Authorization: `Bearer ${this.$cookies.get('auth._token.local')}`,
        },
      })

      commit('SET_USER', user)
    } catch (error) {
      this.$router.push('/logout')
    }
  },
  async login({ commit }, credentials: { email: string; password: string }) {
    const { data } = await this.$axios.$post('/login', {
      user: credentials,
    })

    this.$cookies.set('auth._token.local', data.jwt)
    commit('SET_TOKEN', data.jwt)
  },
  async logout({ commit }) {
    try {
      await this.$axios.delete('/logout')
      commit('SET_USER', null)
      commit('SET_TOKEN', null)
    } catch (error) {
      throw new Error(`[store/auth/logout] ${error}`)
    }
  },
}
