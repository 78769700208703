








import { Component, Vue, Getter } from 'nuxt-property-decorator'
import { GlobalModalData } from '@/constants/ModalTypes'

@Component({
  components: {
    AbsenceProofModal: () => import('@/components/global/modals/modalTypes/AbsenceProofModal.vue'),
    LegalCertificationModal: () =>
      import('@/components/global/modals/modalTypes/LegalCertificationModal.vue'),
    PersonalAgreementModal: () =>
      import('@/components/global/modals/modalTypes/PersonalAgreementModal.vue'),
    FirstCourseViewModal: () =>
      import('@/components/global/modals/modalTypes/FirstCourseViewModal.vue'),
    MeetingProposalModal: () =>
      import('@/components/global/modals/modalTypes/MeetingProposalModal.vue'),
    MeetingSalesModal: () =>
      import('@/components/global/modals/modalTypes/MeetingSalesModal/index.vue'),
    TrainingDiplomaMainModal: () =>
      import('@/components/global/modals/modalTypes/TrainingDiplomaMainModal.vue'),
    AttendanceCertificateModal: () =>
      import('@/components/global/modals/modalTypes/AttendanceCertificationModal.vue'),
  },
})
export default class GlobalModalManager extends Vue {
  @Getter('getGlobalModal') getGlobalModal!: GlobalModalData | undefined
}
