export enum UserRole {
  MENTOR = 'mentor',
  STUDENT = 'student',
}

export interface CalendarAccount {
  provider: string
  provider_id: string
  last_sync: string
  exception: string | null
  error: string | null
  error_at: string | null
}

export interface Calendar {
  id: string
  provider: string
  provider_id: string
  provider_sync: boolean
  name: string
  description: string | null
}

export interface UserStudent {
  accepted_tos_at?: unknown
  active_pause?: {
    start_at: string
    end_at: string
  }
  available_coaching_access?: {
    start_at: string
    end_at: string
  }
  coaching_status?: string
  project_id?: number
  should_refresh_introduction_form?: boolean
  status: string[]
  onboarding_required?: boolean
  training_course_id_to_onboard?: number
  training_course_id_waiting_for_mentor?: number
  training_course_to_onboard_start_date?: string
  current_training_course_id?: number
  has_community_profile?: boolean
}

export interface User {
  id: number | string
  name: string
  picture: string | null
  avatar?: string
  given_name: string
  family_name: string
  email?: string
  firebase_password?: string
  certifications?: number[]
  phone_number?: string
  role: UserRole
  feature_flags: string[]
  admin?: boolean
  coachings?: number[]
  conversations?: string[]
  is_currently_in_pause?: boolean
  student?: UserStudent
  mentor?: unknown
  training_courses?: number[]
  unreadConversations?: string[]
  created_at?: number
}

export interface UserFromFirebase extends User {
  id: string
}

export interface UserFromApi {
  id: number
  given_name: string
  family_name: string
  picture: string
  email: string
  birthday?: string
  gender: string
  address?: {
    line: string
    postal_code: string
    city: string
    country: string
  }
  phone_number?: string
  student?: UserStudent
}

export interface UserProfile {
  id: number
  given_name: string
  family_name: string
  picture: string
  email: string
  birthday?: string
  gender: string
  phone_number?: string
  address?: {
    line: string
    postal_code: string
    city: string
    country: string
  }
  tagline?: string
  bio?: string
  labels?: string[]
}

/* eslint-enable camelcase */
