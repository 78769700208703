import { Callback, Options, StrictOptions } from '.'

export class UserVisibility {
  private element: Document
  private callback: Callback
  private eventHandlerFunction: () => void

  private event = 'visibilitychange'

  private timeoutHandler?: ReturnType<typeof setTimeout>

  private options: StrictOptions

  private readonly defaultOptions: StrictOptions = {
    immediate: false,
    timeout: 5000,
    once: false,
  }

  private isVisible = false

  constructor(callback: Callback, options: Options) {
    this.callback = callback
    this.element = document

    this.options = {
      ...this.defaultOptions,
      ...options,
    }

    this.eventHandlerFunction = this.eventHandler.bind(this)

    if (this.options.immediate) {
      this.emitCallback()
    }

    this.start()
  }

  emitCallback() {
    this.isVisible = this.element.visibilityState === 'visible'
    this.callback({ isVisible: this.isVisible })

    if (this.options.once) {
      this.destroy()
    }
  }

  private eventHandler() {
    if (this.element.visibilityState === 'visible' && !this.isVisible) {
      this.clearTimeout()
      this.emitCallback()
    } else if (this.element.visibilityState === 'hidden') {
      this.initTimeout()
    }
  }

  private clearTimeout(): void {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler)
      this.timeoutHandler = undefined
    }
  }

  private initTimeout(): void {
    this.clearTimeout()

    this.timeoutHandler = setTimeout(this.emitCallback.bind(this), this.options.timeout)
  }

  start() {
    try {
      this.element.addEventListener(this.event, this.eventHandlerFunction)
    } catch (error) {
      throw new Error(`[UserVisibility](start) ${error}`)
    }
  }

  destroy() {
    try {
      this.element.removeEventListener(this.event, this.eventHandlerFunction)

      if (this.timeoutHandler) {
        clearTimeout(this.timeoutHandler)
      }
    } catch (error) {
      throw new Error(`[UserVisibility](destroy) ${error}`)
    }
  }
}
