












import { Component, Vue } from 'nuxt-property-decorator'
import { MkrNavItem, MkrNavItemGroup } from '@livementor/mikado_reborn/src/index-core'

import NavigationManager from '@/components/navigation/common/NavigationManager.vue'
import LogoStudent from '@/assets/img/logo/logo-student.svg'

import { mainEntries, profileEntries, communityEntries } from '@/constants/student-entries'

@Component({
  components: {
    LogoStudent,
    MkrNavItem,
    MkrNavItemGroup,
    NavigationManager,
  },
})
export default class StudentNavigation extends Vue {
  profileEntries = profileEntries
  communityEntries = communityEntries

  get mainEntries() {
    return this.filterEntriesByStudentStatus(mainEntries)
  }

  get hasCommunityFeature() {
    return this.$store.getters.hasAccessToCommunity
  }

  filterEntriesByStudentStatus(
    entries: { available?: string[]; featureFlag?: string; labelKey: string }[],
  ) {
    const student = this.$store.getters['auth/user'].student

    return entries.filter((entry) => {
      if (!entry.available) {
        return true
      }

      const hasAccess = entry.available?.some((availableStatus) =>
        student?.status.includes(availableStatus),
      )

      const hasFeatureFlag = entry.featureFlag
        ? this.$store.getters.userHasFeature(entry.featureFlag)
        : true

      // cas particulier ou on ouvre le chat à tout ceux qui ont la feature community
      if (entry.labelKey === 'chat') {
        return this.$store.getters.hasAccessToCommunity || hasAccess
      }

      return hasAccess && hasFeatureFlag
    })
  }
}
