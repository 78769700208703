import Vue from 'vue'
import uniq from 'lodash/uniq'
import merge from 'lodash/merge'

import { CREATE_PROPOSAL, DELETE_PROPOSAL, MERGE_CONVERSATIONS } from '@/constants/mutations-type'

function setMessage(state, payload) {
  const conversation = state[payload.conversation.id] || {}
  const msgs = uniq(
    payload.messages.map((m) => m.id).concat(conversation.messages ? conversation.messages : []),
  )
  Vue.set(state, payload.conversation.id, {
    displayTo: [],
    ...state[payload.conversation.id],
    messages: msgs,
  })
}

export default {
  [CREATE_PROPOSAL]: (state, payload) => {
    const { conversationId, proposal } = payload

    Vue.set(state[conversationId], 'coachingProposal', proposal)
  },
  [DELETE_PROPOSAL]: (state, conversationId) => {
    Vue.delete(state[conversationId], 'coachingProposal')
  },
  [MERGE_CONVERSATIONS]: (state, { conversations }) => {
    const conversationsById = {}

    for (const conversation of conversations) {
      if (conversation && conversation.id) {
        conversationsById[conversation.id] = conversation
      }
    }

    for (const [key, conversation] of Object.entries(conversationsById)) {
      Vue.set(state, key, merge(state[key], conversation))
    }
  },
  MERGE_MESSAGES: (state, payload) => {
    setMessage(state, payload)
  },
}
