export const strict = true

export default () => {
  return {
    currentScreenView: [],
    currentScreenName: {
      main: '',
      childName: '',
    },
    backActionRoute: '',
    currentUserId: '',
    displayMenu: false,
    displayUserDetails: false,
    error: {},
    globalModal: null,
    isMobile: false,
    locales: ['en', 'fr'],
    locale: 'fr',
    selectedConversation: undefined,
    shouldLogout: false,
    freeCourseLayoutTitle: undefined,
    runHostUrl: undefined,
    allConversationsAreLoaded: false,
  }
}
