import { MERGE_PROJECT, MERGE_USER_PROJECT } from '@/constants/mutations-type'

export default {
  async fetchProjectForUser({ commit }, userId) {
    const {
      data: { data: project },
    } = await this.$api.users.project(userId)

    commit(MERGE_PROJECT, project)
    commit(
      `users/${MERGE_USER_PROJECT}`,
      {
        userId,
        projectId: project.project_id,
      },
      { root: true },
    )
    return project
  },
}
