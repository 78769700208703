import { GetterTree, ActionTree, MutationTree } from 'vuex'
import maplibregl from 'maplibre-gl'
import { RootState } from '..'
import { Pagination } from '@/models/Pagination'
import { CommunityCity, ProfileFilters, ProfilePreview, ProfileShow } from '@/models/Community'

export interface CommunityState {
  profiles: ProfilePreview[]
  pagination: Pagination
  filters: ProfileFilters
  totalItems: number
  profile: ProfilePreview | null
  // profil de l'utilisateur authentifié
  currentUserProfilePreview: ProfilePreview | null
  currentUserProfile: ProfileShow | null
  isFetching: boolean
  cachedCities: CommunityCity[]
  mapMarkers: Map<string, maplibregl.Marker>
}

export const state: () => CommunityState = () => ({
  profiles: [],
  pagination: {
    current_page: null,
    first_page: null,
    last_page: null,
    next_page: null,
    prev_page: null,
  },
  filters: {},
  totalItems: 0,
  profile: null,
  currentUserProfile: null,
  currentUserProfilePreview: null,
  isFetching: true,
  cachedCities: [],
  mapMarkers: new Map<string, maplibregl.Marker>(),
})

export const getters: GetterTree<CommunityState, RootState> = {}

export const mutations: MutationTree<CommunityState> = {
  setProfiles(state, profiles: ProfilePreview[]) {
    state.profiles = profiles
  },
  setProfile(state, profile: ProfilePreview) {
    state.profile = profile
  },
  setPagination(state, pagination: Pagination) {
    state.pagination = pagination
  },
  setFilters(state, filters: ProfileFilters) {
    state.filters = filters
  },
  setTotalItems(state, totalItems: number) {
    state.totalItems = totalItems
  },
  setCurrentUserProfile(state, profile: ProfileShow) {
    state.currentUserProfile = profile
  },
  setCurrentUserProfilePreview(state, profile: ProfilePreview) {
    state.currentUserProfilePreview = profile
  },
  setIsFetching(state, isFetching: boolean) {
    state.isFetching = isFetching
  },
  setCachedCities(state, cities: CommunityCity[]) {
    // insérer les villes qui n'existent pas dans le cache
    state.cachedCities = [
      ...state.cachedCities,
      ...(cities || []).filter(
        (city) => !state.cachedCities.find((cachedCity) => cachedCity.city.id === city.city.id),
      ),
    ]
  },
  addMapMarker(state, { id, marker }) {
    state.mapMarkers.set(id, marker)
  },
  clearCache(state) {
    state.cachedCities = []
    state.mapMarkers.clear()
  },
}

export const actions: ActionTree<CommunityState, RootState> = {
  async fetchProfiles({ commit }, query = {}) {
    try {
      commit('setIsFetching', true)
      const response = await this.$api.community.getProfiles({
        limit_offset: 36,
        page: 1,
        ...query,
      })

      commit('setTotalItems', response.data.profiles.total_items)
      commit('setProfiles', response.data.profiles.items)
      commit('setCachedCities', response.data.profiles_by_city)
      commit('setCurrentUserProfile', response.data.user.profile)
      commit('setPagination', response.data.profiles.pagination)
      commit('setFilters', response.filters)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    } finally {
      commit('setIsFetching', false)
    }
  },
  async updateProfile({ commit }, { payload, userId }) {
    const profile = await this.$api.community.updateProfile(userId, payload)

    commit('setCurrentUserProfile', profile)
  },
  async updateProfilePicture({ commit }, { avatar, userId }: { avatar: File; userId: number }) {
    const formData = new FormData()
    formData.append('avatar', avatar)
    const response = await this.$api.community.updateProfilePicture(userId, formData)

    commit('setCurrentUserProfile', response.data.data as ProfileShow)
  },
  async fetchProfile({ commit }, id: number) {
    const profile = await this.$api.community.getProfile(id)

    commit('setProfile', profile)
  },
  async fetchCurrentUserProfile({ commit }) {
    const profile: ProfileShow = await this.$api.community.getCurrentProfile()

    commit('setCurrentUserProfile', profile)
  },
}
