import { Plugin } from '@nuxt/types'
import dayjs, { extend, locale } from 'dayjs'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import relativeTime from 'dayjs/plugin/relativeTime'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import calendar from 'dayjs/plugin/calendar'
import updateLocale from 'dayjs/plugin/updateLocale'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin

extend(calendar)
extend(duration)
extend(customParseFormat)
extend(isSameOrAfter)
extend(relativeTime)
extend(utc)
extend(timezone)
extend(isBetween)
extend(localizedFormat)
extend(updateLocale)

dayjs.updateLocale('fr', {
  calendar: {
    lastDay: '[Hier à] • HH:mm',
    sameDay: "[Aujourd'hui à] • HH:mm",
    lastWeek: 'DD MMMM YYYY • HH:mm',
    sameElse: 'DD MMMM YYYY • HH:mm',
  },
})

locale('fr')

const dayjsPlugin: Plugin = ({ store }, inject) => {
  locale(store.state.locale || 'fr')
  inject('dayjs', dayjs)
}

export { dayjs }
export default dayjsPlugin
