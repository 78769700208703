













import { Component, Vue } from 'nuxt-property-decorator'
import { MkrApp } from '@livementor/mikado_reborn/src/index-core'
import NotificationsModule from '@/plugins/notifications/NotificationsModule.vue'

@Component({
  components: {
    MkrApp,
    NotificationsModule,
  },
})
export default class LoginLayout extends Vue {
  auth = false
}
