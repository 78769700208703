import Vue from 'vue'

import uniq from 'lodash/uniq'
import groupBy from 'lodash/groupBy'
import union from 'lodash/union'

import {
  MARK_CONVERSATION_AS_READ,
  MARK_CONVERSATION_AS_UNREAD,
  MERGE_COACHINGS,
  MERGE_TRAINING_COURSES,
  MERGE_USERS,
  MERGE_USER_PROJECT,
  MERGE_CERTIFICATIONS,
  SET_USER_WAITING_SIGNATURE_DOCUMENTS,
} from '@/constants/mutations-type'

export default {
  [MARK_CONVERSATION_AS_READ]: (state, payload) => {
    const { conversationId, userId } = payload
    if (!state[userId].unreadConversations) {
      return
    }
    Vue.delete(
      state[userId].unreadConversations,
      state[userId].unreadConversations.indexOf(conversationId),
    )
  },
  [MARK_CONVERSATION_AS_UNREAD]: (state, payload) => {
    const { conversationId, userId } = payload
    state[userId].unreadConversations.push(conversationId)
  },
  [MERGE_COACHINGS]: (state, payload) => {
    const coachingsId = payload.coachings.map((coaching) => coaching.id)

    state[payload.studentId] = state[payload.studentId] || {}

    const coachings = state[payload.studentId].coachings || []

    Vue.set(state[payload.studentId], 'coachings', uniq([...coachings, ...coachingsId]))
  },
  [MERGE_TRAINING_COURSES]: (state, payload) => {
    const trainingCourses = payload.trainingCourses.map((x) => x.id)
    Vue.set(state, payload.studentId, {
      ...state[payload.studentId],
      training_courses: trainingCourses,
    })
  },
  [MERGE_USERS]: (state, payload) => {
    if (Array.isArray(payload)) {
      payload.forEach((user) => {
        Vue.set(state, user.id, {
          ...state[user.id],
          ...user,
        })
      })
    } else {
      Vue.set(state, payload.id, {
        ...state[payload.id],
        ...payload,
      })
    }
  },

  [MERGE_CERTIFICATIONS]: (state, payload) => {
    const certsByStudent = groupBy(payload, 'user_id')

    for (const userId of Object.keys(certsByStudent)) {
      if (!state[userId]) {
        state[userId] = {
          ...state[userId],
          certifications: [],
        }
      }

      Vue.set(
        state[userId],
        'certifications',
        union(
          state[userId].certifications,
          certsByStudent[userId].map((c) => c.id),
        ),
      )
    }
  },

  [MERGE_USER_PROJECT]: (state, { userId, projectId }) => {
    Vue.set(state, userId, {
      ...state[userId],
      project: projectId,
    })
  },

  [SET_USER_WAITING_SIGNATURE_DOCUMENTS]: (state, { userId, documents }) => {
    Vue.set(state, userId, {
      ...state[userId],
      waitingSignatureDocuments: documents,
    })
  },
}
