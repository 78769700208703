export default {
  getCurrentUser: (__, getters, rootState) => {
    if (!rootState.auth.user) {
      return undefined
    }

    return { ...getters.getUserById(rootState.auth.user.id), ...rootState.auth.user }
  },
  getUserById: (state) => (userId) => {
    return state[parseInt(userId)]
  },
  getCurrentUserId: (__, getters) => {
    return getters.getCurrentUser?.id ? getters.getCurrentUser.id.toString() : ''
  },
  getUsersByName: (state) => (userName) => {
    const users = Object.values(state)
    return users.filter((user) =>
      user.name && userName ? user.name.toLowerCase().includes(userName.toLowerCase()) : null,
    )
  },
  currentUserIsMentor: (__, _, rootState) => {
    return rootState.auth.user?.role === 'mentor'
  },
  currentUserProposals: (__, _, rootState) => {
    return Object.values(rootState.conversations)
      .map(({ coachingProposal, id }) =>
        coachingProposal ? { conversationId: id, ...coachingProposal } : undefined,
      )
      .filter((proposal) => proposal)
  },
  /**
   * @param {Record<string, import('@/models/User').User[]>} state - users
   * @returns {import('@/models/User').User[]} data
   */
  getUsersOfConversation: (state, _, rootState) => (conversationId) => {
    /** @type {import('@/models/Conversation').Conversation} */
    const conversation = rootState.conversations[conversationId]

    const conversationUsers = conversation?.participants.map(
      (participantId) => state[participantId],
    )

    return conversationUsers
  },
}
