import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '..'
import { Enrollment } from '@/models/WebinarAlgolia'

export type WebinarEnrollmentState = {
  enrollments: Enrollment[]
}

export const state = (): WebinarEnrollmentState => ({
  enrollments: [],
})

export const getters: GetterTree<WebinarEnrollmentState, RootState> = {
  getEnrollment: (state) => (webinarId: number) => {
    return state.enrollments.find(
      (enrollment: Enrollment) => enrollment.enrollable_id === webinarId,
    )
  },
}

export const mutations: MutationTree<WebinarEnrollmentState> = {
  PUSH_ENROLLMENT(state, enrollment: Enrollment) {
    // if enrollment already exists, replace it
    const index = state.enrollments.findIndex(
      (e: Enrollment) => e.enrollable_id === enrollment.enrollable_id,
    )

    if (index !== -1) {
      state.enrollments.splice(index, 1, enrollment)
    } else {
      state.enrollments.push(enrollment)
    }
  },
  REMOVE_ENROLLMENT(state, enrollmentId: number) {
    const index = state.enrollments.findIndex((e: Enrollment) => e.id === enrollmentId)

    if (index !== -1) {
      state.enrollments.splice(index, 1)
    }
  },
}

export const actions: ActionTree<WebinarEnrollmentState, RootState> = {
  async createEnrollment({ commit, dispatch }, webinarId) {
    // @ts-ignore
    const enrollment = await this.$api.webinar.enroll(webinarId)
    await dispatch('webinar/fetchWebinar', webinarId, { root: true })

    commit('PUSH_ENROLLMENT', enrollment)
  },
  async deleteEnrollment({ commit, dispatch }, { enrollmentId, webinarId }) {
    // @ts-ignore
    await this.$api.webinar.unsubscribe(enrollmentId)
    await dispatch('webinar/fetchWebinar', webinarId, { root: true })

    commit('REMOVE_ENROLLMENT', enrollmentId)
  },
  async fetchEnrollment({ commit, rootGetters }, webinarId) {
    // @ts-ignore
    const pagination = await this.$api.webinar.getUserEnrollment(webinarId)
    const user = rootGetters['auth/user']

    const userEnrollment = pagination.data.find((enrollment: Enrollment) => {
      return enrollment.student_id === user.id
    })

    if (userEnrollment) {
      commit('PUSH_ENROLLMENT', userEnrollment)
    }
  },
}
