import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '..'

export interface Batch {
  start_date: string // '2023-04-09',
  end_date: string // '2023-07-09',
  status: 'current' | 'future' | 'past'
  id: number
}
export interface Edition {
  id: number
  title: string
  batches: Array<Batch>
}

export interface EditionState {
  items: Edition[]
}

export const state = (): EditionState => ({
  items: [],
})

export const getters: GetterTree<EditionState, RootState> = {
  items: (state) => state.items,
  getEditionById: (state) => (id: number) => state.items.find((e) => e.id === id),
}

export const mutations: MutationTree<EditionState> = {
  setItems(state, items: Edition[]) {
    state.items = items
  },
}

export const actions: ActionTree<EditionState, RootState> = {
  async fetchEditions({ commit }) {
    // @ts-ignore
    const { data } = await this.$api.edition.get()
    commit('setItems', data.data)
  },
}
