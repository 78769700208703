import { NavigationSocialNetworkItem } from '@/types/navigation-items'

export const socialNetWorkEntries: NavigationSocialNetworkItem[] = [
  {
    label: 'Facebook',
    icon: 'social-facebook',
    url: 'https://www.facebook.com/livementor.fr',
    external: true,
    tracking: 'LiveMentor Facebook Page',
  },
  {
    label: 'Twitter',
    icon: 'social-twitter',
    url: 'https://twitter.com/_livementor',
    external: true,
    tracking: 'LiveMentor Twitter Page',
  },
  {
    label: 'Instagram',
    icon: 'social-instagram',
    url: 'https://www.instagram.com/_livementor',
    external: true,
    tracking: 'LiveMentor Instagram Page',
  },
]
