import { NavigationMainItem } from '@/types/navigation-items'

export const mainEntries: NavigationMainItem[] = [
  {
    labelKey: 'chat',
    icon: 'mail',
    url: '/chat',
    badged: true,
  },
  {
    labelKey: 'planning',
    icon: 'calendar',
    url: '/planning',
  },
  {
    labelKey: 'directory',
    icon: 'directory',
    url: '/student-directory',
  },
  {
    labelKey: 'dashboard',
    icon: 'dashboard',
    url: '/dashboard',
  },
]
