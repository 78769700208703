






import { Stars } from '@/assets/icons'

export default {
  components: {
    Stars,
  },
}
