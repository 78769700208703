






/**
 * How to handle translation :
 * The props "name" should be the key of form_fields.[name] in the translation file
 * You can use {_field_} & {_value_} inside the errors translation string if needed
 */

import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ValidationProvider as VeeValidateValidationProvider } from 'vee-validate'

@Component({
  components: {
    VeeValidateValidationProvider,
  },
})
export default class ValidationProvider extends Vue {
  @Prop({ required: true })
  name!: string

  @Prop({ required: true })
  mode!: string
}
