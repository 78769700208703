import axios, { AxiosInstance } from 'axios'
import { Context } from '@nuxt/types'
import dayjs, { ManipulateType } from 'dayjs'
import { TimekitAvailability } from '@/components/global/MeetingProposal/types'
import { DaySlot } from '@/components/global/Calendar/types'

export class LmTimekit {
  private readonly axiosInstance: AxiosInstance

  defaultSpan = 21
  defaultPeriod: ManipulateType = 'days'
  defaultTimeZone = 'Europe/Paris'

  constructor(private readonly config: Context['$config']) {
    this.axiosInstance = axios.create({
      baseURL: 'https://api.timekit.io/v2/',
      auth: { username: '', password: this.config.TIMEKIT_API_KEY },
      headers: { 'Content-Type': 'application/json' },
    })
  }

  get instance() {
    return this
  }

  async getAvailability(
    timekitResourceId: string,
    allow_period = {
      start: dayjs().format(),
      end: dayjs().add(this.defaultSpan, this.defaultPeriod).format(),
    },
  ) {
    const params = {
      project_id: this.config.TIMEKIT_ONE_ON_ONE_PROJECT_KEY,
      resources: [timekitResourceId],
      output_timezone: this.defaultTimeZone,
      constraints: [
        {
          allow_period,
        },
      ],
    }
    const {
      data: { data },
    } = await this.axiosInstance.post('availability', params)

    return data
  }

  async validateAvailability(
    timekitResourceId: string,
    slots: DaySlot[],
  ): Promise<TimekitAvailability[]> {
    const params = {
      project_id: this.config.TIMEKIT_ONE_ON_ONE_PROJECT_KEY,
      resources: [timekitResourceId],
      output_timezone: this.defaultTimeZone,
      constraints: slots.map((slot) => {
        return { between_timestamps: { start: slot.start, end: slot.end } }
      }),
    }

    const {
      data: { data },
    } = await this.axiosInstance.post('availability', params)

    return data
  }
}
