export default {
  certifications: (state) => Object.values(state),
  getCertificationById: (state) => (certificationId) => {
    return state[certificationId]
  },
  getAssessmentsForCertificationId:
    (state, _getters, _rootState, rootGetters) => (certificationId) => {
      return (state[certificationId].assessments || [])
        .map((assessmentId) => rootGetters['assessments/getAssessmentById'](assessmentId))
        .filter((assessment) => assessment)
    },
  getExercisesForCertificationId:
    (_state, getters) =>
    (certificationId, mandatoryOnly = false) => {
      return getters
        .getAssessmentsForCertificationId(certificationId)
        .map((assessment) => assessment.skill.exercise)
        .filter((exercise) => exercise.mandatory || !mandatoryOnly)
    },
  getUploadedExercisesForCertificationId: (_state, getters) => (certificationId) => {
    return getters
      .getExercisesForCertificationId(certificationId, true)
      .filter((exercise) => exercise.files.length > 0)
  },
}
