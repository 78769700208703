import { Context } from '@nuxt/types'
import { DaySlot } from '@/components/global/Calendar/types'
import { LmTimekit } from '@/services/lm-timekit'
import { defineNuxtPlugin } from '@/types/nuxt-helpers'

export class TimekitPlugin {
  /* eslint-disable no-useless-constructor */
  constructor(
    private readonly store: Context['store'],
    private readonly lmTimekitInstance: LmTimekit,
  ) {}
  /* eslint-enable no-useless-constructor */

  public getMentorAvailability(allowPeriod: { start: string; end: string }) {
    if (this.store.getters['users/getCurrentUser']?.mentor?.timekit_id) {
      return this.lmTimekitInstance.getAvailability(
        this.store.getters['users/getCurrentUser'].mentor.timekit_id,
        allowPeriod,
      )
    }

    throw new Error('Timekit install')
  }

  public validate(slots: DaySlot[], timekitId?: string) {
    if (timekitId || this.store.getters['users/getCurrentUser']?.mentor?.timekit_id) {
      return this.lmTimekitInstance.validateAvailability(
        timekitId || this.store.getters['users/getCurrentUser'].mentor.timekit_id,
        slots,
      )
    }

    throw new Error('Timekit validate')
  }
}

export default defineNuxtPlugin(({ store, $config }, inject) => {
  const lmTimekitInstance = new LmTimekit($config)

  inject('timekit', new TimekitPlugin(store, lmTimekitInstance))
})
