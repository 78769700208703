import Vue from 'vue'
import {
  ADD_OBJECTIVE_NOTE,
  ADD_PENDING_NOTE,
  DELETE_OBJECTIVE_STEP,
  MERGE_OBJECTIVE_STEP,
  MERGE_OBJECTIVE,
  REMOVE_NOTE,
  REMOVE_PENDING_NOTE,
  SET_OBJECTIVE_STEPS,
  SET_OBJECTIVE_NOTES,
} from '@/constants/mutations-type'

export default {
  [MERGE_OBJECTIVE]: (state, objective) => {
    Vue.set(state, objective.id, {
      ...state[objective.id],
      ...objective,
    })
  },
  [SET_OBJECTIVE_STEPS]: (state, { objectiveId, steps }) => {
    Vue.set(state, objectiveId, {
      ...state[objectiveId],
      steps: steps.map((step) => step.id),
    })
  },
  [MERGE_OBJECTIVE_STEP]: (state, { objectiveId, step }) => {
    // Push to array if step doesn't exist or update position in array if position differs from index
    const steps = [...state[objectiveId].steps]
    const stepIndex = steps.findIndex((stateStep) => stateStep.id === step.id)
    if (stepIndex === -1) {
      steps.push(step.id)
    } else if (step.position !== stepIndex) {
      steps.splice(stepIndex, 1)
      steps.splice(step.position, 0, step.id)
    }
    Vue.set(state, objectiveId, {
      ...state[objectiveId],
      steps,
    })
  },
  [DELETE_OBJECTIVE_STEP]: (state, { objectiveId, stepId }) => {
    const objective = state[objectiveId]
    if (objective && objective.steps.length) {
      objective.steps = objective.steps.filter((storeStep) => storeStep !== stepId)
    }
  },
  [SET_OBJECTIVE_NOTES]: (state, { objectiveId, notes }) => {
    Vue.set(state, objectiveId, {
      ...state[objectiveId],
      notes: notes.map((note) => note.id),
    })
  },
  [ADD_OBJECTIVE_NOTE]: (state, { objectiveId, noteId }) => {
    if (state[objectiveId]) {
      Vue.set(state[objectiveId], 'notes', [...state[objectiveId].notes, noteId])
    } else {
      Vue.set(state, objectiveId, {
        notes: [noteId],
      })
    }
  },
  [ADD_PENDING_NOTE]: (state, { objectiveId, note }) => {
    Vue.set(state, objectiveId, {
      ...state[objectiveId],
      pendingNote: note,
    })
  },
  [REMOVE_PENDING_NOTE]: (state, objectiveId) => {
    Vue.delete(state[objectiveId], 'pendingNote')
  },
  [REMOVE_NOTE]: (state, { objectiveId, noteId }) => {
    const objective = state[objectiveId]
    if (objective && objective.notes.length) {
      objective.notes = objective.notes.filter((storeNoteId) => storeNoteId !== noteId)
    }
  },
}
