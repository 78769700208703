import Vue from 'vue'
import VueI18n from 'vue-i18n'
import dayjs from 'dayjs'
import { configure, extend } from 'vee-validate'
import { required, email, length, confirmed, min, image } from 'vee-validate/dist/rules'
import customRules from './validationRules'
import ValidationProvider from '@/components/global/mkr/ValidationProvider.vue'
import { defineNuxtPlugin } from '@/types/nuxt-helpers'

Vue.component('ValidationProvider', ValidationProvider)

export default defineNuxtPlugin(({ app }) => {
  const i18n = app.i18n as VueI18n

  extend('required', required)
  extend('email', email)
  extend('length', length)
  extend('min', min)
  extend('min_password', min)
  extend('confirmed', confirmed)
  extend('image', image)

  // custom rules
  Object.entries(customRules).forEach(([key, value]) => {
    extend(key, value)
  })

  configure({
    defaultMessage: (field, values) => {
      // override the field name.
      const snakeCaseField = field.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
      values._field_ = i18n.t(`form_fields.${snakeCaseField}.label`)
      values.startDate = dayjs(values.startDate).format('DD MMMM YYYY')

      return i18n.t(`errors.${values._rule_}`, values).toString()
    },
  })
})
