export default {
  actions: (state) => {
    return Object.values(state.actions)
  },
  actionsOrderedByDate: (state) => {
    return Object.values(state.actions).sort(
      (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    )
  },
  actionsByKind: (state) => (kinds) => {
    const actions = []

    for (const kind of kinds) {
      const actionsByKind = Object.values(state.actions).filter((action) => action.kind === kind)

      for (const action of actionsByKind) {
        actions.push(action)
      }
    }

    return actions.sort(
      (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    )
  },
}
