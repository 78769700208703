import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '..'
import { LMWebinar } from '@/models/WebinarAlgolia'
import { Pagination } from '@/models/Pagination'
import { FeedBack } from '@/models/Feedback'
import { DateCategory } from '@/constants/webinar'

export interface WebinarQuery {
  status?: string
  search?: string
  category?: string
  mentor_name?: string
  limit_offset?: string
  page?: string
  bookmarked?: string
}

export type WebinarState = {
  items: LMWebinar[]
  totalItems: number | null
  pagination: null | Pagination
  filters: {
    category: string[]
    mentor_name: string[]
    status: string[]
  }
  query: WebinarQuery
  feedback: FeedBack | null
  isLoading: boolean
}

export const state = (): WebinarState => ({
  items: [],
  totalItems: null,
  pagination: null,
  filters: {
    category: [],
    mentor_name: [],
    status: [],
  },
  query: {},
  feedback: null,
  isLoading: false,
})

export const getters: GetterTree<WebinarState, RootState> = {
  items: (state) => state.items,
  totalItems: (state) => state.totalItems,
  todayWebinars: (state) => state.items.filter((w) => w.status === DateCategory.TODAY),
  upcomingWebinars: (state) =>
    state.items
      .filter((w) => w.status === DateCategory.UPCOMING)
      .sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime()),
  replayWebinars: (state) => state.items.filter((w) => w.status === DateCategory.REPLAY),
  pagination: (state) => state.pagination,
  filters: (state) => state.filters,
  query: (state) => state.query,
  webinar: (state) => (id: number) => state.items.find((w) => w.id === id),
  feedback: (state) => state.feedback,
  isLoading: (state) => state.isLoading,
  filtersToShow: (state) =>
    Object.entries(state.query).filter(
      ([key, value]) => value && !['offset_limit', 'page'].includes(key),
    ),
}

export const mutations: MutationTree<WebinarState> = {
  SET_IS_LOADING(state, value: boolean) {
    state.isLoading = value
  },
  SET_ITEMS(state, items: LMWebinar[]) {
    state.items = items
  },
  SET_TOTAL_ITEMS(state, totalItems) {
    state.totalItems = totalItems
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination
  },
  SET_FILTERS(state, filters) {
    state.filters = filters
  },
  SET_QUERY(state, query: Partial<WebinarQuery>) {
    state.query = {
      ...state.query,
      ...query,
    }
  },
  RESET_QUERY(state) {
    state.query = {}
  },
  TOGGLE_FILTER(
    state,
    { filter, value }: { filter: keyof WebinarState['filters']; value: string },
  ) {
    const filterValue = state.query[filter]
    // filterValue as the format of 'value1,value2,value3'
    // if the value is already in the filterValue, remove it
    // otherwise, add it
    if (filterValue?.includes(value)) {
      state.query[filter] = filterValue
        .split(',')
        .filter((v) => v !== value)
        .join(',')
    } else {
      state.query[filter] = filterValue ? `${filterValue},${value}` : value
    }

    // if the filterValue is empty, remove the filter from the query
    if (!state.query[filter]) {
      delete state.query[filter]
    }
  },
  SET_FEEDBACK(state, feedback: FeedBack | null) {
    state.feedback = feedback
  },
  PUSH_ITEM(state, item: LMWebinar) {
    // if the item is already in the list, replace it
    const index = state.items.findIndex((w) => w.id === item.id)
    if (index !== -1) {
      state.items.splice(index, 1, item)
    } else {
      state.items.push(item)
    }
  },
}

export const actions: ActionTree<WebinarState, RootState> = {
  async fetchWebinars({ commit, state }) {
    // @ts-ignore
    const { data, total_items, filters, pagination } = await this.$api.webinar.getPaginated(
      state.query,
    )

    commit('SET_ITEMS', data)
    commit('SET_TOTAL_ITEMS', total_items)
    commit('SET_PAGINATION', pagination)
    commit('SET_FILTERS', filters)
  },
  setQuery({ commit }, query: Partial<WebinarQuery>) {
    commit('SET_QUERY', {
      ...query,
      page: query.page || '1',
    })
  },
  toggleFilter({ commit }, { filter, value }) {
    commit('TOGGLE_FILTER', { filter, value })
    commit('SET_QUERY', {
      page: '1',
    })
  },
  resetQuery({ commit }) {
    commit('RESET_QUERY')
  },
  async fetchFeedback({ commit }, webinarId: string) {
    // @ts-ignore
    const { data: feedback } = await this.$api.feedback.get(webinarId, 'webinar')
    commit('SET_FEEDBACK', feedback.data[0])
  },
  async sendFeedback({ commit }, payload: FeedBack) {
    const feedback = await this.$api.feedback.send(payload)
    commit('SET_FEEDBACK', feedback.data.data)
  },
  async fetchWebinar({ commit }, webinarId: string) {
    // @ts-ignore
    const { data } = await this.$api.webinar.getById(webinarId)

    commit('PUSH_ITEM', data.data)
  },
}
