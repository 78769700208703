






















import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class Testimonial extends Vue {
  @Prop({ type: Array, required: true })
  images!: string[]

  @Prop({ type: String, required: true })
  title!: string

  @Prop({ type: String, required: true })
  description!: string
}
