import {
  SET_ABSENCES_LOADING,
  SET_ABSENCES_PAST,
  SET_ABSENCES_FUTURE,
  SET_ABSENCES_PRESENT,
  DELETE_ABSENCE_FUTURE,
  ADD_ABSENCE_FUTURE,
} from '@/constants/mutations-type'

export default {
  async GET_ABSENCES({ commit }) {
    commit(SET_ABSENCES_LOADING, true)
    const [past, present, future] = await Promise.all([
      await this.$api.absence.get({ timeframes: 'past' }),
      await this.$api.absence.get({ timeframes: 'present' }),
      await this.$api.absence.get({ timeframes: 'future' }),
    ])

    commit(SET_ABSENCES_PAST, past.data.data)
    commit(SET_ABSENCES_PRESENT, present.data.data)
    commit(SET_ABSENCES_FUTURE, future.data.data)

    commit(SET_ABSENCES_LOADING, false)
  },

  async CREATE_ABSENCE({ commit }, newAbsence) {
    const { data } = await this.$api.absence.add(newAbsence)
    commit(ADD_ABSENCE_FUTURE, data.data)
  },

  async DELETE_ABSENCE({ commit }, absenceToDelete) {
    await this.$api.absence.delete({ id: absenceToDelete.id })
    commit(DELETE_ABSENCE_FUTURE, absenceToDelete)
  },
}
