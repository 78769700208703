export enum FileUploadErrorTypes {
  DEFAULT,
  FILE_TOO_LARGE,
  FILE_FORMAT_NOT_ALLOWED,
}

export class FileUploadError extends Error {
  uploadErrorType: FileUploadErrorTypes

  constructor(uploadErrorType = FileUploadErrorTypes.DEFAULT, ...params: any[]) {
    super(...params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FileUploadError)
    }
    this.name = 'FileUploadError'
    this.uploadErrorType = uploadErrorType
  }
}
