import Vue from 'vue'
import { DELETE_BOOKMARK, SET_BOOKMARKS } from '@/constants/mutations-type'

export default {
  [SET_BOOKMARKS]: (state, bookmarks) => {
    bookmarks.forEach((bookmark) => {
      Vue.set(state, bookmark.id, bookmark)
    })
  },
  [DELETE_BOOKMARK]: (state, bookmarkId) => {
    Vue.delete(state, bookmarkId)
  },
}
