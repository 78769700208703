import { NavigationSubItem } from '@/types/navigation-items'

export const subEntries: NavigationSubItem[] = [
  {
    labelKey: 'webinar',
    icon: 'tribe',
    url: '/webinar',
  },
  {
    labelKey: 'courses',
    icon: 'book-open',
    url: '/courses',
  },
]
