import { ActionTree } from 'vuex/types/index'
import { SyllabusRootState } from './state'
import { Syllabus } from '@/models/Syllabus'

export type SyllabusActions = {
  setSyllabus(action: string, syllabus: Syllabus): void
}

const actions: ActionTree<SyllabusRootState, SyllabusRootState> = {
  setSyllabus({ commit, getters }, syllabus: Syllabus) {
    const existingSyllabus = getters.getSyllabusByMasterclassId(syllabus.masterclass.id)

    if (existingSyllabus) {
      commit('UPDATE_SYLLABUS', syllabus)
    } else {
      commit('SET_SYLLABUS', syllabus)
    }
  },
}

export default actions
