import keyBy from 'lodash/keyBy'
import Vue from 'vue'
import {
  MERGE_CERTIFICATION_ASSESSMENTS,
  MERGE_CERTIFICATIONS,
  MERGE_COACHINGS,
} from '@/constants/mutations-type'
import { CoachingStatus } from '@/models/Coaching'

export default {
  [MERGE_CERTIFICATIONS]: (state, payload) => {
    const conv = keyBy(payload, (x) => x.id)
    Object.keys(conv).forEach((cId) => {
      Vue.set(state, cId, { ...state[cId], ...conv[cId] })
    })
  },
  [MERGE_COACHINGS]: (state, { coachings, certification }) => {
    if (certification) {
      coachings.forEach((coaching) => {
        if (coaching.status !== CoachingStatus.CANCELLED && state[certification]) {
          state[certification].coaching_id = coaching.id
        }
      })
    }
  },
  [MERGE_CERTIFICATION_ASSESSMENTS]: (state, { certificationId, assessments }) => {
    Vue.set(state, certificationId, {
      ...state[certificationId],
      assessments: assessments.map((assessment) => assessment.id),
    })
  },
}
