import { User } from '@/models/User'
import { defineNuxtPlugin } from '@/types/nuxt-helpers'

/*
AUTH PLUGIN

Ce plugin permet de gérer l'authentification de l'utilisateur.
L'iniatialisation de ce plugin est déclenché côté client et côté serveur, avant le rendu de la page.

Dans l'ordre d'execution :
- On récupère le token de l'utilisateur dans les cookies
- On le set dans le store
- On récupère l'utilisateur

Si l'utilisateur est connecté, on le connecte à Firebase
Si l'utilisateur n'est pas connecté, le middleware de route va rediriger vers la page de login
*/

export type auth = {
  login: (credentials: { email: string; password: string }) => Promise<void>
  loginWithGoogle: () => Promise<void>
  setUserToken: (token: string) => Promise<void>
  isLoggedIn: () => boolean
  getToken: () => string | null
  setUser: (user: User | null) => void
  fetchUser: () => Promise<void>
  logout: () => Promise<void>
}

export default defineNuxtPlugin(
  async ({ store, $cookies, $axios, redirect, route, $firebase, $segment }, inject) => {
    try {
      const fetchUser = async () => {
        await store.dispatch('auth/fetchUser')
      }

      const loginWithGoogle = async () => {
        const query = route.fullPath.split('?')[1]
        const redirect_uri = window.location.origin + '/callback'

        const { jwt } = await $axios.$post(
          '/identities/google',
          query + `&redirect_uri=${redirect_uri}`,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        )

        $cookies.set('auth._token.local', jwt)
        await setUserToken(jwt)

        await fetchAppData()
      }

      const firebaseLogin = () => {
        if (process.client) {
          const user: User | undefined = store.getters['auth/user']

          if (!user?.firebase_password) {
            store.commit('setAllConversationsAreLoaded', true)
            return
          }

          $firebase.login(user)
        }
      }

      const segmentIdentify = () => {
        try {
          if ($segment) {
            const { given_name, family_name, id } = store.getters['auth/user'] as User

            $segment.identify(id.toString(), {
              name: `${given_name} ${family_name}`,
            })
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error)
        }
      }

      const fetchAppData = async () => {
        try {
          const user: User = store.getters['auth/user']
          const hasCommunityAccess = store.getters.hasAccessToCommunity
          if (hasCommunityAccess && user.student?.has_community_profile) {
            await store.dispatch('chat/conversations/fetchConversations')
          }
          await store.dispatch('trainingCourses/GET_TRAINING_COURSES', user?.id)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('error', error)
        }
      }

      const initAfterLogin = async () => {
        await fetchUser()
        firebaseLogin()
        segmentIdentify()
      }

      const setUserToken = async (token: string) => {
        try {
          $cookies.set('auth._token.local', token)
          store.commit('auth/SET_TOKEN', token)

          await initAfterLogin()
        } catch (error) {
          throw new Error(`[plugin/auth] ${error}`)
        }
      }

      const login = async ({ email, password }: { email: string; password: string }) => {
        await store.dispatch('auth/login', { email, password })

        await initAfterLogin()

        await fetchAppData()

        redirect('/')
      }

      const logout = async () => {
        await store.dispatch('auth/logout')
      }

      // On récupère le token de l'utilisateur dans les cookies
      const token = $cookies.get('auth._token.local')
      if (token) {
        await setUserToken(token)
      }

      const AuthPlugin: auth = {
        login,
        logout,
        fetchUser,
        getToken: () => store.getters['auth/token'],
        setUserToken,
        isLoggedIn: () => store.getters['auth/loggedIn'],
        setUser: (user) => store.commit('auth/SET_USER', user),
        loginWithGoogle,
      }

      inject('auth', AuthPlugin)
    } catch (error) {
      throw new Error(`[plugin/auth] ${error}`)
    }
  },
)

declare module 'vue/types/vue' {
  // this.$myInjectedFunction inside Vue components
  interface Vue {
    $auth: auth
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$myInjectedFunction inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $auth: auth
  }
  // nuxtContext.$myInjectedFunction
  interface Context {
    $auth: auth
  }
}
