import firebase from 'firebase/compat/app'

import {
  FirebaseCollection,
  FirebaseConfig,
  FirebaseDb,
  FirebaseDoc,
  FirebaseSubCollection,
} from '../types'
import {
  Callback as IdleTimeoutCallback,
  IdleTimeout,
  Options as IdleTimeoutOptions,
} from '@/plugins/idle-timeout'

import { UserVisibility, Callback as UserVisibilityCallback } from '@/plugins/user-visibility'

const convertMinToMs = (durationInMin: number): number => 1000 * 60 * durationInMin

export class SessionsCollection {
  private readonly sessionsCollection: FirebaseCollection
  private readonly sessionLogsCollection: FirebaseSubCollection

  private userSessionDoc?: FirebaseDoc
  private userSessionLogDoc?: FirebaseDoc

  private userId!: string

  private readonly logUpdateDelayInMin = 1
  private logUpdateLogInterval?: ReturnType<typeof setInterval>

  private idleTimeoutInstance?: IdleTimeout

  private userVisibilityInstance?: UserVisibility

  private isLoggingUserSession = false

  constructor(
    private readonly db: FirebaseDb,
    private readonly config: FirebaseConfig,
    private readonly idleTimeoutDurationInMs = convertMinToMs(360), // 6 heures,
    private readonly userVisibilityDurationInMs = 20_000, // 20 secondes,
  ) {
    this.sessionsCollection = this.db.collection(`${this.config.rootCollection}sessions`)
    this.sessionLogsCollection = (userId: string) => {
      return this.sessionsCollection.doc(userId).collection('logs')
    }
  }

  public async startUserSession(userId = this.userId) {
    try {
      this.userId = userId

      this.userSessionDoc = await this.createUserSession()
      await this.startWatchUserActivity()
    } catch (error) {
      throw new Error(`[SessionCollection](startRegisterUserSession) ${error}`)
    }
  }

  public async closeUserSession() {
    if (this.userSessionLogDoc) {
      await this.endRegisterUserSessionLog(this.userSessionLogDoc)
    }
    this.endWatchUserActivity()
    this.userSessionDoc = undefined
  }

  private async createUserSession() {
    try {
      const userSession = this.sessionsCollection.doc(this.userId)

      const userSessionData = (await userSession.get()).data()

      await userSession.set(
        {
          lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
          totalDuration: userSessionData?.totalDuration ?? 0,
        },
        { merge: true },
      )

      return userSession
    } catch (error) {
      throw new Error(`[SessionCollection](createUserSession) ${error}`)
    }
  }

  private async createUserSessionLog() {
    try {
      const userSessionLog = this.sessionLogsCollection(this.userId).doc()

      await userSessionLog.set({
        startDate: firebase.firestore.FieldValue.serverTimestamp(),
        durationInMin: 0,
      })

      return userSessionLog
    } catch (error) {
      throw new Error(`[SessionCollection](createUserSessionLog) ${error}`)
    }
  }

  private async startRegisterUserSessionLog() {
    this.isLoggingUserSession = true
    this.userSessionLogDoc = await this.createUserSessionLog()
    this.launchUpdatingUserSessionLog()
  }

  private async endRegisterUserSessionLog(userSessionLogDoc: FirebaseDoc) {
    try {
      this.isLoggingUserSession = false

      if (this.logUpdateLogInterval) {
        clearInterval(this.logUpdateLogInterval)
      }

      await userSessionLogDoc.update({
        endDate: firebase.firestore.FieldValue.serverTimestamp(),
      })

      this.userSessionLogDoc = undefined
    } catch (error) {
      throw new Error(`[SessionCollection](endRegisterUserSessionLog) ${error}`)
    }
  }

  private async updateSessionAngLog() {
    if (!this.userSessionDoc) {
      throw new Error(
        `[SessionCollection](launchUpdatingUserSessionLog) user session doc not created`,
      )
    }
    if (!this.userSessionLogDoc) {
      throw new Error(
        `[SessionCollection](launchUpdatingUserSessionLog) user session log doc not created`,
      )
    }

    const currentSessionLogDurationInMin =
      (await this.userSessionLogDoc.get()).data()?.durationInMin ?? 0

    const durationInMin = currentSessionLogDurationInMin + this.logUpdateDelayInMin

    await this.userSessionLogDoc.update({ durationInMin })

    await this.userSessionDoc.update({
      totalDuration:
        ((await this.userSessionDoc.get()).data()?.totalDuration ?? 0) + this.logUpdateDelayInMin,
      lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }

  private launchUpdatingUserSessionLog() {
    this.logUpdateLogInterval = setInterval(
      () => this.updateSessionAngLog(),
      this.logUpdateDelayInMin * 60000,
    )
  }

  private async toggleRegisterSessionLog(active: boolean) {
    if (active) {
      if (!this.isLoggingUserSession) {
        await this.startRegisterUserSessionLog()
      }
    } else if (this.userSessionLogDoc && this.isLoggingUserSession) {
      this.endRegisterUserSessionLog(this.userSessionLogDoc)
    }
  }

  private startWatchUserActivity() {
    return new Promise((resolve) => {
      const idleTimeoutCallback: IdleTimeoutCallback = ({ isIdle }) => {
        return this.toggleRegisterSessionLog(!isIdle)
      }

      const idleTimeoutOptions: IdleTimeoutOptions = {
        timeout: this.idleTimeoutDurationInMs,
      }

      this.idleTimeoutInstance = new IdleTimeout(idleTimeoutCallback, idleTimeoutOptions)

      const userActivityCallback: UserVisibilityCallback = async ({ isVisible }) => {
        try {
          await this.toggleRegisterSessionLog(isVisible)
        } finally {
          resolve(true)
        }
      }

      this.userVisibilityInstance = new UserVisibility(userActivityCallback, {
        immediate: true,
        timeout: this.userVisibilityDurationInMs,
      })
    })
  }

  private endWatchUserActivity() {
    this.idleTimeoutInstance?.destroy()
    this.userVisibilityInstance?.destroy()
  }
}
