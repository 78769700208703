import { FirebaseDoc, FirebaseDocCollection, FirebaseSubCollection } from '../types'
import { UsersCollection } from './UsersCollection'

export class DraftsCollection {
  private readonly draftsCollection: FirebaseSubCollection

  constructor(private readonly usersCollection: UsersCollection) {
    this.draftsCollection = (userId) => {
      return this.usersCollection.instance.doc(userId).collection('drafts')
    }
  }

  async fetchDraftsByUserId(userId: string): Promise<FirebaseDocCollection> {
    try {
      const querySnapshot = await this.draftsCollection(userId).orderBy('updated_at', 'desc').get()
      const messages = []

      for (const doc of querySnapshot.docs) {
        messages.push(doc.data())
      }

      return messages
    } catch (error) {
      throw new Error(`while fetching drafts for this userId: ${userId} - Error: ${error}`)
    }
  }

  async getUserDraftByConversationId({
    userId,
    conversationId,
  }: {
    userId: string
    conversationId: string
  }): Promise<FirebaseDoc | undefined> {
    try {
      const currentDoc = await this.draftsCollection(userId).doc(conversationId).get()

      return currentDoc.data()
    } catch (error) {
      throw new Error(
        `while getting draft for this userId: ${userId} and conversationId: ${conversationId} - Error: ${error}`,
      )
    }
  }

  listenUserDraftsChanges({
    userId,
    callback,
  }: {
    userId: string
    callback: (doc: FirebaseDocCollection) => void
  }) {
    try {
      return this.draftsCollection(userId)
        .orderBy('updated_at', 'desc')
        .onSnapshot((next) => {
          if (next.empty) {
            return
          }

          const changedDrafts = next.docChanges().map((m) => m.doc.data())

          const drafts = []
          for (const draft of changedDrafts) {
            if (draft?.id) {
              drafts.push(draft)
            }
          }

          if (drafts.length) {
            callback(drafts)
          }
        })
    } catch (error) {
      throw new Error(`while listening drafts for this userId: ${userId} - Error: ${error}`)
    }
  }

  async saveUserDraft({
    userId,
    conversationId,
    message,
  }: {
    userId: string
    conversationId: string
    message: string
  }) {
    if (!message) {
      throw new Error(
        `while saving draft - the text message is empty - UserId: ${userId} - CID: ${conversationId}`,
      )
    }

    try {
      const payload = { message, updated_at: Date.now() }

      return await this.draftsCollection(userId).doc(conversationId).set(payload, { merge: true })
    } catch (error) {
      throw new Error(
        `while saving draft for this userId: ${userId} and conversationId: ${conversationId} - Error: ${error}`,
      )
    }
  }

  async deleteUserDraft({ userId, conversationId }: { userId: string; conversationId: string }) {
    try {
      return await this.draftsCollection(userId).doc(conversationId).delete()
    } catch (error) {
      throw new Error(
        `while deleting draft for this userId: ${userId} and conversationId: ${conversationId} - Error: ${error}`,
      )
    }
  }
}
