import dayjs from 'dayjs'

const orderTrainingCoursesByDate = (trainingCourseA, trainingCourseB) => {
  if (
    dayjs(trainingCourseA.access_dates.start).isAfter(trainingCourseB.access_dates.start, 'date')
  ) {
    return 1
  } else if (
    dayjs(trainingCourseA.access_dates.start).isBefore(trainingCourseB.access_dates.start, 'date')
  ) {
    return -1
  }
  return 0
}

const getters = {
  getTrainingCourseById: (state) => (trainingCourseId) => {
    return state[trainingCourseId]
  },

  getTrainingCoursesForMasterclass: (state) => (masterClassId) => {
    return Object.values(state).find((t) => {
      return t.masterclass.id.toString() === masterClassId.toString()
    })
  },

  getPastTrainingCoursesForStudent: (state, getters) => (studentId, mentorId) => {
    const pastTrainingCoursesIds = getters
      .getStudentTrainingCoursesIds(studentId)
      .filter((trainingCourseId) => {
        const trainingCourse = state[trainingCourseId]
        return (
          dayjs().isAfter(dayjs(trainingCourse.access_dates.end)) &&
          (mentorId ? trainingCourse.mentor?.id?.toString() === mentorId : true)
        )
      })

    return pastTrainingCoursesIds.map((trainingCourseId) => state[trainingCourseId])
  },

  getFutureTrainingCoursesForStudent: (state, getters) => (studentId, mentorId) => {
    const futureTrainingCoursesIds = getters
      .getStudentTrainingCoursesIds(studentId)
      .filter((trainingCourseId) => {
        return (
          dayjs().isBefore(state[trainingCourseId].access_dates.start, 'date') &&
          (mentorId ? mentorId.toString() === state[trainingCourseId].mentor?.id?.toString() : true)
        )
      })

    return futureTrainingCoursesIds
      .map((trainingCourse) => state[trainingCourse])
      .sort(
        (a, b) => new Date(a.administrative_dates.start) - new Date(b.administrative_dates.start),
      )
  },

  getFutureOrPastTrainingCourseForStudent: (_state, getters) => (studentId, mentorId) => {
    return (
      getters.getFutureTrainingCoursesForStudent(studentId, mentorId)?.[0] ??
      getters.getPastTrainingCoursesForStudent(studentId, mentorId)?.[0]
    )
  },

  getStudentTrainingCourses: (state, getters) => (studentId) => {
    const trainingCourses = getters
      .getStudentTrainingCoursesIds(studentId)
      .map((trainingCourseId) => state[trainingCourseId])
      .sort(orderTrainingCoursesByDate)

    return trainingCourses
  },

  getStudentTrainingCoursesIds: (_state, _getter, rootState) => (studentId) => {
    const student = rootState.users[studentId]

    if (!student || !student.training_courses) {
      return []
    }

    return student.training_courses
  },

  getCertificationForTrainingCourse: (state, __, rootState) => (tcId) => {
    return rootState.certifications?.[state[tcId]?.certification_id?.toString()]
  },
}

export default getters
