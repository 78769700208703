interface FilterCurrencyOptions extends Intl.NumberFormatOptions {
  locale: string
  round?: boolean
}

const getFormattedCurrency = (number: number | string, options: FilterCurrencyOptions) => {
  let numberToFormat = Number(number)

  if (options.round) {
    numberToFormat = Math.floor(numberToFormat)
    options.minimumFractionDigits = 0
  }

  return new Intl.NumberFormat(options.locale, options).format(numberToFormat)
}

const validRequiredAttributes = (number: number | string, options: FilterCurrencyOptions) => {
  if (typeof number === 'undefined') {
    throw new TypeError('[FilterCurrency] The `number` attribute is required.')
  }
  if (Number.isNaN(number)) {
    throw new TypeError('[FilterCurrency] The `number` attribute must be a valid number.')
  }
  if (typeof options.locale === 'undefined') {
    throw new TypeError('[FilterCurrency] The `options.locale` attribute is required.')
  }
  if (typeof options.locale !== 'string') {
    throw new TypeError('[FilterCurrency] The `options.locale` attribute must be a string.')
  }
  if (typeof options.currency === 'undefined') {
    throw new TypeError('[FilterCurrency] The `options.currency` attribute is required.')
  }
}

export const currency =
  ({ defaultCurrency, defaultLocale }: { defaultCurrency: string; defaultLocale: string }) =>
  (number: number | string, options?: Partial<FilterCurrencyOptions>): string => {
    const DEFAULT_OPTIONS: FilterCurrencyOptions = {
      style: 'currency',
      minimumFractionDigits: 2,
      round: false,
      currency: defaultCurrency,
      locale: defaultLocale,
    }

    const opts: FilterCurrencyOptions = {
      ...DEFAULT_OPTIONS,
      ...options,
    }

    validRequiredAttributes(number, opts)

    try {
      return getFormattedCurrency(number, opts)
    } catch (error) {
      throw new Error(`[FilterCurrency] ${error}`)
    }
  }
