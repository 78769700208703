import Vue from 'vue'
import { MERGE_TEMPLATES } from '@/constants/mutations-type'

export default {
  [MERGE_TEMPLATES]: (state, templates) => {
    templates.forEach((template) => {
      Vue.set(state, template.id, template)
    })
  },
}
