import Vue from 'vue'
import { SET_COURSES, SET_MODULES, SET_LESSONS } from '@/constants/mutations-type'

export default {
  [SET_COURSES]: (state, masterclasses) => {
    Vue.set(state, 'courses', masterclasses.data)
  },
  [SET_MODULES]: (state, { modules, id }) => {
    const newModules = {
      ...(state.modules || {}),
      [id]: modules,
    }
    Vue.set(state, 'modules', newModules)
  },
  SET_BONUSES: (state, { bonuses, id }) => {
    const newBonuses = {
      ...(state.bonuses || {}),
      [id]: bonuses,
    }
    Vue.set(state, 'bonuses', newBonuses)
  },
  [SET_LESSONS]: (state, { lessons, id }) => {
    if (!state.lessons[id]) {
      state.lessons[id] = lessons
      return
    }

    // only inlcude lessons that are not already in state.lessons[id]
    const flatLessonIds = state.lessons[id].map(({ id }) => id) || []
    const lessonsToAdd = lessons.filter(({ id }) => !flatLessonIds.includes(id))

    state.lessons[id] = [...state.lessons[id], ...lessonsToAdd]
  },
  UPDATE_LESSON: (state, { lesson, masterclassId }) => {
    // if state.lessons[masterclassId] is undefined, create an array at that key wiith lesson as the first element
    if (!state.lessons[masterclassId]) {
      state.lessons[masterclassId] = [lesson]
      return
    }
    const lessonIndex = state.lessons?.[masterclassId]?.findIndex(({ id }) => lesson?.id === id)
    state.lessons[masterclassId].splice(lessonIndex, 1, lesson)
  },
  UPDATE_BONUS: (state, { bonus, masterclassId }) => {
    const bonusIndex = state.bonuses[masterclassId]?.findIndex(({ id }) => bonus?.id === id)
    state.bonuses[masterclassId].splice(bonusIndex, 1, bonus)
  },
  UPDATE_FEEDBACK: (state, { feedback, masterclassId }) => {
    const lessonIndex = state.lessons[masterclassId]?.findIndex(
      ({ id }) => feedback?.feedbackable_id === id,
    )
    const updatedLesson = {
      ...state.lessons[masterclassId][lessonIndex],
      feedback,
    }
    state.lessons[masterclassId].splice(lessonIndex, 1, updatedLesson)
  },
  SET_LESSON_PAGE_IS_LOADING: (state, isLoading) => {
    Vue.set(state, 'lessonPageIsLoading', isLoading)
  },
  SET_LESSON_VIEWS: (state, { lessonViews, masterclassId }) => {
    const newLessonViews = {
      ...(state.lessonViews || {}),
      [masterclassId]: lessonViews,
    }
    Vue.set(state, 'lessonViews', newLessonViews)
  },
  UPDATE_LESSON_VIEWS: (state, { lessonViews, masterclassId }) => {
    const lessonIndex = state.lessons[masterclassId]?.findIndex(
      ({ id }) => lessonViews.lesson_id === id,
    )
    const updatedLesson = {
      ...state.lessons[masterclassId][lessonIndex],
      viewed_by_user: true,
    }
    state.lessons[masterclassId].splice(lessonIndex, 1, updatedLesson)
    state.lessonViews[masterclassId].push(lessonViews)
  },
  SET_MILESTONES: (state, { milestones, id }) => {
    const newMilestones = {
      ...(state.milestones || {}),
      [id]: milestones,
    }

    Vue.set(state, 'milestones', newMilestones)
  },
  UPDATE_MILESTONE: (state, { milestone, masterclassId }) => {
    const milestoneIndex = state.milestones[masterclassId]?.findIndex(
      ({ id }) => milestone?.id === id,
    )
    state.milestones[masterclassId].splice(milestoneIndex, 1, milestone)
  },
}
