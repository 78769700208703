import Vue from 'vue'
import { MERGE_MESSAGES, UPDATE_MESSAGE } from '@/constants/mutations-type'

const setMessage = (state, payload) => {
  payload.messages.sort((a, b) => a.created_at - b.created_at)

  payload.messages.forEach((x) => {
    Vue.set(state, x.id, x)
  })
}

export default {
  [UPDATE_MESSAGE]: (state, message) => {
    Vue.set(state, message.id, {
      ...state[message.id],
      ...message,
    })
  },
  [MERGE_MESSAGES]: (state, payload) => {
    setMessage(state, payload)
  },
}
