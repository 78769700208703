import Vue from 'vue'

import {
  GLOBAL_MODAL,
  SET_BACK_ACTION_ROUTE,
  SET_DISPLAY_MENU,
  SET_DISPLAY_USER_DETAILS,
  SET_IS_MOBILE,
  SET_SELECTED_CONVERSATION,
  SET_SHOULD_LOGOUT,
  SET_FREE_COURSE_lAYOUT_TITLE,
} from '@/constants/mutations-type'

export default {
  [GLOBAL_MODAL]: (state, payload) => {
    state.globalModal = payload
  },
  [SET_DISPLAY_MENU]: (state, value) => {
    if (!value) {
      state.displayMenu = !state.displayMenu
    } else {
      state.displayMenu = value
    }
  },
  [SET_DISPLAY_USER_DETAILS]: (state) => {
    state.displayUserDetails = !state.displayUserDetails
  },
  [SET_SELECTED_CONVERSATION]: (state, conversationId) => {
    Vue.set(state, 'selectedConversation', conversationId)
  },
  [SET_SHOULD_LOGOUT]: (state, value) => {
    state.shouldLogout = value
  },
  SET_RUN_HOST_URL: (state, value) => {
    state.runHostUrl = value
  },
  [SET_BACK_ACTION_ROUTE]: (state, route) => {
    state.backActionRoute = route
  },
  SET_CURRENT_SCREEN_VIEW: (state, screenView) => {
    state.currentScreenView = screenView
  },
  SET_CURRENT_SCREEN_NAME: (state, screenName) => {
    state.currentScreenName = {
      main: screenName,
      childName: screenName,
    }
  },
  SET_CURRENT_SCREEN_CHILD_NAME: (state, childName) => {
    state.currentScreenName = {
      ...state.currentScreenName,
      childName,
    }
  },
  [SET_IS_MOBILE]: (state, value) => {
    state.isMobile = value
  },
  [SET_FREE_COURSE_lAYOUT_TITLE]: (state, title) => {
    state.freeCourseLayoutTitle = title
  },
  setAllConversationsAreLoaded: (state, value) => {
    state.allConversationsAreLoaded = value
  },
}
